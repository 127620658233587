import gql from 'graphql-tag'
export default gql `
    query getPollResults($id: ID!){
        getPollResults(
            id: $id
        ){
            total
            event
            a
            question
         }
    }
`
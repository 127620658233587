import gql from 'graphql-tag'

export default gql`
    mutation updateSettings($input: UpdateSettingsInput!){
        updateSettings(
            input: $input
        ){
            id
            allow_late_registration
            allow_registration
            excerpt
            flyer
            generic_code
            name
            start
            stop
            teaser_timeout
            login
            url
            css
            logo
            lanyard
            bg_badge
            favicon
            modal_info
            modal_support
            ga
            cb
            lang
            utc
			badge
			gdpr
			available_languages
			not_protected
            color_primary
            toolbox
            navigation
            meta_description
            ecuson
            segment
        }
    }
`
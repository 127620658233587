import _ from 'lodash'
import Vue from 'vue'
import algoliasearch from "algoliasearch";

const state = {
	list: [],
	page: 0
}

// getters
const getters = {
	list: function (state) {
		return state.list
	},
	page: function (state) {
		return state.page
	}
}

// actions
const actions = {
	patch: async function ({ commit }, item) {
		commit('PATCH_LIST', item)
	},
	list: async function ({ dispatch, commit, getters }) {
		dispatch('loader', 'moderators/list', { root: true })
		try {
			let client = algoliasearch(process.env.VUE_APP_ALGOLIA_APP_ID || 'RDGKIGZ5MV', process.env.VUE_APP_ALGOLIA_SEARCH_API_KEY || '489633ec46587787f706b713d69a9bb2');
			let index = client.initIndex(process.env.VUE_APP_ALGOLIA_USERS_INDEX || process.env.VUE_APP_SLUG);
			index.search({
				query: '',
				hitsPerPage: 100,
				filters: 'app_metadata.role:moderator',
				page: getters.page
			},
				function searchDone (err, content) {
					if (err) throw err;
					commit('SET_LIST', content.hits)

					if (content.nbPages > getters.page + 1) {
						commit('SET_NEXT_PAGE')
						dispatch('list')
					}
				}
			);
		} catch (e) {
			commit('LOG', { type: 'error', message: e.message || e }, { root: true })
		}
		dispatch('loader', 'moderators/list', { root: true })
	}
}

// mutations
const mutations = {
	SET_LIST (state, list) {
		_.each(list, function (i) {
			if (_.isUndefined(_.find(state.list, { objectID: i.objectID }))) {
				state.list.push(i)
			} else {
				Vue.set(state.list, _.findIndex(state.list, { objectID: i.objectID }), i)
			}
		})
	},
	SET_NEXT_PAGE: function (state) {
		state.page++
	},
	PATCH_LIST: function (state, payload) {
		const isModerator = payload.app_metadata.role === 'moderator'
		const existsInList = state.list.some(el => el.objectID === payload.user_id)
		if (isModerator) {
			if (!existsInList) {
				state.list.push({
					...payload,
					objectID: payload.user_id
				})
			}
		} else {
			if (existsInList) {
				const remainingList = state.list.filter((el) => {
					return el.objectID !== payload.user_id
				})
				state.list = remainingList
			}
		}
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}

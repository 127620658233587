import gql from 'graphql-tag'

export default gql `
    mutation deleteLocation($id: ID!){
        deleteLocation(
            id: $id
        ){
            id
        }
    }
`
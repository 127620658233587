import gql from 'graphql-tag'

export default gql `
    subscription onUpdatedPollQuestion {
        onUpdatedPollQuestion {
            id
            question
            multiple
            excerpt
            answers{
                id
                answer
            }
            results
            separator
            status
            event
        }
    }
`
import Vue from 'vue'
import Vuex from 'vuex'
import _ from 'lodash'
import state from './state'
import actions from './actions'
import mutations from './mutations'
import faq from './modules/utils/faq'
import settings from './modules/utils/settings'
import events from './modules/events'
import locations from './modules/locations'
import hosts from './modules/hosts'
import moderators from './modules/moderators'
import broadcasts from './modules/broadcasts'
import inbox from './modules/inbox'
import users from './modules/users'
import questions from './modules/questions'
import templates from './modules/templates'
import images from './modules/images'

Vue.use(Vuex)

export default new Vuex.Store({
	state: state,
	actions: actions,
	mutations: mutations,
	getters: {
		setup: function (state) {
			return state.setup
		},
		loaders: function (state) {
			return state.loaders
		},
		loading: function (state) {
			return state.loaders.length > 0
		},
		faq: function (state, getters) {
			return getters['faq/list']
		},
		events: function (state, getters) {
			return getters['events/list']
		},
		locations: function (state, getters) {
			return getters['locations/list']
		},
		hosts: function (state, getters) {
			return getters['hosts/list']
		},
		broadcasts: function (state, getters) {
			return getters['broadcasts/list']
		},
		inbox: function (state, getters) {
			return getters['inbox/list']
		},
		has_more_inbox: function (state, getters) {
			return !_.isNull(getters['inbox/next_token'])
		},
		moderators: function (state, getters) {
			return getters['moderators/list']
		},
		users: function (state, getters) {
			return getters['users/list']
		},
		total_users: function (state, getters) {
			return getters['users/total']
		},
		single_user: function (state, getters) {
			return getters['users/single']
		},
		logged_user: function (state) {
			return state.logged_user || JSON.parse(localStorage.getItem('user'))
		},
		logged_user_role: function (state, getters) {
			try {
				return !_.isNull(getters.logged_user) ? getters.logged_user['https://360live.events/role'] : undefined
			} catch (e) {
				return undefined
			}
		},
		logged_user_priviledges: function (state, getters) {
			try {
				return !_.isNull(getters.logged_user) ? getters.logged_user['https://360live.events/priviledges'] : false
			} catch (e) {
				return false
			}
		},
		settings: function (state, getters) {
			return getters['settings/settings']
		},
		/*logged_user_events_for_moderation: function(state, getters){
			if( getters.logged_user_role === '360' ){
				return _.filter( getters.events, func )
			}
		},*/
		logged_in: function () {
			return new Date().getTime() < parseInt(localStorage.getItem('expires_at'))
		},
		questions: function (state, getters) {
			return getters['questions/list']
		},
		images: function (state, getters) {
			return getters['images/list']
		},
		templates: function (state, getters) {
			return getters['templates/list']
		},
		folders: function (state, getters) {
			return getters['images/folders']
		},
		hosts_by_name: function (state, getters) {
			return getters['hosts/list_by_name']
		}
	},
	modules: {
		faq,
		events,
		locations,
		hosts,
		broadcasts,
		inbox,
		moderators,
		users,
		questions,
		settings,
		templates,
		images
	},
	strict: true,
	plugins: []
})

import _ from 'lodash'
import Vue from 'vue'
import {ApolloClient} from "@/main"

import getSettings from '@/appsync/queries/utils/getSettings'
import cleanDeep from "clean-deep"
import webAuth from "@/auth"
import Settings from '@/schemas/Settings'
import createSettings from "@/appsync/mutations/settings/createSettings";
import updateSettings from "@/appsync/mutations/settings/updateSettings";
import clearProject from "@/appsync/mutations/settings/clearProject";
import clearBadges from "@/appsync/mutations/settings/clearBadges";

const state = {
    settings: new Settings().toObject()
}

// getters
const getters = {
    settings: function(state){
        return state.settings
    }
}

// actions
const actions = {
    get: async function ({dispatch, commit} ) {
        dispatch('loader', 'utils/settings/get', { root: true })
        try{
            let get = await ApolloClient.query({
                query: getSettings
            })
            commit( 'SET', get.data.getSettings )
        } catch (e) {
            window.console.log(e)
            if( ! _.isUndefined( e.networkError ) &&  ! _.isUndefined( e.networkError.statusCode ) && e.networkError.statusCode === 401 ){
                webAuth.authorize()
            } else {
                commit('LOG', { type: 'error', title: 'Could not get settings', message: e.message || e }, {root: true})
            }
        }
        dispatch('loader', 'utils/settings/get', { root: true })
    },
    create: async function ({dispatch, commit}, form) {
        dispatch('loader', 'settings/create', { root: true })
        try{
            form = new Settings(form).toObject()
            form = cleanDeep(form,{
                emptyArrays: false,
                nullValues: true
            })
            if( ! _.isUndefined( form.created ) ){
                delete form.created
            }
            if( ! _.isUndefined( form.updated ) ){
                delete form.updated
            }
            let updated = await ApolloClient.mutate({
                mutation: createSettings,
                variables: {
                    input: form
                }
            })
            if( ! _.isNull( updated.data.createSettings ) ){
                commit( 'SET', updated.data.createSettings )
            }
        } catch (e) {
            if( ! _.isUndefined( e.networkError ) &&  ! _.isUndefined( e.networkError.statusCode ) && e.networkError.statusCode === 401 ){
                webAuth.authorize()
            } else {
                commit('LOG', { type: 'error', message: e.message || e }, {root: true})
            }
        }
        dispatch('loader', 'settings/create', { root: true })
    },
    update: async function ({dispatch, commit}, form) {
        dispatch('loader', 'settings/update', { root: true })
        try{
            form = new Settings(form).toObject()
            form.flyer = form.flyer || null
            form.favicon = form.favicon || null
            form.logo = form.logo || null
            form.bg_badge = form.bg_badge || null
            form.lanyard = form.lanyard || null
            form.css = form.css || null
            form.ga = form.ga || null
            form.cb = form.cb || null
            form.color_primary = form.color_primary || null
            form.toolbox = form.toolbox || null
            form.ecuson = form.ecuson || null
            form = cleanDeep(form,{
                emptyArrays: false,
                nullValues: false
            })
            if( ! _.isUndefined( form.created ) ){
                delete form.created
            }
            if( ! _.isUndefined( form.updated ) ){
                delete form.updated
            }
            let updated = await ApolloClient.mutate({
                mutation: updateSettings,
                variables: {
                    input: form
                }
            })
            if( ! _.isNull( updated.data.updateSettings ) ){
                commit( 'SET', updated.data.updateSettings )
            }
        } catch (e) {
            if( ! _.isUndefined( e.networkError ) &&  ! _.isUndefined( e.networkError.statusCode ) && e.networkError.statusCode === 401 ){
                webAuth.authorize()
            } else {
                commit('LOG', { type: 'error', message: e.message || e }, {root: true})
            }
        }
        dispatch('loader', 'settings/update', { root: true })
    },
    delete: async function ({dispatch, commit}) {
        dispatch('loader', 'settings/delete', { root: true })
        try{
            await ApolloClient.mutate({
                mutation: clearProject,
                variables: {}
            })
        } catch (e) {
            if( ! _.isUndefined( e.networkError ) &&  ! _.isUndefined( e.networkError.statusCode ) && e.networkError.statusCode === 401 ){
                webAuth.authorize()
            } else {
                commit('LOG', { type: 'error', message: e.message || e }, {root: true})
            }
        }
        dispatch('loader', 'settings/delete', { root: true })
    },
    clearBadges: async function ({dispatch, commit}) {
        dispatch('loader', 'settings/clearBadges', { root: true })
        try{
            await ApolloClient.mutate({
                mutation: clearBadges,
                variables: {}
            })
        } catch (e) {
            if( ! _.isUndefined( e.networkError ) &&  ! _.isUndefined( e.networkError.statusCode ) && e.networkError.statusCode === 401 ){
                webAuth.authorize()
            } else {
                commit('LOG', { type: 'error', message: e.message || e }, {root: true})
            }
        }
        dispatch('loader', 'settings/clearBadges', { root: true })
    }
}

// mutations
const mutations = {
    SET: function (state, c){
        Vue.set( state, 'settings', new Settings(c).toObject() )
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

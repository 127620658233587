import gql from 'graphql-tag'
export default gql`
    query listLocations($limit: Int, $nextToken: String){
        listLocations(
            limit: $limit,
            nextToken: $nextToken
        ){
            items{
                id
                name
                chat
                chat_group
                hosts
                template
                bg
                slug
                buttons{
                    type
                    label
                    url
                    path
                }
                heat_maps{
                    type
                    label
                    url
                    path
                    d
                }
                vod
                parent
                menu_order
                is_home
                video_wall
                info
                agenda
                agenda_date_format
                thumb
				not_protected
                svg
                permissions
                password
                phone
            },
            nextToken
         }
    }
`
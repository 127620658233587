export default {
    TOGGLE_LOADER: function(state, loader){
        if( state.loaders.indexOf( loader ) < 0 ){
            state.loaders.push(loader)
        } else {
            state.loaders.splice( state.loaders.indexOf( loader ), 1 )
        }
    },
    LOG (state, ev){
        state.log.push(ev)
    },
    UNLOG (state, k){
        state.log.splice(k, 1)
    },
    READY(state){
        state.setup = true
    },
    SET_LOGGED_USER(state, user){
        state.logged_user = user
    }
}
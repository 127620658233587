import gql from 'graphql-tag'

export default gql `
  mutation deletePollQuestion($id: ID!) {
    deletePollQuestion(
      id: $id
    ){
        id
    }
  }
`
<template>
    <div>
        <BroadcastsHeader :title="is_new ? 'New Broadcast' : 'Edit broadcast'"></BroadcastsHeader>
        <el-form class="content-box margin-top--2 form-broadcast" label-position="top" ref="form"  :rules="rules" :model="form" @submit.native.prevent v-loading="loading_global">
            <el-row :gutter="32">
                <el-col :xs="0" :lg="24" align="right">
                    <el-form-item label=" ">
                        <el-tag size="mini" :type="getBroadcastTagType(form.status)" round>{{ form.status | broadcast_type }}</el-tag>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-form-item label="Body" prop="body"  class="margin-top--3">
                <el-input  :disabled="is_body_disabled" type="textarea" v-model="form.body" :rows="6" placeholder="Enter the email body here"></el-input>
            </el-form-item>
            <div class="margin-top--4 form-footer">
                <el-button v-if="form.status === 0" type="primary" :disabled="!form_has_changed" round  v-on:click.prevent="saveForm()"><i class="margin-right--05 fas fa-save"></i> Send broadcast</el-button>
                <el-button type="text" class="color-gray" v-if="form_has_changed" v-on:click.prevent="resetForm()"><i class="margin-right--05 fas fa-undo"></i> Reset changes</el-button>
            </div>
        </el-form>
    </div>
</template>

<script>
    import _ from 'lodash'
    import store from '@/store'
    import BroadcastsHeader from "@/components/broadcasts/BroadcastsHeader";
    import Broadcast from '@/schemas/Broadcast'
    //import onUpdatedBroadcast from '@/appsync/subscriptions/broadcasts/onUpdatedBroadcast';
    import titleMixin from '@/mixins'

    export default {
        name: "Broadcast",
        components: { BroadcastsHeader },
        props: ['id'],
        mixins: [titleMixin],
        data: function(){
            let vm = this
            return {
                drawer: false,
                rules: {
                    'body': [
                        { required: true, message: 'Please input the body', trigger: 'blur' },
                        { min: 6, message: 'Length should be at least 6 characters', trigger: 'blur' }
                    ]
                },
                form: new Broadcast().toObject(),
                form_witness: JSON.stringify( new Broadcast().toObject() ),
                recipients_count: 0
            }
        },
        beforeRouteEnter: async function(to, from, next){
            if( store.getters.broadcasts.length === 0 ){
                await store.dispatch('broadcasts/list')
            }
            next()
        },
        computed: {
            page_title () {
                return this.is_new ? 'Broadcasts | Add broadcast' : 'Broadcasts | Edit broadcast'
            },
            form_has_changed: function(){
                return JSON.stringify( this.form ) !== this.form_witness
            },
            is_new: function(){
                return _.isUndefined( this.id )
            },
            broadcasts: function(){
                return JSON.parse( JSON.stringify( store.getters.broadcasts ) )
            },
            broadcast: function(){
                return _.find( this.broadcasts, { id: this.id } )
            },
            is_body_disabled: function () {
                return this.form.status !== 0
            }
        },
        mounted: function(){
            let vm = this
            store.subscribe((mutation) => {
                if( mutation.type === 'broadcasts/ADD' ){
                    if( vm.is_new ){
                        vm.$router.push({ name: 'broadcasts-edit', params: { id: mutation.payload.id } })
                    }
                }
            })
            this.setForm(new Broadcast().toObject())
            if( ! this.is_new && ! _.isUndefined( this.broadcast ) ){
                this.setForm(this.broadcast)
            }
            //this.$apollo.subscriptions.onUpdatedBroadcast.refresh()
        },
        methods: {
            saveForm: function(){
                let vm = this
                this.$refs.form.validate((valid) => {
                    if (valid) {
                        if( vm.is_new ){
                            store.dispatch('broadcasts/create', vm.form )
                        } else {
                            store.dispatch('broadcasts/update', {...vm.form, id: this.id } )
                        }
                    } else {
                        return false;
                    }
                });
            },
            setForm: function(form){
                this.form_witness = JSON.stringify( form )
                this.form = form
            },
            resetForm: function(){
                this.form = JSON.parse( this.form_witness )
            }
        },
        watch: {
            broadcast: function(n){
                this.setForm(n || new Broadcast().toObject())
            }
        },
        /*apollo: {
            $subscribe: {
                onUpdatedBroadcast: {
                    query: onUpdatedBroadcast,
                    result ({ data }) {
                        store.dispatch( 'broadcasts/patchLocalData', data.onUpdatedBroadcast )
                    }
                }
            }
        }*/
    }
</script>

<style scoped lang="scss">
    .form-broadcast{

        .el-col + .el-col{
            margin-top: 1.5rem;
        }
        @include breakpoint(laptop) {
            .el-col + .el-col{
                margin-top: 0;
            }
        }
    }
</style>
import gql from 'graphql-tag'
export default gql `
    query listChat($limit: Int, $nextToken: String, $thread: String){
        listChat(
            limit: $limit,
            nextToken: $nextToken,
            thread: $thread
        ){
            items{
                id
                body
                from{
                    name
                    id
                }
                read
                created
                ts
                thread
                type
                location
            },
            nextToken
         }
    }
`
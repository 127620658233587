<template>
	<div>
		<h1>Event details</h1>
		<div class="content-box margin-top--2">
			<dl>
				<dt>Name</dt>
				<dd class="color-black">{{settings.name}}</dd>
				<dt>URL</dt>
				<dd class="color-black"><a
						:href="settings.url"
						target="_blank"
					>{{settings.url}} <em class="fad fa-external-link margin-left--5px"></em></a></dd>
				<dt>Starting</dt>
				<dd>{{settings.start | moment('dddd DD, MMMM YY [at] HH:mm') }}</dd>
				<dt>Ending</dt>
				<dd>{{settings.stop | moment('dddd DD, MMMM YY [at] HH:mm') }}</dd>
				<dt>Allow registration</dt>
				<dd>
					<el-tag
						:type="settings.allow_registration ? 'success' : 'danger'"
						size="mini"
						round
					>
						{{settings.allow_registration ? 'Yes' : 'No'}}
					</el-tag>
				</dd>
				<dt>Allow late registration</dt>
				<dd>
					<el-tag
						:type="settings.allow_late_registration ? 'success' : 'danger'"
						size="mini"
						round
					>
						{{settings.allow_late_registration ? 'Yes' : 'No'}}
					</el-tag>
				</dd>
				<dt>Authentication</dt>
				<dd>
					<el-tag
						:type="settings.login !== 'none' ? 'success' : 'danger'"
						size="mini"
						round
					>{{settings.login
                        !== 'none' ? 'Yes | Private event' : 'No | Public event'}}
					</el-tag>
				</dd>
				<template v-if="settings.login !== 'none'">
					<dt>Authentication type</dt>
					<dd>
						<template v-if="settings.login === 'auth0'">Username & password</template>
						<template v-else-if="settings.login === 'generic_code'">Generic code - <span class="color-black">{{settings.generic_code}}</span></template>
						<template v-else-if="settings.login === 'unique_code'">Unique personal code</template>
					</dd>
				</template>
				<dt>Pre-access time</dt>
				<dd>{{ settings.teaser_timeout / 60 }} minutes</dd>
				<dt>Flyer</dt>
				<dd>
					<template v-if="settings.flyer"><a
							:href="getImgSrc({ width: 1200 }, settings.flyer)"
							target="_blank"
						>{{ getImgSrc({ width: 1200 }, settings.flyer) }}
							<em class="fad fa-external-link margin-left--5px"></em></a></template>
					<template v-else>-</template>
				</dd>
				<dt>Description</dt>
				<dd>
					<div class="text--muted">{{settings.excerpt}}</div>
				</dd>
				<dt>Event sitemap</dt>
				<dd>
					<el-tree
						class="filter-tree"
						:data="locations_list"
						:props="defaultProps"
						@node-click="treeClick"
						default-expand-all
						ref="tree"
					>
					</el-tree>
				</dd>
			</dl>
		</div>

		<h1 class="margin-top--4">Event locations</h1>
		<div class="content-box margin-top--2">
			<el-collapse v-model="activeLocations">
				<el-collapse-item
					v-for="location in locations"
					:key="location.id"
					:id="location.id"
					:title="location.name"
					:name="location.id"
				>
					<dl>
						<dt>Name</dt>
						<dd>{{location.name}}</dd>
						<dt>URL</dt>
						<dd class="color-black"><a
								:href="`${settings.url}/${location.slug}`"
								target="_blank"
							>{{settings.url}}/{{location.slug}}
								<em class="fad fa-external-link margin-left--5px"></em></a></dd>
						<dt>Parent location</dt>
						<dd v-if="location.parent"><a :href="`#${location.parent}`">{{ location.parent | location_name
                            }}</a></dd>
						<dd
							v-else
							class="text--muted"
						>none</dd>
						<dt>Template</dt>
						<dd>{{location.template}}</dd>
						<dt>Chat</dt>
						<dd>
							<el-tag
								:type="location.chat ? 'success' : 'danger'"
								size="mini"
								round
							>{{location.chat ?
                                'Yes' : 'No'}}
							</el-tag>
						</dd>
						<template v-if="location.chat">
							<dt>Chat type</dt>
							<dd>
								<el-tag
									:type="location.chat_group ? 'success' : 'danger'"
									size="mini"
									round
								>
									{{location.chat_group ? 'Private' : 'Public'}}
								</el-tag>
							</dd>
							<dt>Chat host</dt>
							<dd v-if="location.hosts && location.hosts.length > 0">
								<router-link
									target="_blank"
									:to="{ name: 'users-edit', params: { id: location.hosts[0] } }"
								>
									{{location.hosts[0] | host_name}} <em class="fad fa-external-link margin-left--5px"></em></router-link>
							</dd>
							<dd v-else>-</dd>
						</template>
						<dt>Background image</dt>
						<dd>
							<template v-if="location.bg"><a
									:href="getImgSrc({ width: 1200 }, location.bg)"
									target="_blank"
								>{{ getImgSrc({ width: 1200 }, location.bg)
                                }} <em class="fad fa-external-link margin-left--5px"></em></a></template>
							<template v-else>-</template>
						</dd>
						<dt>Buttons</dt>
						<dd v-if="location.buttons && location.buttons.length > 0">
							<div
								v-for="(button, button_index) in location.buttons"
								:key="button_index"
								class="border-bottom-1 padding-bottom--10px margin-bottom--10px text-size--small"
							>
								Label: {{button.label}}<br>
								Type: {{button.type}}<br>
								<template v-if="button.url">URL: <a
										:href="button.url"
										target="_blank"
									>{{button.url}}
										<em class="fad fa-external-link margin-left--5px"></em></a></template>
							</div>
						</dd>
						<dd
							v-else
							class="text--muted"
						>none</dd>
						<dt>Heat maps</dt>
						<dd v-if="location.heat_maps && location.heat_maps.length > 0">
							<div
								v-for="(button, button_index) in location.heat_maps"
								:key="button_index"
								class="border-bottom-1 padding-bottom--10px margin-bottom--10px text-size--small"
							>
								Label: {{button.label}}<br>
								Type: {{button.type}}<br>
								<template v-if="button.url">URL: <a
										:href="button.url"
										target="_blank"
									>{{button.url}}
										<em class="fad fa-external-link margin-left--5px"></em></a></template>
							</div>
						</dd>
						<dd
							v-else
							class="text--muted"
						>none</dd>
						<dt>Video wall</dt>
						<dd v-if="location.video_wall">
							{{location.video_wall}}
						</dd>
						<dd
							v-else
							class="text--muted"
						>none</dd>
						<dt>Video on demand</dt>
						<dd v-if="location.vod">
							{{location.vod}}
						</dd>
						<dd
							v-else
							class="text--muted"
						>none</dd>
						<dt>Menu order</dt>
						<dd>{{location.menu_order}}</dd>
					</dl>
				</el-collapse-item>
			</el-collapse>
		</div>

		<h1 class="margin-top--4">Event schedule</h1>
		<div class="content-box margin-top--2">
			<div class="text-align--right">
				<el-select
					v-model="events_filter"
					placeholder="Filter by location"
					class="margin-bottom--2"
					clearable
					style="max-width: 200px"
				>
					<el-option
						v-for="item in events_locations"
						:key="item.value"
						:label="item.label | location_name"
						:value="item.value"
					>
					</el-option>
				</el-select>
			</div>
			<el-timeline>
				<el-timeline-item
					v-for="ev in events"
					:key="ev.id"
					:timestamp="ev.start | moment('DD/MM/YYYY [at] HH:mm')"
					placement="top"
				>
					<dl>
						<dt>Event name</dt>
						<dd>{{ev.name}}</dd>
						<dt>Starting</dt>
						<dd>{{ev.start | moment('dddd DD, MMMM YY [at] HH:mm') }}</dd>
						<dt>Ending</dt>
						<dd>{{ev.stop | moment('dddd DD, MMMM YY [at] HH:mm') }}</dd>
						<dt>Event host</dt>
						<dd v-if="ev.hosts && ev.hosts.length > 0">
							<router-link
								target="_blank"
								:to="{ name: 'users-edit', params: { id: ev.hosts[0] } }"
							>
								{{ev.hosts[0] | host_name}} <em class="fad fa-external-link margin-left--5px"></em>
							</router-link>
						</dd>
						<dd v-else>-</dd>
						<dt>Location</dt>
						<dd v-if="ev.location"><a :href="`#${ev.location}`">{{ ev.location | location_name }}</a></dd>
						<dt>Live video</dt>
						<dd>
							<el-tag
								:type="ev.live ? 'success' : 'danger'"
								size="mini"
								round
							>{{ev.live ? 'Yes' :
                                'No'}}
							</el-tag>
						</dd>
						<template v-if="ev.live_video">
							<dt>Live video stream</dt>
							<dd>{{ev.live_video}}</dd>
						</template>
						<dt>Sync video</dt>
						<dd>
							<el-tag
								:type="ev.sync ? 'success' : 'danger'"
								size="mini"
								round
							>{{ev.sync ? 'Yes' :
                                'No'}}
							</el-tag>
						</dd>
						<template v-if="ev.sync_video">
							<dt>Sync video stream</dt>
							<dd>{{ev.sync_video}}</dd>
						</template>
						<dt>Poll</dt>
						<dd>
							<el-tag
								:type="ev.poll ? 'success' : 'danger'"
								size="mini"
								round
							>{{ev.poll ? 'Yes' :
                                'No'}}
							</el-tag>
						</dd>
						<dt>Q&A</dt>
						<dd>
							<el-tag
								:type="ev.qa ? 'success' : 'danger'"
								size="mini"
								round
							>{{ev.qa ? 'Yes' : 'No'}}
							</el-tag>
						</dd>
						<template v-if="ev.qa">
							<dt>Q&A moderator</dt>
							<dd>
								<router-link
									target="_blank"
									:to="{ name: 'users-edit', params: { id: ev.qa_moderator } }"
								>
									{{ev.qa_moderator | moderator_name}} <em class="fad fa-external-link margin-left--5px"></em></router-link>
							</dd>
						</template>
						<dt>Description</dt>
						<dd>
							<div class="text--muted">{{ev.excerpt}}</div>
						</dd>
					</dl>
				</el-timeline-item>
			</el-timeline>
		</div>
	</div>
</template>

<script>
import store from '@/store'
import _ from 'lodash'
import titleMixin from '@/mixins'

export default {
	name: "Public",
	mounted: function () {
	},
	mixins: [titleMixin],
	computed: {
		page_title () {
			return `Event details`
		},
		events: function () {
			let events = _.orderBy(store.getters.events, ['start'])
			if (this.events_filter) {
				events = _.filter(events, { location: this.events_filter })
			}
			return events
		},
		settings: function () {
			return store.getters.settings
		},
		locations: function () {
			return _.map(_.orderBy(store.getters.locations, ['is_home', 'parent', 'menu_order'], ['asc', 'desc']), function (l) {
				return { ...l, children: _.filter(store.getters.locations, { parent: l.id }) }
			})
		},
		locations_list: function () {
			let vm = this
			return _.map([_.find(vm.locations, { is_home: true })], function (l) {
				return {
					...l, children: _.orderBy(_.filter(vm.locations, function (ll) {
						return (_.isUndefined(ll.parent) || _.isNull(ll.parent) || ll.parent === '') && ll.id !== l.id
					}), ['menu_order'])
				}
			})
		},
		events_locations: function () {
			let list = []
			_.each(this.events, function (e) {
				if (list.indexOf(e.location) === -1) {
					list.push(e.location)
				}
			})
			return _.map(list, function (l) {
				return {
					label: l,
					value: l
				}
			})
		}
	},
	methods: {
		getHostName (id) {
			if (this.$options.filters.host_name(id).indexOf('auth0') === -1 || this.$options.filters.host_name(id).indexOf('google-oauth2') === -1) {
				return this.$options.filters.host_name(id)
			} else {
				return 'No host is set'
			}
		},
		treeClick: function (v) {
			let scrollDiv = document.getElementById(v.id).offsetTop;
			window.scrollTo({ top: scrollDiv, behavior: 'smooth' });
			if (this.activeLocations.indexOf(v.id) === -1) {
				this.activeLocations.push(v.id)
			}
		}
	},
	data: function () {
		return {
			activeLocations: [],
			defaultProps: {
				children: 'children',
				label: 'name'
			},
			events_filter: ''
		}
	},
	beforeRouteEnter: function (to, from, next) {
		next()
		/*if( store.getters.logged_in ){
			if( store.getters.logged_user_role === 'moderator' ){
				next('/moderation')
			} else if( store.getters.logged_user_role === 'host' ){
				next('/live')
			} else if( store.getters.logged_user_role === '360' ){
				next('/users')
			}
		} else {
			next()
		}*/
	}
}
</script>

<style scoped lang="scss">
dl {
	display: flex;
	width: 100%;
	flex-wrap: wrap;
	line-height: 1.65;

	dt {
		flex-basis: 35%;
		flex-shrink: 0;
		margin-bottom: 1rem;
	}

	dd {
		flex-basis: 65%;
		margin-left: auto;
		margin-right: 0;
		margin-bottom: 1rem;
		color: $color-black;
	}

	& + dl {
		margin-top: 1rem;
		border-top: 1px dashed rgba($color-black, 1);
		padding-top: 1rem;
	}
}
</style>

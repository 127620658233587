<template>
    <div>
        <div class="visible--tablet text-align--right">
            <el-button type="text" class="color-primary" v-on:click="$router.push({ name: 'users-new' })"><i class="far fa-plus margin-right--1"></i> Add new user</el-button>
            <template v-if="search">
                <div class="search-box margin-top--2">
                    <input type="text" v-model="input" placeholder="Search by name, email or phone" class="search">
                    <i class="fas fa-search"></i>
                </div>
            </template>
        </div>
        <div class="hidden--tablet">
            <el-row type="flex" align="middle">
                <el-col :span="12">
                    <template v-if="search">
                        <div  class="search-box">
                            <input type="text" v-model="input" placeholder="Search user by name, email or phone" class="search">
                            <i class="fas fa-search"></i>
                        </div>
                    </template>
                    <template v-else>
                        <h1 class="page-title" v-text="title"></h1>
                    </template>
                </el-col>
                <el-col :span="12" align="right">
                    <el-button type="text" class="margin-right--1 color-gray" v-on:click.prevent="dialog_import = true"><i class="fad fa-lg fa-cloud-upload margin-right--05"></i> Import users</el-button>
                    <el-button type="primary" round v-on:click="$router.push({ name: 'users-new' }).catch(()=>{})"><i class="far fa-plus margin-right--05"></i> Add new user</el-button>
                </el-col>
            </el-row>
        </div>

        <el-dialog title="Import .csv document" :visible.sync="dialog_import" width="100%" class="dialog--small" :before-close="closeDialogImportHook">
            <upload-to-s3></upload-to-s3>
        </el-dialog>
    </div>
</template>

<script>
    import _ from 'lodash'
    import UploadToS3 from '@/components/upload-to-s3'
    import store from "@/store"

    export default {
        name: "UsersHeader",
        props: [ 'title', 'search' ],
        data: function(){
            return {
                dialog_import: false,
                file_import: [],
                input: ''
            }
        },
        methods: {
            closeDialogImportHook: function(next){
                next()
            }
        },
        watch: {
            input: _.debounce(function(v){
                this.$emit('search', v)
            }, 300),
            dialog_import: function(n){
                if(!n){
                    store.dispatch('users/list', { search: '' })
                }
            }
        },
        components: {
            'upload-to-s3': UploadToS3
        }
    }
</script>

<style scoped>

</style>
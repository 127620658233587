<template>
	<div>
		<h1 class="page-title">Project settings</h1>
		<el-form
			class="content-box margin-top--2"
			label-position="top"
			v-loading="loading_global"
		>

			<el-row :gutter="20">
				<el-col :span="24">
					<el-form-item
						prop="name"
						label="Project name"
					>
						<el-input
							v-model="form.name"
							placeholder="Enter project name"
						></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row
				:gutter="20"
				class="margin-top--3"
			>
				<el-col :span="24">
					<el-form-item
						prop="name"
						label="Available languages"
					>
						<el-checkbox-group v-model="form.available_languages">
							<el-checkbox-button
								v-for="(language, index) in languagesModel"
								:label="language.label"
								:key="index"
								@click.native="updatedLanguage = language.label"
							>
								{{language.name}}
							</el-checkbox-button>
						</el-checkbox-group>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row
				:gutter="20"
				class="margin-top--3"
			>
				<el-col :span="24">
					<el-form-item
						label="Default language"
						prop="lang"
					>
						<el-select
							v-model="form.lang"
							placeholder="Choose default language"
						>
							<el-option
								v-for="(language, index) in form.available_languages"
								:label="getLanguageFullName(language)"
								:key="index"
								:value="language"
							>
								{{getLanguageFullName(language)}}
							</el-option>

						</el-select>
					</el-form-item>
				</el-col>
			</el-row>
			<el-form-item
				prop="url"
				label="Project url"
				class="margin-top--3"
			>
				<el-input
					v-model="url"
					placeholder="client-domain"
				>
					<template slot="prepend"><em
							class="fad fa-lock"
							style="margin-right: 10px"
						></em>
						https://
					</template>
					<template slot="append">
						.360live.events
					</template>
				</el-input>
				<div
					v-if="preview_key"
					v-on:click.prevent="ctc(preview_key)"
					class="text-size--smaller cursor--pointer color-black"
				>
					<el-tooltip
						:disabled="disabled_tooltip"
						v-model="tooltip"
						:manual="true"
						content="Copied!"
						placement="right"
						effect="dark"
					>
						<span><strong>Preview key is:</strong> {{preview_key}} <span class="fa-fw fa-paste fad color-primary margin-left--5px"></span></span>
					</el-tooltip>
				</div>
			</el-form-item>
			<el-row
				:gutter="20"
				class="margin-top--3"
			>
				<el-col :span="12">
					<el-form-item
						prop="login"
						label="Authentication"
					>
						<el-select v-model="form.login">
							<el-option
								value="none"
								label="None"
							></el-option>
							<el-option
								value="unique_code"
								label="Unique code"
							></el-option>
							<el-option
								value="generic_code"
								label="Universal code"
							></el-option>
							<el-option
								value="auth0"
								label="Username & Password"
							></el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item
						v-if="form.login === 'generic_code'"
						prop="generic_code"
						label="Universal code"
					>
						<el-input
							v-model="form.generic_code"
							placeholder="Enter universal code"
						></el-input>
					</el-form-item>
					<el-row
						:gutter="32"
						v-if="form.login === 'auth0'"
					>
						<el-col :span="12">
							<el-form-item
								prop="allow_registration"
								label="Allow registration"
							>
								<el-switch
									v-model="form.allow_registration"
									active-text="Yes"
								></el-switch>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item
								v-if="form.allow_registration"
								prop="allow_late_registration"
								label="Late registration"
							>
								<el-switch
									v-model="form.allow_late_registration"
									active-text="Yes"
								></el-switch>
							</el-form-item>
						</el-col>
					</el-row>
				</el-col>
			</el-row>
			<el-row
				:gutter="20"
				class="margin-top--3"
			>
				<el-col :span="12">
					<el-form-item
						label="Starting date & time"
						prop="start"
					>
						<el-date-picker
							v-model="form.start"
							type="datetime"
							value-format="yyyy-MM-dd[T]HH:mm:ss"
							placeholder="Select date and time"
						>
						</el-date-picker>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item
						label="Ending date & time"
						prop="stop"
					>
						<el-date-picker
							v-model="form.stop"
							type="datetime"
							value-format="yyyy-MM-dd[T]HH:mm:ss"
							placeholder="Select date and time"
						>
						</el-date-picker>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row
				:gutter="20"
				class="margin-top--3"
			>
				<el-col :span="6">
					<el-form-item
						label="Teaser timeout (min)"
						prop="teaser_timeout"
					>
						<el-input-number
							v-model="teaser_timeout"
							:min="0"
							:max="1440"
						></el-input-number>
					</el-form-item>
				</el-col>
				<el-col
					:span="12"
					:offset="6"
				>
					<el-form-item
						prop="utc"
						label="Timezone"
					>
						<el-input
							v-model="form.utc"
							placeholder="Enter timezone"
						></el-input>
					</el-form-item>
				</el-col>
			</el-row>

			<el-row
				:gutter="20"
				class="margin-top--3"
			>
				<el-col :span="6">
					<el-form-item
						prop="badge"
						label="Badge is mandatory"
					>
						<el-switch
							v-model="form.badge"
							active-text="Yes"
						></el-switch>
					</el-form-item>
				</el-col>
				<el-col :span="6">
					<el-form-item
						prop="badge"
						label="Marketing"
					>
						<el-switch
							v-model="form.gdpr"
							active-text="Yes"
						></el-switch>
					</el-form-item>
				</el-col>
        <el-col :span="6">
          <el-form-item
              prop="segment"
              label="Send events to Segment"
          >
            <el-switch
                v-model="form.segment"
                active-text="Yes"
            ></el-switch>
          </el-form-item>
        </el-col>
			</el-row>
			<el-form-item
				prop="flyer"
				label="Flyer"
				class="margin-top--3"
			>
				<image-upload
					v-model="form.flyer"
					:prefix="`${prefix}/flyers/`"
				></image-upload>
			</el-form-item>
			<el-form-item
				prop="logo"
				label="Logo"
				class="margin-top--3"
			>
				<image-upload
					v-model="form.logo"
					:prefix="`${prefix}/logos/`"
				></image-upload>
			</el-form-item>
			<el-form-item
				prop="favicon"
				label="Favicon"
				class="margin-top--3"
			>
				<image-upload
					v-model="form.favicon"
					:prefix="`${prefix}/favicons/`"
				></image-upload>
			</el-form-item>
			<el-form-item
				prop="badge"
				label="Badge"
				class="margin-top--3"
			>
				<image-upload
					v-model="form.bg_badge"
					:prefix="`${prefix}/badges/`"
				></image-upload>
			</el-form-item>
      <el-form-item
          prop="lanyard"
          label="Lanyard"
          class="margin-top--3"
      >
        <image-upload
            v-model="form.lanyard"
            :prefix="`${prefix}/lanyards/`"
        ></image-upload>
      </el-form-item>

      <el-form-item prop="ecuson" label="Ecuson" class="margin-top--3">
        <image-upload
            v-model="form.ecuson"
            :prefix="`${prefix}/ecusoane/`"
        ></image-upload>
      </el-form-item>

			<el-row
				:gutter="20"
				class="margin-top--3"
			>
				<el-col :span="12">
					<el-form-item
						label="Google Analytics ID"
						prop="ga"
					>
						<el-input
							v-model="form.ga"
							placeholder="UA-C84JXXX-1"
						>
						</el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item
						label="Cookie Bot ID"
						prop="cb"
					>
						<el-input
							v-model="form.cb"
							placeholder="JDI97320-XNSDIE-XNSDIE-XNSDIE"
						>
						</el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-form-item
				label="Support modal content"
				prop="modal_support"
				class="margin-top--3"
			>
				<el-input
					type="textarea"
					:rows="5"
					v-model="form.modal_support"
				></el-input>
			</el-form-item>
			<el-form-item
				label="Info modal content"
				prop="modal_info"
				class="margin-top--3"
			>
				<el-input
					type="textarea"
					:rows="5"
					v-model="form.modal_info"
				></el-input>
			</el-form-item>
      <el-row :gutter="20" class="margin-top--3">
        <el-col :span="6">
          <el-form-item label="Brand color" prop="color_primary">
            <el-color-picker v-model="form.color_primary"></el-color-picker>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="Expanded navigation" prop="bavigation">
            <el-switch v-model="form.navigation" active-text="Yes"></el-switch>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item label="Toolbox text" prop="toolbox" class="margin-top--3">
        <el-input v-model="form.toolbox"></el-input>
      </el-form-item>
      <el-form-item label="Meta description" prop="meta_description" class="margin-top--3">
        <el-input v-model="form.meta_description"></el-input>
      </el-form-item>
			<el-form-item
				label="Custom CSS"
				prop="css"
				class="margin-top--3"
			>
				<prism-editor
					class="my-editor"
					v-model="form.css"
					:highlight="highlighter"
					line-numbers
				></prism-editor>
			</el-form-item>
			<el-row
				:gutter="20"
				class="margin-top--4 form-footer"
			>
				<el-col :span="8">
					<el-button
						type="primary"
						:disabled="!form_has_changed"
						round
						v-on:click.prevent="save()"
					><i class="margin-right--05 fas fa-save"></i> Save project
					</el-button>
					<el-button
						type="text"
						class="color-gray"
						v-if="form_has_changed"
						v-on:click.prevent="resetForm()"
					><i class="margin-right--05 fas fa-undo"></i> Reset changes
					</el-button>
				</el-col>
				<el-col
					:span="16"
					align="right"
				>
          <el-button type="warning" round v-on:click.prevent="clearBadges()"><i class="margin-right--05 fad fa-fire"></i> Clear Badges</el-button> &nbsp;
					<el-button
						type="danger"
						round
						v-on:click.prevent="clearProject()"
					><i class="margin-right--05 fad fa-fire"></i> CLEAR PROJECT
					</el-button>
				</el-col>
			</el-row>
		</el-form>
	</div>
</template>

<script>
import { PrismEditor } from 'vue-prism-editor';
import 'vue-prism-editor/dist/prismeditor.min.css';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism-tomorrow.css';
import store from '@/store';
import Settings from '@/schemas/Settings';
import _ from "lodash";
import ImageUpload from "@/components/ImageUpload"
import slugify from "slugify"
import titleMixin from '@/mixins'
import md5 from "md5"
import copy from 'copy-to-clipboard';

export default {
	name: "Dashboard",
	components: {
		PrismEditor,
		ImageUpload
	},
	mixins: [titleMixin],
	methods: {
    clearBadges(){
      this.$confirm('This will permanently delete the badges. Continue?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'danger'
      }).then(() => {
        store.dispatch('settings/clearBadges')
      }).catch(() => {
        return;
      });
    },
		getLanguageFullName (prefix) {
			return {
				'en': 'English',
				'ro': 'Romanian',
				'it': 'Italian',
				'es': 'Spanish',
				'de': 'German',
				'fr': 'French',
			}[prefix]
		},
		ctc: function (t) {
			copy(t)
			this.disabled_tooltip = false
			this.tooltip = true
			let vm = this
			setTimeout(function () {
				vm.disabled_tooltip = true
				vm.tooltip = false
			}, 2000)
		},
		clearProject () {
			this.$confirm('This will permanently delete the chat, the Q&A and the polls. Continue?', 'Warning', {
				confirmButtonText: 'OK',
				cancelButtonText: 'Cancel',
				type: 'danger'
			}).then(() => {
				store.dispatch('settings/delete')
			}).catch(() => {
				return;
			});
		},
		highlighter (code) {
			return highlight(code, languages.js, "css"); // languages.<insert language> to return html with markup
		},
		save: function () {
			if (!_.isUndefined(this.form.id) && !_.isNull(this.form.id) && this.form.id.length > 5) {
				store.dispatch('settings/update', this.form)
			} else {
				store.dispatch('settings/create', this.form)
			}
		},
		setForm: function (form) {
			this.form_witness = JSON.stringify(form)
			this.form = JSON.parse(JSON.stringify(form))
		},
		resetForm: function () {
			this.form = JSON.parse(this.form_witness)
		},
	},
	data: function () {
		return {
			witness: JSON.stringify(new Settings().toObject()),
			form: new Settings().toObject(),
			disabled_tooltip: true,
			tooltip: false,
			languagesModel: [
				{
					name: 'English',
					label: 'en'
				},
				{
					name: 'Romanian',
					label: 'ro'
				},
				{
					name: 'Italian',
					label: 'it'
				},
				{
					name: 'Spanish',
					label: 'es'
				},
				{
					name: 'German',
					label: 'de'
				},
				{
					name: 'French',
					label: 'fr'
				},
			],
			updatedLanguage: null
		}
	},
	computed: {
		preview_key: function () {
			try {
				if (this.url.length > 3) {
					return md5(this.form.url)
				}
				return false
			} catch (e) {
				return false
			}
		},
		page_title () {
			return 'Project settings'
		},
		url: {
			get: function () {
				try {
					return this.form.url.replace('https://', '').replace('.360live.events', '')
				} catch (e) {
					return ''
				}
			},
			set: function (n) {
				n = n.replace(/\//g, '')
				n = slugify(n, { remove: /[\][*+~,`=\\;{}§±#^?()'"!:@]/g })
				this.form.url = `https://${n}.360live.events`
			}
		},
		form_has_changed: function () {
			return JSON.stringify(this.form) !== this.form_witness
		},
		prefix: function () {
			return `projects/${process.env.VUE_APP_SLUG}`
		},
		settings: function () {
			return store.getters.settings
		},
		teaser_timeout: {
			get: function () {
				return this.form.teaser_timeout / 60
			},
			set: function (n) {
				this.form.teaser_timeout = n * 60
			}
		}
	},
	mounted: function () {
		this.setForm(new Settings(this.settings || this.form).toObject())
	},
	watch: {
		'form.available_languages' (newValue, oldValue) {
			const removed = newValue.length < oldValue.length
			if (removed && this.form.lang === this.updatedLanguage) {
				this.form.lang = this.form.available_languages[0]
			}

		},
		settings: function (n) {
			this.setForm(n)
		}
	}
}
</script>

<style lang="scss">
.form-settings {
	.label {
		vertical-align: middle;
		float: none;
		font-size: 14px;
		color: #606266;
		line-height: 40px;
		padding: 0 12px 0 0;
		box-sizing: border-box;
	}
}

.thumbnail {
	width: 100%;
	max-width: 100%;
	max-height: 100%;
	border-radius: 10px;
}

.height-200 {
	height: 200px;
}

.my-editor.my-editor {
	/* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
	background: #2d2d2d;
	color: #ccc;
	margin-bottom: 2rem;
	border-radius: 5px;
	height: auto;
	max-height: 500px;

	/* you must provide font-family font-size line-height. Example:*/
	font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
	font-size: 14px;
	line-height: 1.5;
	padding: 5px;
}

.prism-editor__textarea:focus {
	outline: none;
	outline-color: transparent !important;
}
</style>
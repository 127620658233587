import _ from 'lodash'
import Vue from 'vue'
import algoliasearch from "algoliasearch";

const state = {
	list: [],
	page: 0
}

// getters
const getters = {
	list: function (state) {
		return state.list
	},
	page: function (state) {
		return state.page
	},
	list_by_name: function (state) {
		return _.map(state.list, function (host) {
			let out = {
				id: host.objectID,
				email: host.email
			}
			let name = host.user_metadata.first_name ? host.user_metadata.first_name.trim() : ''
			name += host.user_metadata.last_name ? ' ' + host.user_metadata.last_name.trim() : ''
			name = name.trim()
			name = name.length > 0 ? name : host.email
			out.name = name
			return out
		})
	}
}

// actions
const actions = {
	list: async function ({ dispatch, commit, getters }) {
		dispatch('loader', 'hosts/list', { root: true })
		try {
			let client = algoliasearch(process.env.VUE_APP_ALGOLIA_APP_ID || 'RDGKIGZ5MV', process.env.VUE_APP_ALGOLIA_SEARCH_API_KEY || '489633ec46587787f706b713d69a9bb2');
			let index = client.initIndex(process.env.VUE_APP_ALGOLIA_USERS_INDEX  || process.env.VUE_APP_SLUG);
			index.search({
				query: '',
				hitsPerPage: 100,
				filters: 'app_metadata.role:host',
				page: getters.page
			},
				function searchDone (err, content) {
					if (err) throw err;
					commit('SET_LIST', content.hits)
					if (content.nbPages > getters.page + 1) {
						commit('SET_NEXT_PAGE')
						dispatch('list')
					}
				}
			);
		} catch (e) {
			commit('LOG', { type: 'error', message: e.message || e }, { root: true })
		}
		dispatch('loader', 'hosts/list', { root: true })
	}
}

// mutations
const mutations = {
	SET_LIST (state, list) {
		_.each(list, function (i) {
			if (_.isUndefined(_.find(state.list, { id: i.id }))) {
				state.list.push(i)
			} else {
				Vue.set(state.list, _.findIndex(state.list, { id: i.id }), i)
			}
		})
	},
	SET_NEXT_PAGE: function (state) {
		state.page++
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}

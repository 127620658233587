<template>
	<div>
		<template v-if="['initiated', 'uploaded'].indexOf( import_status ) >= 0 ">
			<div class="text-align--center">
				<span class="fa-stack fa-2x color-primary">
					<i class="fas fa-cloud fa-stack-2x opacity--20"></i>
					<i class="fad fa-cog fa-xs fa-stack-1x fa-spin"></i>
				</span><br><br>
				Working on importing you users
			</div>
		</template>
		<template v-else-if="['failed', 'expired'].indexOf( import_status ) >= 0 ">
			<div class="text-align--center">
				<span class="fa-stack fa-2x color-danger">
					<i class="fas fa-cloud fa-stack-2x opacity--20"></i>
					<i class="fas fa-times fa-xs fa-stack-1x"></i>
				</span><br><br>
				Failed to import users
			</div>
		</template>
		<template v-else-if="import_status === 'completed' ">
			<div class="text-align--center">
				<span class="fa-stack fa-2x color-green">
					<i class="fas fa-cloud fa-stack-2x opacity--20"></i>
					<i class="fas fa-check fa-xs fa-stack-1x"></i>
				</span><br><br>
				Successfully imported users
			</div>
		</template>
		<template v-else>
			<el-upload
				action=""
				drag
				:show-file-list="false"
				:http-request="uploadToS3"
				:before-upload="beforeAvatarUpload"
			>
				<div class="el-upload__text  margin-top--4  margin-bottom--4">
					<i class="fad fa-cloud-upload color-primary fa-3x"></i>
					<div class="el-upload__text">Drag you file here or <em>click </em> to upload</div>
				</div>
				<div
					class="el-upload__tip text-align--center margin-top--1"
					slot="tip"
				>.csv file only with a size less than 400KB</div>
			</el-upload>
		</template>
	</div>
</template>

<script>
import { ApolloClient } from '@/main'
import { gql } from 'apollo-boost'
import axios from 'axios'
import store from '@/store'
import slugify from 'slugify'
import onUpdatedImport from '@/appsync/subscriptions/utils/onUpdatedImport'

export default {
	name: "upload-to-s3",
	props: ['name', 'value'],
	data: function () {
		return {
			import_status: null
		}
	},
	methods: {
		normalizeFileName: function (name) {
			let fileExtension = name.split('.').pop();
			let fileName = name.replace(fileExtension, '')
			fileName = slugify(fileName, { remove: /[*+~.()'"!:@+=±§#$&`?/><,;\\}{[\]^%]/g }).toLowerCase()
			fileName = fileName + '.' + fileExtension.toLowerCase()
			return fileName
		},
		uploadToS3: async function (n) {
			let vm = this
			store.dispatch('loader', 'getSignedUrl')
			let fileName = this.normalizeFileName(n.file.name)
			try {
				let signedUrl = await ApolloClient.query({
					query: gql`
                            query getSignedUrl( $key: String!, $type: String! ){
                                getSignedUrl( key: $key, type: $type ){
                                    file
                                    path
                                }
                             }
                        `,
					variables: {
						key: fileName,
						type: n.file.type
					}
				})
				let response = await axios.put(signedUrl.data.getSignedUrl.path, n.file, {
					headers: {
						'Content-Type': n.file.type,
						'x-amz-meta-email': vm.$auth.user.email
					}
				}).catch(function (e) {
					window.console.log('eer', e)
				})
				if (response.status === 200) {
					vm.import_status = "initiated"
				}
			} catch (e) {
				window.console.log('eroare upload', e)
				//store.dispatch( 'logEntry', { type: 'error', title: 'Something went wrong uploading your image' } )
			}
			store.dispatch('loader', 'getSignedUrl')
		},
		beforeAvatarUpload (file) {
			const hasExtensionCsv = file.name.slice(file.name.length - 4).toLowerCase() === '.csv'
			const isCSV = file.type === 'text/csv';
			const isLt2M = file.size / 1024 / 1024 / 1024 < 400;
			if (!isCSV && !hasExtensionCsv) {
				this.$message.error('Upload file must be .CSV format!');
			}
			if (!isLt2M) {
				this.$message.error('Upload file can not exceed 400KB!');
			}
			return (isCSV && isLt2M) || hasExtensionCsv;
		}
	},
	apollo: {
		$subscribe: {
			onUpdatedEvent: {
				query: onUpdatedImport,
				result ({ data }) {
					window.console.log('update import', data)
					this.import_status = data.onUpdatedImport.status
				}
			}
		}
	}
}
</script>

<style scoped>
</style>
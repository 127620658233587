import gql from 'graphql-tag'

export default gql `
    subscription onPong($location: String) {
        onPong(
            location: $location
        ){
            location
            body
            id
            thread
        }
    }
`
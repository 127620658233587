import gql from 'graphql-tag'

export default gql `
    mutation createChatMessage($input: CreateChatMessageInput!){
        createChatMessage(
            input: $input
        ){
            id
            body
            from{
                name
                id
            }
            read
            created
            ts
            thread
            type
        }
    }
`
<template>
  <el-row class="location-button" :gutter="5" style="line-height: 32px">
    <el-col :span="1" style="line-height: 1; padding-top: 5px">
      <em class="fa-fw fa-lg far fa-angle-up" v-on:click.prevent="moveUp()" :class="{ disabled: this.first }"></em>
      <em class="fa-fw fa-lg far fa-angle-down" v-on:click.prevent="moveDown()" :class="{ disabled: this.last }"></em>
    </el-col>
    <el-col v-if="is_heat" :span="22" class="margin-bottom--5px">
      <el-select size="mini" v-model="button.d" placeholder="Choose heat map">
        <el-option v-for="(t, ti) in template_maps" :key="`t-maps-${ti}`" :label="t.name" :value="t.d"></el-option>
      </el-select>
    </el-col>
    <el-col :span="6" :offset="is_heat ? 1 : 0">
      <el-select size="mini" v-model="button.type">
        <el-option value="back" label="Go back"></el-option>
        <el-option value="path" label="Go to location"></el-option>
        <el-option value="chat" label="Chat"></el-option>
        <el-option value="info" label="Info modal"></el-option>
        <el-option value="link" label="External url"></el-option>
        <el-option value="download" label="Download"></el-option>
        <el-option value="info-location" label="Location Info modal"></el-option>
      </el-select>
    </el-col>
    <el-col :span="8">
      <el-input size="mini" v-model="button.label" placeholder="Enter button label"></el-input>
    </el-col>
    <el-col :span="8">
      <template v-if="['download', 'link'].indexOf( button.type ) >= 0">
        <component :is="'el-input'"  size="mini" v-model="button.url" placeholder="Enter url"></component>
      </template>
      <template v-else-if="button.type === 'path'">
        <component :is="'el-select'"  size="mini" v-model="button.path">
          <el-option v-for="location in locations" :key="location.id" :value="location.slug" :label="location.name"></el-option>
        </component>
      </template>
      <template v-else>
        <div>&nbsp; &nbsp;</div>
      </template>
    </el-col>
    <el-col :span="1" align="right" >
      <em class="fad margin-top--12px fa-fw fa-trash color-danger" v-on:click.prevent="deleteButton()"></em>
    </el-col>
  </el-row>
</template>

<script>
import store from "@/store"
import _ from "lodash"
export default {
  name: "LocationButton",
  props: ['is_heat', 'value', 'for', 'last', 'first' ,'template'],
  computed: {
      template_maps: function(){
        try{
            let tpl = _.find( store.getters.templates, { id: this.template })
            return tpl.heat_maps
        } catch (e) {
            return {}
        }
      },
    button: {
      get: function (){
        return this.value
      },
      set: function (n){
        this.$emit('input', n)
      }
    },
    locations: function (){
      return store.getters.locations
      /*let vm = this
      return _.filter( store.getters.locations, function (l){
        return l.id !== vm.for
      })*/
    }
  },
  methods: {
    deleteButton: function(){
      this.$confirm('Are you sure you want to delete it?', 'Warning', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        this.$emit('delete')
      }).catch(() => {
      });
    },
    moveUp: function (){
      if( ! this.first ){
        this.$emit('move', 'up')
      }
    },
    moveDown: function (){
      if( ! this.last ){
        this.$emit('move', 'down')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.disabled{
  cursor: not-allowed;
  opacity: 0.35;
}
[class*="fa-angle-"]:not(.disabled){
  cursor: pointer;
  &:hover{
    color: blue;
  }
}
  .location-button{
    & + &{
      margin-top: 1.5rem;
    }
  }
</style>
<template>
  <div>
    <h1 class="page-title">Questions & Answers Display
      <el-button v-on:click.prevent="status = ! status" class="float--right" :type="status ? 'danger' : 'success'" round size="mini"><em class="fas fa-fw margin-right--10px" :class="{ 'fa-play' : !status, 'fa-stop': status  }"></em>{{ status ? 'Stop': 'Start' }}</el-button>
      <el-button-group class="float--right margin-right--10px">
        <el-button round size="mini" v-for="(day, day_index) in events_grouped" :key="`button-day-${day_index}`" v-on:click.prevent="setDate(day_index)">{{day_index | moment('ddd DD')}}</el-button>
      </el-button-group></h1>
    <div class="qas-display-table margin-top--3" v-loading="loading">
      <div class="qas-display-table__header">
        <div class="qas-display-table__name">Event</div>
        <div class="qas-display-table__host">Host</div>
        <div class="qas-display-table__questions">Questions</div>
      </div>
      <template v-for="(event, bi) in table_data">
        <div :key="bi" class="qas-display-table__item">
          <div class="qas-display-table__name">{{event.name}}</div>
          <div class="qas-display-table__host">{{event.host}}</div>
          <div class="qas-display-table__questions">
            <div v-for="question in event.questions" class="qas-display-table__questions__question">
              <div class="qas-display-table__questions__question__body">{{ question && question.body ? question.body : '' }}</div>
              <div class="qas-display-table__questions__question__created">{{ question.created | moment('x') | timeago }}</div>
              <div class="qas-display-table__questions__question__actions">
                <el-tooltip v-if="question" v-model="tooltip[question.id]" :manual="true" content="Copied!" placement="right" effect="dark">
                  <em  class="fad fa-fw fa-lg cursor--pointer fa-clipboard color-primary" v-on:click.prevent="ctc(event, question)"></em>
                </el-tooltip>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>

    <!--<el-table :data="table_data" class="content-box&#45;&#45;shadow table-events margin-top&#45;&#45;3" v-loading="loading">
      <el-table-column type="expand">
        <template slot-scope="scope">
          {{ scope.row.name }}
        </template>
      </el-table-column>
      <el-table-column width="450" label="Host" prop="host">
        <template slot-scope="scope">
          <div class="host-cell">
            {{ scope.row.host }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="Question" prop="question">
        <template slot-scope="scope">
          <div v-for="question in scope.row.questions">
            {{ question && question.body ? question.body : '' }}
          </div>
        </template>
      </el-table-column>
      <el-table-column width="180" align="right" prop="question.created">
        <template slot-scope="scope">
          <div v-for="question in scope.row.questions">
            <span v-if="question">{{ question.created | moment('x') | timeago }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="" width="80" align="right">
        <template slot-scope="scope">


        </template>
      </el-table-column>
    </el-table>-->
  </div>
</template>

<script>
import {filter,  groupBy, values, map, orderBy, each, random, isUndefined, concat, keys} from 'lodash'
import store from '@/store'
import moment from "moment-timezone"
import onNewQAMessage from "@/appsync/subscriptions/qas/onNewQAMessage";
import getLastQA from "@/appsync/queries/qas/last";
import copy from 'copy-to-clipboard';
export default {
  name: "QasDisplay",
  data: function() {
    return {
      questions: {},
      status: false,
      waiting: [],
      disabled_tooltip: true,
      tooltip: [],
      selected: 0
    }
  },
  computed: {
    events: () => filter(store.getters.events, {qa: true}),
    events_grouped() { return groupBy( orderBy(this.events, ['start']), (ev) => moment(ev.start).format('YYYY-MM-DD')) },
    events_next() { return values( this.events_grouped )[this.selected] },
    mapped_events(){
      try{
        let vm = this
        return map( this.events_next, (ev) => {
          return {
            id: ev.id,
            name: ev.name,
            host: vm.getHostName(ev),
            questions: orderBy(vm.questions[ev.id], ['created'], ['desc']),
            start: ev.start
          }}
        )
      } catch (e){
        console.log(e)
      }
      return []
    },
    table_data(){
      return orderBy(this.mapped_events, ['start'])
    },
    loading() {
      return this.waiting.length > 0
    }
  },
  filters: {
    getEventHost: function (ev) {
      try {
        return ev.hosts[0]
      } catch (e) {
        return ev.hosts_excerpt
      }
    }
  },
  watch: {
    status: function(active){
      if(active){
        this.getQuestions()
      }
    }
  },
  methods: {
    setDate: function(date){
      this.selected = keys(this.events_grouped).indexOf(date)
      if(this.status){
        this.getQuestions()
      }
    },
    ctc(ev, question){
      copy(`Host: ${ev.host} Question: ${question.body}`)
      this.tooltip[question.id] = true
      let vm = this
      setTimeout(function () {
        vm.tooltip[question.id] = false
      }, 2000)
    },
    getHostName: function(ev){
      let host = ev.hosts_excerpt || 'Host not set'
      try{
        host = ev.hosts[0]
      } catch (e){}
      return host
    },
    getEventQuestions: async function(id){
      this.toggleLoader(id)
      try {
        let variables = {
          thread: id,
          limit: 20
        }
        let list = await this.$apollo.query({
          query: getLastQA,
          variables: variables
        })
        if( list.data && list.data.getLastQA && list.data.getLastQA.items.length > 0 ){
          this.$set(this.questions, id, list.data.getLastQA.items)
        }
      } catch (e) {console.log('eeeer', e)}
      this.toggleLoader(id)
    },
    getQuestions: async function(){
      let vm = this
      this.toggleLoader('all')
      const ids = map( this.table_data, (ev) => ev.id )
      await each(ids, async (id) => {
        await vm.getEventQuestions(id)
        //await setTimeout(async ()=> await vm.getEventQuestions(id), random(0, 5) * 1000)
      })
      this.toggleLoader('all')
    },
    toggleLoader(target){
      if(this.waiting.indexOf(target) >= 0){
        this.waiting.splice(this.waiting.indexOf(target), 1)
      } else {
        this.waiting.push(target)
      }
    }
  },
  apollo: {
    $subscribe: {
      onUpdatedQAMessage: {
        query: onNewQAMessage,
        result ({ data }) {
          const key = data.onNewQAMessage.thread
          if( isUndefined( this.questions[key] ) ){
            this.$set(this.questions, key, data.onNewQAMessage)
          } else{
            this.questions[key] = concat([data.onNewQAMessage], this.questions[key])
          }
          this.questions[key] = this.questions[key].slice(0, 10)
        },
        skip () {
          return !this.status
        }
      },
    }
  },
}
</script>

<style scoped lang="scss">
/*.host-cell{
  text-wrap: normal;
  white-space: normal;
  line-height: 1.35;
  word-break: normal;
  color: $color-black;

  strong{
    display: block;
    font-size: 12px;
    font-weight: 400;
    opacity: 0.5;
    line-height: 1.2;

    & + span{
      margin-top: 0.35rem;
    }
  }
}*/
.qas-display-table {
  font-size: 0.9375rem;
  display: grid;
  grid-row-gap: 1.5rem;
  grid-column-gap: 1.5rem;
  grid-template-columns: 1fr;

  &__header{
    display: grid;
    grid-column-gap: 20px;
    grid-template-columns: 0.5fr 0.5fr 1fr;
    font-weight: 500;
    color: $color-black;
    text-transform: uppercase;
    font-size: 12px;
  }
  &__item{
    background-color: $color-bg-box;
    border-radius: 5px;
    box-shadow: 0 2px 8px -1px rgba($color-black, 0.11);
    color: rgba($color-black, 0.75);
    transition: all 200ms ease;
    cursor: pointer;
    display: grid;
    grid-column-gap: 20px;
    grid-template-columns: 0.5fr 0.5fr 1fr;
    padding: 1.25rem;
    align-items: normal;
    line-height: 1.4;

    .qas-display-table{
      &__host{
        color: rgba($color-black, 1);
        font-weight: 600;
      }
      &__name{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &__questions{
        &__question{
          display: flex;
          width: 100%;
          position: relative;

          &::before{
            content: '';
            display: block;
            position: absolute;
            top: -0.5rem;
            right: -0.5rem;
            bottom: -0.5rem;
            left: -0.75rem;
            background-color: currentColor;
            opacity: 0;
            border-radius: 5px;
            z-index: 0;
          }
          &:hover::before{
            opacity: 0.1;
          }

          & + .qas-display-table__questions__question{
            margin-top: 0.75rem;
          }

          &__created{
            margin-right: 0.75rem;
            margin-left: 0.75rem;
            font-size: 12px;
            font-style: italic;
            opacity: 0.65;
            flex-shrink: 0;
          }
          &__body{
            color: rgba($color-black, 1);
            position: relative;
            z-index: 1;
            margin-right: auto;
          }
          &__actions{
            margin-left: 0.75rem;
            margin-right: 0;
            position: relative;
            z-index: 1;
          }
        }
      }
    }

    &:hover{
      box-shadow: 0 18px 30px -4px rgba($color-black, 0.25);

      .qas-display-table {
        &__title {
          color: rgba($color-black, 1);
          i{
            color: $color-primary
          }
        }
      }
    }
  }

}
</style>

<template>
    <div>
        <h1 class="page-title">Real-time polls</h1>
        <div v-if="events.length > 0" class="boxes boxes--two margin-top--2"  v-loading="loading_global">
            <div class="box" v-for="(ev, evi) in events" :key="evi" v-on:click.prevent="$router.push({ name: 'polls-edit', params: { id: ev.id } })">
                <el-row type="flex" align="middle" class="box__head">
                    <el-col :xs="12">
                        <el-tag size="mini" :type="getTimerTagType(ev.start)" round>
                            <span class="status-type">
                                <template v-if="['primary','warning', 'danger'].indexOf( getTimerTagType(ev.start) ) >= 0">
                                    Starting
                                </template>
                                <template v-else>Started</template>
                            </span>
                            {{ ev.start | timestamp | timeago }}
                        </el-tag>
                    </el-col>
                    <el-col :xs="12" align="right" class="color-gray opacity--50 text-size--smaller text-overflow">
                        {{ev.location | location_name }}
                    </el-col>
                </el-row>
                <h1 class="margin-top--1 margin-bottom--5 title">{{ev.name}}</h1>
                <el-row>
                    <el-col :span="12">
                        <i class="fad fa-chart-bar fa-lg" :class="{ 'color-primary': ev.results > 0 }"></i>
                    </el-col>
                    <el-col :span="12" align="right">
                        <el-button type="text" class="no-padding">
                            <template v-if="getPollQuestionsCount(ev.id).length > 0">{{getPollQuestionsCount(ev.id).length }}</template>
                            <template v-else>no</template>
                            questions <i class="margin-left--7px fas fa-chevron-right "></i>
                        </el-button>
                    </el-col>
                </el-row>
            </div>
        </div>
        <div v-else class="content-box text-align--center margin-top--2" v-loading="loading_global">
            <img src="@/assets/empty.svg" class="empty-svg">
            <p class="margin-top--2">No real time polls available.</p>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash'
    import store from '@/store'
    import titleMixin from '@/mixins'

    export default {
        name: "PollsList",
        mixins: [titleMixin],
        computed: {
            page_title () {
                return `Real-time polls`
            },
            events: function(){
                return _.filter( _.orderBy( store.getters.events, ['start'] ), { poll: true } )
            }
        },
        methods: {
            getPollQuestionsCount: function(id){
                return _.filter( store.getters.questions, { event: id } )
            }
        }
    }
</script>

<style scoped lang="scss">
    .boxes--two{
        .box{
            padding: 1.5rem 1.5rem 1.5rem;

            display: flex;
            flex-direction: column;

            .title{
                flex-grow: 1;
            }
        }
        h1{
            line-height: 1.35;
            font-size: 1rem;
        }

        @include breakpoint(tablet){
            .box{
                h1{
                    font-size: 1.25rem;
                }
            }
        }

        @include breakpoint(laptop){
            .box {
                padding: 3rem 3rem 2rem;
            }
        }

        .status-type{
            display: none;

            @include breakpoint(laptop){
                display: inline;
            }
        }

        .box__head{
            .el-col + .el-col{
                /*margin-top: 0.5rem;
                margin-bottom: 1rem;
                text-align: left;

                @include breakpoint(laptop){
                    text-align: right;
                    margin-top: 0;
                }*/
            }
        }
    }
</style>
import gql from 'graphql-tag'

export default gql `
    mutation createPollQuestion($input: CreatePollQuestionInput!){
        createPollQuestion(
            input: $input
        ){
            id
            question
            multiple
            excerpt
            answers{
                id
                answer
            }
            results
            separator
            status
            event
        }
    }
`
<template>
    <div>
        <h1 class="page-title">Edit real-time poll</h1>
        <div class="margin-top--2">
            <h1 class="display">{{event.name}}</h1>
            <p v-if="event.excerpt">{{event.excerpt}}</p>
            <div class="meta-box margin-top--2">
                <el-row>
                    <el-col :xs="24" :sm="8" :md="6">
                        <strong><i class="far fa-calendar-alt"></i>Event start</strong>
                        {{ event.start | moment( 'DD MMM YYYY [@] HH:mm' ) }}
                    </el-col>
                    <el-col :xs="24" :sm="8" :md="5">
                        <strong><i class="fas fa-map-signs"></i>Location</strong>
                        {{ event.location | location_name }}
                    </el-col>
                    <el-col :xs="24" :sm="8" :md="5">
                        <strong><i class="fas fa-users-class"></i>Hosts</strong>
                        <el-tooltip placement="right">
                            <div slot="content">
                                <div v-for="host in event.hosts" :key="host">{{ host | host_name }}</div>
                            </div>
                            <div v-if="event.hosts && event.hosts.length > 0" class="host">
                                <span>{{ event.hosts[0] | host_name }}</span> <em>+{{event.hosts.length - 1}}</em>
                            </div>
                            <div v-else class="opacity--50">
                                None
                            </div>
                        </el-tooltip>
                    </el-col>
                    <el-col :xs="24" :sm="8" :md="4">
                        <strong>Status</strong>
                        <el-tag :type="getTimerTagType(event.start)" size="mini" round>
                            <template v-if="getTimerTagType(event.start) === 'info'">Finished</template>
                            <template v-else-if="getTimerTagType(event.start) === 'success'">Live</template>
                            <template v-else>Scheduled</template>
                        </el-tag>
                    </el-col>
                    <el-col :xs="24" :sm="8" :md="4">
                        <strong><i class="far fa-poll"></i>Results</strong>
                        <span class="opacity--50">
                            <template v-if="event.results > 0">
                                <a href="#results">{{ event.results }} answers</a>
                            </template>
                            <template v-else>No results yet</template>
                        </span>
                    </el-col>
                </el-row>
            </div>
            <el-row type="flex" align="middle" class="margin-top--4">
                <el-col :span="12"><h1>Poll questions</h1></el-col>
                <el-col :span="12" align="right">
                    <el-button type="primary" class="hidden--tablet" round v-on:click="addQuestion"><i
                            class="far fa-plus margin-right--05"></i> Add new question
                    </el-button>
                    <el-button type="text" class="color-primary no-padding visible--tablet" round
                               v-on:click="addQuestion"><i class="far fa-plus margin-right--05"></i> Add new <span
                            class="hidden--tablet">question</span></el-button>
                </el-col>
            </el-row>
            <div class="margin-top--2">
                <template v-if="questions.length > 0">
                    <draggable v-model="questions" group="consents" @start="drag = true" @end="finishedDrag"
                               handle=".handle" class="questions" :class="{ 'fields--drag': drag }" :move="checkMove">
                        <template v-for="(question, index) in questions">
                            <template v-if="question.separator">
                                <div class="separator" :key="question.id"
                                     v-on:click.prevent="editSeparator(question.id)">
                                    {{question.question}}
                                </div>
                            </template>
                            <template v-else>
                                <div class="question" :key="question.id" v-loading="loading_global">
                                    <i class="handle fas fa-bars"></i>
                                    <div class="add-separator-before" v-on:click.prevent="addSeparator(index)">ADD
                                        SEPARATOR
                                    </div>
                                    <h2 v-on:click.prevent="selected = selected === question.id ? null : question.id"
                                        :id="question.id">Q{{getQuestionIndex(index)}}. {{question.question}} <i
                                            class="fas expand"
                                            :class="{ 'fa-chevron-up': selected === question.id, 'fa-chevron-down': selected !== question.id }"></i>
                                    </h2>
                                    <p v-if="question.excerpt" class="margin-top--075">{{question.excerpt}}</p>
                                    <div v-if="selected === question.id" class="edit-question margin-top--3">
                                        <el-form label-position="top" :ref="`form_${ question.id.replace(/-/g, '_') }`"
                                                 :rules="rules" :model="questions[index]" @submit.native.prevent
                                                 v-loading="loading">
                                            <el-form-item label="Question" prop="question">
                                                <el-input type="text" v-model="questions[index].question"
                                                          placeholder="Enter the question here"></el-input>
                                            </el-form-item>
                                            <el-form-item label="Description" class="margin-top--2">
                                                <el-input type="textarea" :rows="6" v-model="questions[index].excerpt"
                                                          placeholder="Enter the question here"></el-input>
                                            </el-form-item>
                                            <el-row class="margin-top--2">
                                                <el-col :xs="24" :lg="12" class="color-black">Answers</el-col>
                                                <el-col :xs="24" :lg="12" class="add-multiple">
                                                    <el-switch v-model="questions[index].multiple"
                                                               active-text="Allow multiple answers"></el-switch>
                                                </el-col>
                                            </el-row>
                                            <el-form-item prop="answers" class="answers">
                                                <draggable v-model="questions[index].answers" group="answers"
                                                           handle=".handle-answer" class="answers margin-top--1"
                                                           :move="checkMove">
                                                    <div class="answer"
                                                         v-for="(answer, answer_index) in questions[index].answers"
                                                         :key="answer_index">
                                                        <i class="fas handle-answer fa-bars"></i>
                                                        <el-input
                                                                v-model="questions[index].answers[answer_index].answer"
                                                                type="text" placeholder="Enter answer here"></el-input>
                                                        <i v-if="questions[index].answers.length > 2"
                                                           v-on:click.prevent="questions[index].answers.splice(answer_index, 1)"
                                                           class="fad fa-trash"></i>
                                                    </div>
                                                </draggable>
                                            </el-form-item>
                                            <el-button type="text" class="add-new-answer-button color-primary"
                                                       size="small" v-on:click.prevent="addAnswer"><i
                                                    class="margin-right--7px fas fa-plus"></i> Add new answer
                                            </el-button>
                                            <el-row class="margin-top--4 footer-question">
                                                <el-col :xs="24" :sm="12">
                                                    <el-button type="primary" round v-on:click.prevent="saveQuestion"><i
                                                            class="margin-right--7px fas fa-save"></i> Save question
                                                    </el-button>
                                                    <el-button type="text" class="margin-left--2 color-gray"
                                                               v-if="question_form_changed"
                                                               v-on:click.prevent="resetChanges()"><i
                                                            class="margin-right--7px fas fa-undo"></i> Reset changes
                                                    </el-button>
                                                </el-col>
                                                <el-col :xs="24" :sm="12">
                                                    <el-button type="text" v-if="questions.length > 1"
                                                               v-on:click.prevent="deleteQuestion(question.id)"
                                                               class="color-danger padding-right--0" round><i
                                                            class="margin-right--7px fas fa-times"></i> Delete question
                                                    </el-button>
                                                </el-col>
                                            </el-row>
                                        </el-form>
                                    </div>
                                </div>
                            </template>
                        </template>
                    </draggable>
                </template>
                <template v-else>
                    <div class="text-align--center content-box">
                        <img src="@/assets/empty.svg" class="empty-svg">
                        <p class="margin-top--2">No questions set for this poll.</p>
                    </div>
                </template>
            </div>
            <Results :id="id" :questions="questions" :on_page="true" />
        </div>
        <el-dialog title="Separator" :visible.sync="dialog_separator" width="100%" class="dialog--small">
            <el-input type="text" v-model="separator" placeholder="Enter separator name here"></el-input>
            <span slot="footer" class="dialog-footer">
                <el-button v-if="!is_new_separator" v-on:click.prevent="deleteQuestion(separator_index)" type="text"
                           class="color-danger float--left"><em class="fas fa-fw margin-right--05 fa-times"></em>Remove</el-button>
                <el-button type="text" class="margin-right--1" @click="cancelSeparator()">Cancel</el-button>
                <el-button type="primary" round @click="saveSeparator()">Save</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import draggable from 'vuedraggable'
    import _ from 'lodash'
    import {v4 as uuidv4} from 'uuid';
    import store from '@/store'
    import Results from './Results'
    import Event from '@/schemas/Event'
    import moment from 'moment'
    import titleMixin from '@/mixins'

    export default {
        name: "Poll",
        props: ['id'],
        mixins: [titleMixin],
        data: function () {
            return {
                separator_index: '',
                separator: '',
                dialog_separator: false,
                selected: null,
                selected_witness: JSON.stringify(null),
                rules: {
                    'question': [
                        {required: true, message: 'Please input the question', trigger: 'blur'},
                        {min: 6, message: 'Length should be at least 6 characters', trigger: 'blur'}
                    ],
                    'answers': [
                        {
                            validator: function (rule, value, callback) {
                                _.each(value, function (a) {
                                    if (_.isUndefined(a.answer) || _.isNull(a.answer) || a.answer.length === 0) {
                                        callback(["Answer cannot be empty"]);
                                    }
                                })
                                callback();
                            },
                            trigger: 'blur'
                        }
                    ]
                },
                loading: false,
                questions: [],
                drag: false
            }
        },
        mounted: function () {
            if (this.poll_questions.length > 0) {
                this.setQuestions()
            }
            let vm = this
            store.subscribe((mutation) => {
                if (mutation.type === 'questions/ADD' || mutation.type === 'questions/PATCH') {
                    vm.selected = null
                }
            })
        },
        methods: {
            getQuestionIndex: function (index) {
                let separators = 0
                _.each(this.questions, function (q, i) {
                    if (i <= index && q.separator) {
                        separators++
                    }
                })
                return index - separators + 1
            },
            cancelSeparator: function () {
                let vm = this
                if (this.is_new_separator && this.separator.length === 0) {
                    this.questions = _.filter(this.questions, function (i) {
                        return i.id !== vm.separator_index
                    })
                }
                this.dialog_separator = false
            },
            saveForm: function () {
                if (!_.isUndefined(this.selected) && !_.isNull(this.selected) && !this.is_new_question) {
                    store.dispatch('questions/update', _.find(this.questions, {id: this.selected}))
                } else if (this.is_new_question) {
                    store.dispatch('questions/create', _.find(this.questions, {id: this.selected}))
                }
                store.dispatch('events/patch_poll_order', {id: this.id, poll_order: this.poll_order})
            },
            saveSeparator: function () {
                if (this.is_new_separator) {
                    store.dispatch('questions/create', {
                        ...this.selected_separator,
                        question: this.separator
                    })
                } else {
                    store.dispatch('questions/update', {
                        ...this.selected_separator,
                        question: this.separator
                    })
                }
                store.dispatch('events/patch_poll_order', {id: this.id, poll_order: this.poll_order})
                this.dialog_separator = false
            },
            editSeparator: function (index) {
                this.separator_index = index
                this.separator = JSON.parse(JSON.stringify(this.selected_separator)).question
                this.dialog_separator = true
            },
            addSeparator: function (index) {
                let id = uuidv4()
                let separator = {
                    id: id,
                    separator: true,
                    question: 'untitled separator',
                    event: this.id
                }
                this.questions.splice(index, 0, separator);
                this.separator_index = id
                this.dialog_separator = true
            },
            finishedDrag: function () {
                this.drag = false
                store.dispatch('events/patch_poll_order', {id: this.id, poll_order: this.poll_order})
            },
            saveQuestion: function () {
                let vm = this
                vm.$refs[`form_${ vm.selected.replace(/-/g, '_') }`][0].validate((valid) => {
                    if (valid) {
                        vm.saveForm()
                    } else {
                        return false;
                    }
                });
            },
            addAnswer: function () {
                this.questions[_.findIndex(this.questions, {id: this.selected})].answers.push({id: uuidv4(), value: ''})
            },
            deleteQuestion: function (i) {
                let vm = this
                this.$confirm('This will permanently delete the question. Continue?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'danger'
                }).then(() => {
                    store.dispatch('questions/delete', i)
                    vm.selected = null
                    vm.selected_index = ''
                    vm.dialog_separator = false
                }).catch(() => {
                    return;
                });
            },
            addQuestion: function () {
                let id = uuidv4()
                this.questions.push({
                    id: id,
                    question: '',
                    answers: [{id: uuidv4(), value: ''}, {id: uuidv4(), value: ''}],
                    multiple: false,
                    excerpt: '',
                    event: this.id,
                    order: this.questions.length
                })
                this.selected = id
                this.$nextTick(function () {
                    let el = document.getElementById(`${id}`);
                    let rect = el.getBoundingClientRect();
                    window.scrollTo(rect.x, rect.y - 50)
                })
            },
            checkMove: function (evt) {
                if (evt.draggedContext.element.separator) {
                    return false
                }
                return true
            },
            resetChanges: function () {
                this.$set(this.questions, _.findIndex(this.questions, {id: this.selected}), JSON.parse(this.selected_witness))
            },
            setQuestions: function (questions) {
                let vm = this
                this.questions = _.sortBy(JSON.parse(JSON.stringify(questions || vm.poll_questions)), function (q) {
                    return vm.event.poll_order.indexOf(q.id)
                }, ['desc'])
            }
        },
        computed: {
            page_title () {
                return `Real-time polls | Edit poll`
            },
            selected_separator: function () {
                return _.find(this.questions, {id: this.separator_index})
            },
            poll_questions: function () {
                return _.filter(store.getters.questions, {event: this.id})
            },
            poll_order: function () {
                return _.map(this.questions, function (q) {
                    return q.id
                })
            },
            event_started: function () {
                return moment().unix() >= moment(this.event.start).unix()
            },
            question_form_changed: function () {
                return this.selected_witness !== JSON.stringify(this.selected_form)
            },
            selected_form: function () {
                return this.questions[_.findIndex(this.questions, {id: this.selected})]
            },
            event: function () {
                return _.find(store.getters.events, {id: this.id}) || new Event().toObject()
            },
            is_new_separator: function () {
                return _.map(this.poll_questions, (q) => {
                    return q.id
                }).indexOf(this.separator_index) === -1
            },
            is_new_question: function () {
                return _.map(this.poll_questions, (q) => {
                    return q.id
                }).indexOf(this.selected) === -1
            }
        },
        watch: {
            selected: function (n) {
                this.selected_witness = _.isNull(n) ? JSON.stringify(null) : JSON.stringify(this.questions[_.findIndex(this.questions, {id: n})])
            },
            dialog_separator: function (n) {
                if (!n) {
                    this.separator = ''
                }
            },
            poll_questions: function (n) {
                this.setQuestions(n)
            },
            event: function () {
                this.setQuestions()
            }
        },
        components: {
            draggable,
            Results
        },
        beforeRouteEnter: async function (to, from, next){
          if( ! _.isUndefined( to.params ) && !_.isNull(to.params) && ! _.isUndefined( to.params.id ) ){
            await store.dispatch('events/get', to.params.id)
          }
          next()
        }
    }
</script>

<style scoped lang="scss">
    .host {
        display: inline-flex;
        flex-wrap: nowrap;
        cursor: pointer;
        text-transform: capitalize;

        span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        em {
            font-style: normal;
            color: $color-black;
            display: inline-block;
            margin-left: 0.5rem;
            font-weight: 500;
        }
    }

    .add-separator-before {
        position: absolute;
        top: -1.4rem;
        left: 0;
        right: 0;
        height: 1.4rem;
        background-color: rgba($color-green, 0);
        border-radius: 3px;
        cursor: pointer;
        transition: all 120ms ease;
        opacity: 0;
        display: none;
        text-align: center;
        font-size: 0.625rem;
        text-transform: uppercase;
        line-height: 1.4rem;
        color: $color-green;
        justify-content: center;
        align-items: center;

        &::before {
            content: '';
            display: block;
            border-bottom: 4px dashed rgba($color-green, 0.75);
            transition: all 120ms ease;
            flex-basis: 20%;
            flex-grow: 0;
            margin: 0 1rem;
        }
        &::after {
            content: '';
            display: block;
            border-bottom: 4px dashed rgba($color-green, 0.75);
            transition: all 120ms ease;
            flex-basis: 20%;
            flex-grow: 0;
            margin: 0 1rem;
        }

        &:hover {

            opacity: 1;
        }
    }

    .display {
        font-size: 2rem;
    }

    .empty-svg {
        max-width: 15rem;
        margin: 0 auto;
    }

    .meta-box {
        background-color: $color-bg-box;
        border-radius: 5px;
        box-shadow: 0 2px 8px -1px rgba($color-black, 0.11);
        padding: 1rem 1.5rem;
        transition: all 200ms ease;

        .el-col {
            position: relative;
            padding-left: 2rem;
            color: $color-gray;
            font-size: 0.8125rem;

            & + .el-col {
                margin-top: 1rem;

                @include breakpoint(tablet) {
                    &:nth-child(2),
                    &:nth-child(3) {
                        margin-top: 0;
                    }
                }

                @include breakpoint(laptop) {
                    margin-top: 0;
                }
            }
        }

        strong {
            color: $color-black;
            font-weight: 500;
            display: block;
            margin-bottom: 0.35rem;
            font-size: 0.9375rem;
        }
        i {
            position: absolute;
            left: 0;
            top: 2px;
        }
    }

    .question {
        background-color: $color-bg-box;
        border-radius: 5px;
        box-shadow: 0 2px 8px -1px rgba($color-black, 0.11);
        padding: 1rem 2rem 1rem 3.5rem;
        transition: all 200ms ease;
        position: relative;

        & + .question {
            margin-top: 1.5rem;

            .add-separator-before {
                display: flex;
            }
        }

        .add-multiple {
            margin-top: 0.75rem;
            margin-bottom: 0.5rem;
        }

        h2 {
            margin: 0;
            color: $color-black;
            font-size: 1rem;
            font-weight: 400;
            cursor: pointer;
        }
        p {
            font-size: 0.8125rem;
            margin: 0 20% 0 0;
            line-height: 1.5;
            opacity: 0.75;
        }
        .expand {
            padding: 0;
            position: absolute;
            top: 1.15rem;
            right: 1rem;
        }
        .handle {
            position: absolute;
            left: 1.25rem;
            top: 1.2rem;
            font-size: 1.15rem;
        }
        .edit-question {
            margin-left: -2.5rem;
            margin-right: -1rem;
            padding-bottom: 1rem;
        }

        .add-new-answer-button {
            margin-top: 0.75rem;
        }

        .footer-question {
            text-align: center;

            .el-button {
                display: block;
                width: 100%;
                margin: 1rem 0 0;
            }
        }

        @include breakpoint(tablet) {
            padding: 1.5rem 2rem 1.5rem 5rem;

            h2 {
                font-size: 1.25rem;
            }

            .expand {
                top: 1.75rem;
                right: 2rem;
            }

            .handle {
                left: 2rem;
                top: 1.85rem;
            }
            .edit-question {
                margin-left: 0;
                margin-right: 0;
            }

            .add-multiple {
                text-align: right;
                margin: 0;
            }
            .add-new-answer-button {
                margin-left: 2rem;
            }
            .footer-question {
                text-align: left;

                .el-col + .el-col {
                    text-align: right;
                    padding-top: 0.35rem;
                }
                .el-button {
                    display: inline-block;
                    width: auto;
                }
            }
        }
        @include breakpoint(desktop) {
            .edit-question {
                margin-right: 3rem;
                padding-bottom: 3rem;
            }
        }
    }

    .separator {
        text-align: center;
        padding: .35rem 2rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
        background-color: rgba($color-gray, 0.1);
        border: 1px solid rgba($color-gray, 0.05);
        font-size: 0.625rem;
        text-transform: uppercase;
        color: $color-gray;
        border-radius: 3px;
        cursor: pointer;
    }

    .answers {
        .answer {
            position: relative;
            padding: 0 0 0 2rem;

            & + .answer {
                margin-top: 1rem;
            }

            .handle-answer {
                position: absolute;
                left: 0;
                top: 1rem
            }
            .el-input {
                position: relative;

                &__inner {
                    padding-right: 3rem;
                }
            }
            .fa-trash {
                position: absolute;
                top: 1rem;
                right: 1rem;
                cursor: pointer;

                &:hover {
                    color: $color-primary
                }
            }
        }
    }
</style>
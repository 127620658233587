<template>
	<div>
		<template v-if="messages.length > 0">
			<div class="qa">
				<div
					class="qa__question"
					v-for="(message, mi) in messages_list"
					:key="mi"
					:class="{ 'qa__question--published': message.status === 2 }"
				>
					<div class="name">
						<avatar
							:username="message.sender_name"
							:backgroundColor="getRandomColor(message.id)"
							:lighten="-40"
							:size="45"
						></avatar>
						<strong>{{message.sender || message.sender_name}} - {{message.created | moment('HH:mm')}}</strong>
					</div>
					<template v-if="( moderator && moderation_status ) || ( !moderator && status )">
						<div class="actions">
							<el-button
								type="text"
								size="small"
								class="actions--ban color-gray"
								v-on:click.prevent="banUser(message.id)"
							><i class="fad fa-user-slash margin-right--7px"></i>Ban</el-button>
							<el-button
								type="text"
								size="small"
								class="actions--kick color-black "
								v-on:click.prevent="kickUser(message.id)"
							><i class="fad fa-user-times margin-right--7px"></i>Kick</el-button>
							<el-button
								type="text"
								size="small"
								class="actions--reject color-danger"
								v-on:click.prevent="rejectQuestion(message.id)"
							><i class="far fa-times margin-right--7px"></i>Reject</el-button>
							<el-button
								type="text"
								size="small"
								class="actions--accept color-green"
								v-on:click.prevent="acceptQuestion(message.id)"
							><i class="far fa-check margin-right--7px"></i>Accept</el-button>
						</div>
					</template>
					<p v-text="message.body"></p>
				</div>

			</div>
		</template>
		<template v-else>
			<div class="content-box text-align--center">
				<img
					src="@/assets/empty.svg"
					class="empty-svg"
				>
				<p class="margin-top--2">No users questions available for moderation.</p>
			</div>
		</template>
	</div>
</template>

<script>
import _ from 'lodash'
import store from '@/store'
import Avatar from "vue-avatar/src/Avatar";
import onUpdatedQAMessage from '@/appsync/subscriptions/qas/onUpdatedQAMessage'
import onCheckForOnlineModerator from '@/appsync/subscriptions/qas/onCheckForOnlineModerator'
import listQAMessages from '@/appsync/queries/qas/list'
import webAuth from "@/auth"
import updateQAMessage from '@/appsync/mutations/qas/update'
import checkForOnlineModerator from '@/appsync/mutations/qas/checkForOnlineModerator'
import kickQAParticipant from '@/appsync/mutations/qas/kick'
import Vue from 'vue'
export default {
	name: "qa",
	props: ['id', 'moderator', 'moderation_status', 'status'],
	data: function () {
		return {
			messages: [],
			next_token: null,
			moderator_online: false,
			waiting_moderator: false
		}
	},
	components: {
		Avatar
	},
	mounted: function () {
		this.checkIfStarted()
		this.listMessages()
		if (_.isUndefined(this.moderator) || !this.moderator) {
			this.checkForOnlineModerator()
		}
		this.$apollo.subscriptions.onUpdatedQAMessage.refresh()
	},
	watch: {
		moderation_status: function (n, o) {
			this.replyOnline()
			if (this.moderator && n && !o && this.event_status && this.qa_status) {
				this.listMessages()
			}
		}
	},
	methods: {
		acceptQuestion: function (id) {
			this.updateMessage({ id, status: this.moderator ? 1 : 2 })
		},
		rejectQuestion: function (id) {
			this.updateMessage({ id, status: -1 })
		},
		kickUser: function (id) {
			this.updateMessage({ id, status: -2 })
		},
		banUser: function (id) {
			this.updateMessage({ id, status: -3 })
		},
		updateMessage: async function ({ id, status }) {
			try {
				let variables = {
					input: {
						id: id,
						status: status
					}
				}
				let update = await this.$apollo.mutate({
					mutation: status >= -1 ? updateQAMessage : kickQAParticipant,
					variables: variables
				})
				if (this.moderator) {
					let remove = _.findIndex(this.messages, { id: status >= -1 ? update.data.updateQAMessage.id : update.data.kickQAParticipant.id })
					if (remove >= 0) {
						this.messages.splice(remove, 1)
					}
				} else {
					if (status === 2) {
						let update_index = _.findIndex(this.messages, { id: update.data.updateQAMessage.id })
						if (update_index >= 0) {
							Vue.set(this.messages, update_index, update.data.updateQAMessage)
						}
					} else {
						let remove = _.findIndex(this.messages, { id: status >= -1 ? update.data.updateQAMessage.id : update.data.kickQAParticipant.id })
						if (remove >= 0) {
							this.messages.splice(remove, 1)
						}
					}
				}

			} catch (e) {
				window.console.log('eror', e)
				if (!_.isUndefined(e.networkError) && !_.isUndefined(e.networkError.statusCode) && e.networkError.statusCode === 401) {
					webAuth.authorize()
				}
			}
		},
		listMessages: async function () {
			try {
				let filters = {}
				if (this.moderator) {
					filters = {
						status: {
							eq: 0
						}
					}

				} else {
					filters = {
						status: {
							between: this.moderator_online ? [1, 2] : [0, 2]
						}
					}
				}

				let variables = {
					thread: this.id,
					filter: filters
				}
				if (this.next_token !== null) {
					variables.nextToken = this.next_token
				}
				let list = await this.$apollo.query({
					query: listQAMessages,
					variables: variables
				})
				this.messages = list.data.listQAMessages.items
			} catch (e) {
				if (!_.isUndefined(e.networkError) && !_.isUndefined(e.networkError.statusCode) && e.networkError.statusCode === 401) {
					webAuth.authorize()
				}
			}
		},
		checkForOnlineModerator: async function () {
			try {
				let vm = this
				this.waiting_moderator = true
				await this.$apollo.mutate({
					mutation: checkForOnlineModerator,
					variables: {
						thread: vm.id,
						body: `is-online`,
						id: vm.logged_user.sub
					}
				})
				setTimeout(function () {
					if (vm.waiting_moderator) {
						vm.moderator_online = false
					}
				}, 5000)
			} catch (e) {
				if (!_.isUndefined(e.networkError) && !_.isUndefined(e.networkError.statusCode) && e.networkError.statusCode === 401) {
					webAuth.authorize()
				}
			}
		},
		replyOnline: async function () {
			try {
				let vm = this
				await this.$apollo.mutate({
					mutation: checkForOnlineModerator,
					variables: {
						thread: this.id,
						body: vm.moderation_status ? `im-online` : 'not-online',
						id: vm.logged_user.sub
					}
				})
			} catch (e) {
				if (!_.isUndefined(e.networkError) && !_.isUndefined(e.networkError.statusCode) && e.networkError.statusCode === 401) {
					webAuth.authorize()
				}
			}
		},
		checkIfStarted: function () {
			store.dispatch('events/get', this.id)
		}
	},
	apollo: {
		$subscribe: {
			onUpdatedQAMessage: {
				query: onUpdatedQAMessage,
				variables: function () {
					return {
						thread: this.id,
						status: this.moderator_online ? 1 : 0
					}
				},
				result ({ data }) {
					this.messages.push(data.onUpdatedQAMessage)
				},
				skip () {
					return this.skip_update_message
				}
			},
			onCheckForOnlineModerator: {
				query: onCheckForOnlineModerator,
				variables: function () {
					return {
						thread: this.id
					}
				},
				result ({ data }) {
					if (this.moderator && data.onCheckForOnlineModerator.body === `is-online` && data.onCheckForOnlineModerator.id !== this.logged_user.sub) {
						this.replyOnline()
					}
					if (_.isUndefined(this.moderator) && data.onCheckForOnlineModerator.body === `im-online` && data.onCheckForOnlineModerator.id !== this.logged_user.sub) {
						this.waiting_moderator = false
						this.moderator_online = true
					} else if (_.isUndefined(this.moderator) && data.onCheckForOnlineModerator.body === `not-online`) {
						this.waiting_moderator = false
						this.moderator_online = false
					}
				}
			}
		}
	},
	computed: {
		skip_update_message: function () {
			if (this.moderator) {
				return !this.qa_status || !this.event_status || !this.moderation_status
			}
			return false
		},
		event: function () {
			return _.find(store.getters.events, { id: this.id })
		},
		event_status: function () {
			try {
				return this.event.status === 1
			} catch (e) {
				return false
			}
		},
		qa_status: function () {
			try {
				return this.event.qa_status === 1
			} catch (e) {
				return false
			}
		},
		messages_list: function () {
			return _.uniqBy(_.orderBy(this.messages, ['status', 'created'], ['desc', 'asc']), 'id')
		}
	}
}
</script>

<style scoped lang="scss">
.qa {
	background-color: $color-bg-box;
	border-radius: 5px;
	box-shadow: 0 2px 8px -1px rgba($color-black, 0.11);
	padding: 0.5rem 1rem;
	transition: all 200ms ease;

	strong {
		color: $color-black;
		font-weight: 500;
	}
	p {
		margin: 0.25rem 0 0 0;
		line-height: 1.4;
	}

	&__question {
		position: relative;
		padding: 1rem 0 1rem 4rem;
		display: flex;
		flex-direction: column;

		.actions {
			order: 5;
			display: flex;
			flex-wrap: wrap;
			width: 100%;
			text-align: left;
			margin-top: 1rem;
			margin-bottom: 1rem;

			&--accept,
			&--reject,
			&--ban,
			&--kick {
				padding: 0;
				flex-basis: 50%;
				text-align: left;
				margin: 0;
				flex-grow: 0;
				flex-shrink: 0;
			}

			&--accept {
				order: -1;
			}
			&--reject {
				order: 0;
			}
			&--kick {
				margin-top: 1.5rem;
				order: 1;
			}
			&--ban {
				margin-top: 1.5rem;
				order: 2;
			}
		}

		& + .qa__question {
			border-top: 1px solid $color-border;
		}

		&--published {
			opacity: 0.5;
			position: relative;
			filter: grayscale(100%);
			color: $color-gray;

			&::before {
				background-color: rgba($color-bg, 0.5);
				content: "";
				position: absolute;
				top: -1px;
				left: -2rem;
				right: -2rem;
				bottom: -1px;
				z-index: -1;
				display: block;
			}

			&:first-of-type {
				&::before {
					border-top-right-radius: 5px;
					border-top-left-radius: 5px;
				}
			}
			&:last-of-type {
				&::before {
					border-bottom-right-radius: 5px;
					border-bottom-left-radius: 5px;
				}
			}

			strong,
			p {
				text-decoration: line-through;

				color: $color-gray;
			}
		}
	}

	.vue-avatar--wrapper {
		position: absolute;
		left: 0;
	}

	@include breakpoint(tablet) {
		padding: 0.5rem 2rem;

		&__question {
			padding: 1.5rem 0 1.5rem 6rem;
			display: block;
			position: relative;

			.actions {
				margin-left: 1rem;
				width: auto;
				position: absolute;
				top: 0;
				right: 0;
				flex-wrap: nowrap;

				&--accept,
				&--reject,
				&--ban,
				&--kick {
					padding: 0;
					flex-basis: auto;
					margin: 0 0 0 2rem;
					flex-grow: 0;
					flex-shrink: 0;
					order: 0;
				}
			}
			.name {
				margin-right: 23rem;
			}
			&:hover:not(&--published) {
				.actions {
					display: flex;
				}
			}
		}
		.vue-avatar--wrapper {
			position: absolute;
			left: 1rem;
		}
	}
}
</style>
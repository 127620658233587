import gql from 'graphql-tag'

export default gql `
    subscription onNewQAMessage{
        onNewQAMessage{
            id
            body
            sender
            sender_name
            sender_id
            read
            created
            updated
            ts
            thread
            status
        }
    }
`
<template>
    <div>
        <UsersHeader title="User not found"></UsersHeader>
        <div class="content-box margin-top--2 text-align--center">
            <img src="@/assets/empty.svg" class="empty-svg">
            <p class="margin-top--2">Username could not be found please <a href="#" v-on:click.prevent="$router.go(-2)">go back</a> and try again.</p>
        </div>
    </div>
</template>

<script>
    import UsersHeader from "@/components/users/UsersHeader";

    export default {
        name: "UserMissing",
        components: { UsersHeader},
        data: function(){
            return {}
        },
        computed: {
        },
        methods: {
        }
    }
</script>

<style scoped lang="scss">
    .empty-svg{
        max-width: 10rem;
        height: auto;
        margin: 0 auto;
    }
</style>
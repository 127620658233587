import store from '@/store'
function getTitle (vm) {
    const { page_title } = vm
    if (page_title) {
        return typeof page_title === 'function'
            ? page_title.call(vm)
            : page_title
    }
}

const serverTitleMixin = {
    created () {
        const page_title = getTitle(this)
        if (page_title) {
            this.$ssrContext.title = `${store.getters.settings.name || process.env.VUE_APP_EVENT_NAME || '360live.events'} | ${page_title}`
        }
    }
}

const clientTitleMixin = {
    mounted () {
        const page_title = getTitle(this)
        if (page_title) {
            document.title = `${store.getters.settings.name || process.env.VUE_APP_EVENT_NAME || '360live.events'} | ${page_title}`
        }
    }
}

export default process.env.VUE_ENV === 'server'
    ? serverTitleMixin
    : clientTitleMixin
import gql from 'graphql-tag'

export default gql `
    mutation updateQAMessage($input: UpdateQAMessageInput!){
        updateQAMessage(
            input: $input
        ){
            id
            body
            sender
            sender_name
            sender_id
            read
            created
            updated
            ts
            thread
            status
        }
    }
`
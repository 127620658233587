import gql from 'graphql-tag'

export default gql `
    mutation updatePollQuestion($input: UpdatePollQuestionInput!){
        updatePollQuestion(
            input: $input
        ){
            id
            question
            multiple
            excerpt
            answers{
                id
                answer
            }
            results
            separator
            status
            event
        }
    }
`
import gql from 'graphql-tag'
export default gql `
    query listThreads($limit: Int, $nextToken: String, $location: String){
        listThreads(
            limit: $limit,
            nextToken: $nextToken,
            location: $location
        ){
            items{
                id
                location
                ts
                created
                last_message{
                    id
                    body
                    from{
                        name
                        id
                    }
                    read
                    created
                    ts
                    thread
                    type
                    location
                }
                author{
                    name
                    id
                    email
                    phone
                }
            },
            nextToken
         }
    }
`
import gql from 'graphql-tag'

export default gql `
    mutation updateInboxMessage($input: UpdateInboxMessageInput!){
        updateInboxMessage(
            input: $input
        ){
            id
            read
        }
    }
`
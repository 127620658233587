import gql from 'graphql-tag'

export default gql `
    subscription onCheckForOnlineModerator($thread: String) {
        onCheckForOnlineModerator(
            thread: $thread
        ) {
            thread
            body
            id
        }
    }
`
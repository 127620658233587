import gql from 'graphql-tag'
export default gql `
    query listBroadcasts($limit: Int, $nextToken: String){
        listBroadcasts(
            limit: $limit,
            nextToken: $nextToken
        ){
            items{
                id
                type
                from
                subject
                body
                delivery
                delivery_date
                state_machine
                status
                created
                updated
                sent
                filters
                recipients
            },
            nextToken
         }
    }
`
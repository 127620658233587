<template>
    <div>
        <template v-for="(pq, qi) in poll_questions">
            <template v-if="pq.separator">
                <div class="polls__separator" :key="qi">
                    {{pq.question}}
                </div>
            </template>
            <template v-else>
                <!--<pre :key="qi" >{{ JSON.stringify(pq, 0 , 4) }}</pre>-->
                <el-row class="polls__question" :key="qi" type="flex" align="middle">
                    <el-col :xs="24" :sm="18" :lg="16">
                        <h1><span class="display--inline-block margin-right--05">Q{{qi+1}}.</span> {{pq.question}}</h1>
                        <p>{{pq.excerpt}}</p>
                        <div class="visible--mobile margin-top--4">
                            <template v-if="pq.status === 1">
                                <el-button type="warning" plain size="small" round v-on:click="hideQuestion(pq.id)"><i class="fas fa-fw fa-eye-slash margin-right--7px"></i>Hide</el-button>
                            </template>
                            <template v-else>
                                <el-button type="success" size="small" round v-on:click="publishQuestion(pq.id)"><i class="fas fa-fw fa-check margin-right--7px"></i>Publish</el-button>
                            </template>
                        </div>
                    </el-col>
                    <el-col :xs="0" :sm="5" :lg="4" :offset="1" align="center">
                        <template v-if="pq.status === 1">
                            <el-button type="warning" plain size="small" round v-on:click="hideQuestion(pq.id)"><i class="fas fa-fw fa-eye-slash margin-right--7px"></i>Hide</el-button>
                        </template>
                        <template v-else>
                            <el-button type="success" size="small" round v-on:click="publishQuestion(pq.id)"><i class="fas fa-fw fa-check margin-right--7px"></i>Publish</el-button>
                        </template>
                    </el-col>
                    <el-col align="right" :xs="0" :sm="4" :lg="3">
                      <div class="progress-cicle">
                        <el-progress type="circle" :percentage="25" :strokeWidth="6" :show-text="false" color="#bbc0d7" :width="80"></el-progress>
                        <div class="nr"><strong>{{pq.results}}</strong>answers</div>
                      </div>
                    </el-col>
                </el-row>
            </template>
        </template>
    </div>
</template>

<script>
    import _ from 'lodash'
    import store from '@/store'
    export default {
        name: "poll",
        props: ['id'],
        data: function(){
            return {
                poll_questions: []
            }
        },
        methods: {
            setQAS: function(n){
                let vm = this
                if( ! _.isUndefined( this.event ) ){
                    this.poll_questions = _.sortBy( JSON.parse( JSON.stringify( n || this.questions || [] ) ), function(q){
                        return vm.event.poll_order.indexOf(q.id)
                    })
                }
            },
            publishQuestion: function(id){
                this.updateQuestion({
                    id,
                    status: 1
                })
            },
            hideQuestion: function(id){
                this.updateQuestion({
                    id,
                    status: 0
                })
            },
            updateQuestion: function(form){
                store.dispatch( 'questions/updateStatus', form )
            }
        },
        mounted: function(){
            this.setQAS()
        },
        computed: {
            event: function(){
                return _.find( store.getters.events, { id: this.id } )
            },
            questions: function(){
                return _.filter( store.getters.questions, { event: this.id } )
            }
        },
        watch: {
            event: function(){
                this.setQAS()
            },
            questions: function(n){
                this.setQAS(n)
            }
        }
    }
</script>

<style scoped lang="scss">
    .polls {
        background-color: $color-bg-box;
        border-radius: 5px;
        box-shadow: 0 2px 8px -1px rgba($color-black, 0.11);
        padding: 0 2rem;
        transition: all 200ms ease;



        &__question{
            padding: 2rem 0;

            h1{
                margin: 0 0 0.5rem;
                line-height: 1.45;
                font-size: 1rem;
            }
            p{
                margin: 0;
                line-height: 1.45;
            }

            & + .polls__question{
                border-top: 1px solid $color-border;
            }

            .el-button{
                width: 100%;
                text-align: center;
            }

        }

        &__separator{
            text-align: center;
            background-color: rgba($color-bg, 1);
            padding: 1rem 1rem;
            margin: 1rem 0;
            text-transform: uppercase;
            border-radius: 5px;
            color: $color-black;
            font-size: 0.75rem;
        }

        @include breakpoint(tablet){
            &__question{
                padding: 2rem 0;

                h1{
                    font-size: 1.25rem;
                }
            }
        }

    }
</style>
<template>
    <div v-if="faq_list.length > 0">
        <div class="faq" :class="{ selected: faq !== null }">
            <div class="faq__side">
                <h1>Frequently asked questions</h1>
                <div class="list">
                    <a v-for="(q, qi) in faq_list" :key="qi" href="#" class="list_item" v-on:click.prevent="faq = qi" :class="{ selected: qi === faq }"><span>{{q.question}}</span></a>
                </div>
            </div>
            <div class="faq__content" v-if="faq !== null && faq_list[faq]">
                <h1 v-on:click.prevent="goBack"><i class="far fa-fw fa-sm visible--mobile fa-chevron-left margin-right--1"></i>{{faq_list[faq].question}}</h1>
                <vue-markdown :source="faq_list[faq].answer"></vue-markdown>
            </div>
        </div>
    </div>
</template>

<script>
    import VueMarkdown from 'vue-markdown';
    import store from '@/store';
    export default {
        name: "faq",
        data: function () {
            return {
                faq: null
            }
        },
        components: {
            VueMarkdown
        },
        computed: {
            faq_list: function () {
                return store.getters.faq || []
            }
        },
        mounted: function(){
            if( window.innerWidth >= 834 ){
                this.faq = 0
            }
        },
        methods: {
            goBack: function(){
                if( window.innerWidth < 834 ){
                    this.faq = null
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .faq{
        background-color: $color-bg-box;
        border-radius: 5px;
        box-shadow: 0 2px 8px -1px rgba($color-black, 0.11);
        //padding: 1rem 1.5rem;
        transition: all 200ms ease;
        display: grid;


        &__side{
            border-right: 1px solid $color-border;
            min-height: 40vh;

            h1{
                padding: 1rem 2rem;
                border-bottom: 1px solid $color-border;
                margin: 0;
                font-size: 1rem;
            }
            .list{
                padding: 1rem 2rem;
                margin-top: 1rem;

                a{
                    display: block;
                    color: $color-black;
                    line-height: 1.3;
                    position: relative;
                    padding: .65rem 0;

                    &::before{
                        content: '';
                        display: block;
                        border-radius: 5px;
                        background-color: $color-bg;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        right: -1rem;
                        left: -1rem;
                        z-index: 0;
                        opacity: 0;
                    }

                    &:hover{
                        &::before{
                            opacity: 1;
                        }
                    }

                    &.selected{
                        font-weight: 500;

                        &::before{
                            opacity: 1;
                            background-color: rgba($color-primary, 0.1);
                        }
                    }

                    & + a{
                        margin-top: .25rem;
                    }

                    span{
                        display: inline-block;
                        position: relative;
                    }
                }
            }
        }
        &__content{
            padding: 1.5rem ;
            line-height: 1.45;
            display: none;

            h1{
                margin-bottom: 2rem;
                line-height: 1.25;
                font-size: 1rem;
            }
        }

        &.selected{
            .faq{
                &__side{
                    display: none;
                }
                &__content{
                    display: block;
                }

                @include breakpoint(tablet) {

                    &__side {
                        display: block;
                    }
                }
            }
        }

        @include breakpoint(tablet){
            grid-template-columns: 25rem 1fr;

            &__side{
                display: block;

                h1{
                    font-size: 1.25rem;
                }
            }
            &__content{
                display: block;
                padding: 2.5rem;

                h1{
                    font-size: 1.25rem;
                    line-height: 1.4;
                }
            }
        }

        @include breakpoint(desktop){
            &__content{
                padding: 3.5rem 5.5rem;
            }
        }

    }
</style>
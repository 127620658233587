import SchemaObject from "schema-object";
const AppMetadata = new SchemaObject({
    role: {
        type: String,
        default: "visitor"
    },
    permissions: {
        type: String,
        default: ""
    }
});

const UserMetadata = new SchemaObject({
    first_name: {
        type: String,
        default: ""
    },
    last_name: {
        type: String,
        default: ""
    },
    phone: {
        type: String,
        default: ""
    },
    permissions: {
        type: String,
        default: ""
    },
    custom_1: {
        type: String,
        default: ""
    },
    custom_2: {
        type: String,
        default: ""
    }
});

export default new SchemaObject({
    user_id: String,
    email: {
        type: String,
        default: ""
    },
    password: {
        type: String,
        default: ""
    },
    retype: {
        type: String,
        default: ""
    },
    created_at: String,
    updated_at: String,
    app_metadata: AppMetadata,
    user_metadata: UserMetadata
});
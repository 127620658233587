import gql from 'graphql-tag'

export default gql `
    subscription onUpdatedImport{
        onUpdatedImport{
            id
            status
            ttl
        }
    }
`
<template>
    <div>
        <h1>Live video</h1>
        <div class="margin-top--2" v-if="event">
            <h1 class="display">{{event.name}}</h1>
            <p>{{event.excerpt}}</p>
            <div class="meta-box margin-top--2">
                <el-row>
                    <el-col :xs="24" :sm="8" :lg="6">
                        <strong><i class="far fa-calendar-alt"></i>Event start</strong>
                        {{ event.start | moment( 'MMM DD, YYYY [@] HH:mm' ) }}
                    </el-col>
                    <el-col :xs="24" :sm="10" :lg="12">
                        <counter :event="event"></counter>
                    </el-col>
                    <el-col :xs="24" :sm="6" :lg="6">
                        <el-button class="display--block--tablet" :disabled="!is_role_360 || loading_status"
                                   :type="event_status ? 'danger' : 'success'" round
                                   v-on:click.prevent="event_status = !event_status ? 1 : 0">
                            <i class="fa-fw margin-right--05" :class="start_event_button_classes"></i>
                            <template v-if="!event_status">Start</template>
                            <template v-else>Stop</template>
                            session
                        </el-button>
                    </el-col>
                </el-row>
                <div v-if="event_status && ( has_q_a || has_poll ) && is_role_360" class="actions">
                    <el-button v-if="has_q_a" type="primary" :plain="qa_status === 1" round
                               :disabled="poll_status > 0 || loading_qa"
                               v-on:click.prevent="qa_status = qa_status === 0 ? 1 : 0"><i
                            class="fa-fw margin-right--05" :class="start_qa_button_classes"></i>
                        <template v-if="!qa_status">Start</template>
                        <template v-else>Stop</template>
                        Q&A session
                    </el-button>
                    <el-button v-if="has_poll" type="primary" :plain="poll_status === 1" round
                               :disabled="qa_status > 0 || loading_poll"
                               v-on:click.prevent="poll_status = poll_status === 0 ? 1 : 0"><i
                            class="fa-fw margin-right--05" :class="start_poll_button_classes"></i>
                        <template v-if="!poll_status">Start</template>
                        <template v-else>Stop</template>
                        real-time poll
                    </el-button>
                </div>
            </div>
            <qa :status="qa_status === 1" :event_status="event_status === 1" :id="id"
              class="margin-top--2"></qa>
            <poll  :id="id" class="polls margin-top--2"></poll>
            <faq class="margin-top--2"></faq>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash'
    import store from '@/store'

    import counter from "@/components/video/counter"
    import qa from "@/components/video/qa"
    import poll from "@/components/video/poll"
    import faq from "@/components/video/faq"
    import moment from 'moment'
    import titleMixin from '@/mixins'

    export default {
        name: "LiveVideo",
        props: ['id'],
        data: function () {
            return {
                //event_status: false,
                //qa_status: false,
                loading_status: false,
                loading_qa: false,
                loading_poll: false
            }
        },
        mixins: [titleMixin],
        mounted: function () {
            let vm = this
            store.subscribe((mutation) => {
                if (mutation.type === 'events/PATCH' && mutation.payload.id === vm.id) {
                    vm.loading_status = false
                    vm.loading_qa = false
                    vm.loading_poll = false
                }
            })

            /*let qa_status = localStorage.getItem( `_chat_${this.id}_qa_status` )
            if( ! _.isUndefined( qa_status ) && ! _.isNull( qa_status ) ){
                this.qa_status = qa_status == 'true'
            }
            let poll_status = localStorage.getItem( `_chat_${this.id}_poll_status` )
            if( ! _.isUndefined( poll_status ) && ! _.isNull( poll_status ) ){
                this.poll_status = poll_status == 'true'
            }*/
            this.checkIfStarted()
        },
        components: {
            counter,
            qa,
            poll,
            faq
        },
        computed: {
            page_title () {
                return `Dashboard | Live video`
            },
            start_event_button_classes: function () {
                if (this.loading_status) {
                    return ['fad', 'fa-spin', 'fa-spinner-third']
                } else if (!this.event_status) {
                    return ['fas', 'fa-play']
                }
                return ['fas', 'fa-stop']
            },
            start_qa_button_classes: function () {
                if (this.loading_qa) {
                    return ['fad', 'fa-spin', 'fa-spinner-third']
                } else if (this.qa_status === 0) {
                    return ['fas', 'fa-play']
                }
                return ['fas', 'fa-stop']
            },
            start_poll_button_classes: function () {
                if (this.loading_poll) {
                    return ['fad', 'fa-spin', 'fa-spinner-third']
                } else if (this.poll_status === 0) {
                    return ['fas', 'fa-play']
                }
                return ['fas', 'fa-stop']
            },
            event_status: {
                get: function () {
                    try {
                        return this.event.status === 1
                    } catch (e) {
                        return 0
                    }
                },
                set: function (v) {
                    if (this.is_role_360) {
                        this.loading_status = true
                        let update = {id: this.id, status: v}
                        if (this.event.sync) {
                            update.sync_updated = moment().unix()
                        }
                        if( update.status === 0 ){
                            update.qa_status = 0
                            update.poll_status = 0
                            update.stopped = moment().unix()
                        } else {
                            update.started = moment().unix()
                        }
                        store.dispatch('events/patch_status', update)
                    }
                }
            },
            qa_status: {
                get: function () {
                    try {
                        return this.event.qa_status
                    } catch (e) {
                        return 0
                    }
                },
                set: function (v) {
                    if (this.is_role_360) {
                        this.loading_qa = true
                        store.dispatch('events/patch_status', {id: this.id, qa_status: v})
                    }
                }
            },
            poll_status: {
                get: function () {
                    try {
                        return this.event.poll_status
                    } catch (e) {
                        return 0
                    }
                },
                set: function (v) {
                    if (this.is_role_360) {
                        this.loading_poll = true
                        store.dispatch('events/patch_status', {id: this.id, poll_status: v})
                    }
                }
            },
            event: function () {
                return _.find(store.getters.events, {id: this.id})
            },
            has_poll: function () {
                return this.event.poll
            },
            has_q_a: function () {
                return this.event.qa
            },
            is_role_360: function () {
                return store.getters.logged_user['https://360live.events/role'] === '360'
            },
            is_host: function () {
                return store.getters.logged_user['https://360live.events/role'] === 'host'
            }
        },
        methods: {
            checkIfStarted: function () {
                this.loading_status = true
                store.dispatch('events/get', this.id)
            },
            toggleQA: function () {
                this.qa_status = !this.qa_status
            },
            togglePoll: function () {
                this.poll_status = !this.poll_status
            }
        },
        watch: {
            /*event_status: function(n){
                localStorage.setItem(`_chat_${this.id}_status`, n)

            },
            poll_status: function(n){
                localStorage.setItem(`_chat_${this.id}_poll_status`, n)
            },
            qa_status: function(n){
                localStorage.setItem(`_chat_${this.id}_qa_status`, n)
                if( this.is_role_360 ){
                    store.dispatch( 'events/update_qa_status', { id: this.id, status: n ? 1 : 0 })
                }
            },
            event: function(n){
                if( ! _.isUndefined( n ) ){
                    this.event_status = n.status === 1
                    this.qa_status = n.qa_status === 1
                }

            }*/
        }
    }
</script>

<style scoped lang="scss">
    .meta-box {
        background-color: $color-bg-box;
        border-radius: 5px;
        box-shadow: 0 2px 8px -1px rgba($color-black, 0.11);
        padding: 1rem 1.5rem;
        transition: all 200ms ease;

        .el-col {
            position: relative;
            padding-left: 2rem;
            color: $color-gray;
            font-size: 0.8125rem;

            & + .el-col {
                margin-top: 1rem;
            }
        }

        strong {
            color: $color-black;
            font-weight: 500;
            display: block;
            margin-bottom: 0.35rem;
            font-size: 0.9375rem;
        }
        i:not(.fa-play):not(.fa-stop):not(.fa-spinner-third) {
            position: absolute;
            left: 0;
            top: 2px;
        }

        .actions {
            position: relative;
            margin-top: 2rem;
            padding-top: 2rem;

            &::before {
                content: '';
                display: block;
                position: absolute;
                left: -2rem;
                right: -2rem;
                top: 0;
                border-top: 1px solid $color-border;

            }
        }

        @include breakpoint(tablet) {

            .el-col {
                &:nth-child(2) {
                    margin-top: 0;
                    text-align: right;
                }
                &:nth-child(3) {
                    margin-top: 0;
                    text-align: right;
                }
            }
        }
    }
</style>
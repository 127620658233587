import _ from 'lodash'
import Vue from 'vue'
import {ApolloClient} from "@/main"

import listInbox from '@/appsync/queries/inbox/list'
import updateInboxMessage from '@/appsync/mutations/inbox/update'
import webAuth from "@/auth"

const state = {
    list: [],
    next_token: null
}

// getters
const getters = {
    list: function(state){
        return _.orderBy( state.list, ['ts'], ['desc'] )
    },
    next_token: function(state){
        return state.next_token
    }
}

// actions
const actions = {
    patchLocalData: function({commit}, item){
        commit('PATCH', item)
    },
    list: async function ({dispatch, commit, getters}) {
        dispatch('loader', 'inbox/list', { root: true })
        try{
            let variables = {}
            if( getters.next_token !== null ){
                variables.nextToken = getters.next_token
            }
            let list = await ApolloClient.query({
                query: listInbox,
                variables: variables
            })
            commit('SET_LIST', list.data.listInbox.items )
            if( ! _.isNull( list.data.listInbox.nextToken ) ){
                commit('SET_NEXT_TOKEN', list.data.listInbox.nextToken )
            }
        } catch (e) {
            if( ! _.isUndefined( e.networkError ) &&  ! _.isUndefined( e.networkError.statusCode ) && e.networkError.statusCode === 401 ){
                webAuth.authorize()
            } else {
                commit('LOG', { type: 'error', message: e.message || e }, {root: true})
            }
        }
        dispatch('loader', 'inbox/list', { root: true })
    },
    markAsSeen: async function ({commit}, form) {
        try{
            let updated = await ApolloClient.mutate({
                mutation: updateInboxMessage,
                variables: {
                    input: form
                }
            })
            if( ! _.isNull( updated.data.updateInboxMessage ) ){
                commit( 'PATCH', updated.data.updateInboxMessage )
            }
        } catch (e) {
            if( ! _.isUndefined( e.networkError ) &&  ! _.isUndefined( e.networkError.statusCode ) && e.networkError.statusCode === 401 ){
                webAuth.authorize()
            } else {
                commit('LOG', { type: 'error', message: e.message || e }, {root: true})
            }
        }
    },
}

// mutations
const mutations = {
    SET_LIST(state, list) {
        _.each(list, function (i) {
            if( _.isUndefined( _.find( state.list, { id: i.id } ) ) ){
                state.list.push(i)
            } else {
                Vue.set( state.list, _.findIndex( state.list, { id: i.id } ), i )
            }
        })
    },
    PATCH: function(state, item){
        let index = _.findIndex( state.list, { id: item.id } )
        if( index < 0 ){
            state.list.push(item)
        } else {
            state.list[ index ].read = item.read || state.list[ index ].read
        }

       /* let index = _.findIndex( state.list, { id: item.id } )
        if( index >= 0 ){
            state.list[ index ].read = item.read || state.list[ index ].read
        }*/
    },
    SET_NEXT_TOKEN: function (state, token) {
        state.next_token = token
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

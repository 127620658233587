import _ from 'lodash'
import Vue from 'vue'
import axios from 'axios'
import Template from '@/schemas/Template'
import cleanDeep from 'clean-deep'

const state = {
    list: [],
    nextToken: null
}

// getters
const getters = {
    list: function(state){
        return state.list
    },
    nextToken: function(state){
        return state.nextToken
    }
}

// actions
const actions = {
    list: async function ({dispatch, commit, getters}) {
        dispatch('loader', 'templates/list', { root: true })
        try{
            let variables = {}
            if( getters.nextToken !== null ){
                variables.nextToken = getters.nextToken
            }
            let { data } = await axios.get('https://6e2cx81q7k.execute-api.eu-central-1.amazonaws.com/dev/list-templates');
            commit('SET_LIST', data.Items )
        } catch (e) {
            commit('LOG', { type: 'error', message: e.message || e }, {root: true})
        }
        dispatch('loader', 'templates/list', { root: true })
    },
    save: async function ({dispatch, commit}, form ) {
        dispatch('loader', 'templates/save', { root: true })
        try{
            form = new Template(form).toObject()
            form = cleanDeep(form,{
                emptyArrays: false,
                nullValues: true
            })
            let { data } = await axios.post('https://6e2cx81q7k.execute-api.eu-central-1.amazonaws.com/dev/save-template', form);
            commit('SET', data )
        } catch (e) {
            commit('LOG', { type: 'error', message: e.message || e }, {root: true})
        }
        dispatch('loader', 'templates/save', { root: true })
    },
    delete: async function ({dispatch, commit}, id ) {
        dispatch('loader', 'templates/delete', { root: true })
        try{
            let { data } = await axios.post('https://6e2cx81q7k.execute-api.eu-central-1.amazonaws.com/dev/remove-template', id);
            commit('REMOVE', data )
        } catch (e) {
            commit('LOG', { type: 'error', message: e.message || e }, {root: true})
        }
        dispatch('loader', 'templates/delete', { root: true })
    }
}

// mutations
const mutations = {
    SET_LIST(state, list) {
        list = _.map(list, function (v) {
            v = new Template(v).toObject()
            return v
        })
        _.each(list, function (i) {
            if( _.isUndefined( _.find( state.list, { id: i.id } ) ) ){
                state.list.push(i)
            } else {
                Vue.set( state.list, _.findIndex( state.list, { id: i.id } ), i )
            }
        })
    },
    SET_NEXT_TOKEN: function (state, token) {
        state.nextToken = token
    },
    PATCH: function(state, item){
        Vue.set( state.list, _.findIndex( state.list, { id: item.id } ), item )
    },
    REMOVE: function(state, item){
        state.list.splice( _.findIndex( state.list, { id: item } ), 1 )
    },
    SET: function(state, item){
        let existing = _.findIndex( state.list, { id: item.id })
        if( existing >= 0 ){
            Vue.set( state.list, existing, new Template(item).toObject() )
        } else {
            state.list.push( new Template(item).toObject() )
        }
    }

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
import _ from 'lodash'
import Vue from 'vue'
import {ApolloClient} from "@/main"

import getFaq from '@/appsync/queries/utils/getFaq'
//import cleanDeep from "clean-deep"
import webAuth from "@/auth"


const state = {
    list: []
}

// getters
const getters = {
    list: function(state){
        return state.list
    }
}

// actions
const actions = {
    get: async function ({dispatch, commit} ) {
        dispatch('loader', 'utils/faq/get', { root: true })
        try{
            let get = await ApolloClient.query({
                query: getFaq
            })
            if( ! _.isUndefined( get.data.getFaq ) && ! _.isNull( get.data.getFaq ) && ! _.isUndefined( get.data.getFaq.list ) ){
                commit( 'SET_LIST', get.data.getFaq.list )
            }
        } catch (e) {
            window.console.log(e)
            if( ! _.isUndefined( e.networkError ) &&  ! _.isUndefined( e.networkError.statusCode ) && e.networkError.statusCode === 401 ){
                webAuth.authorize()
            } else {
                commit('LOG', { type: 'error', title: 'Could not get schedule', message: e.message || e }, {root: true})
            }
        }
        dispatch('loader', 'utils/faq/get', { root: true })
    }
}

// mutations
const mutations = {
    SET_LIST (state, c){
        Vue.set( state, 'list', c )
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

import gql from 'graphql-tag'

export default gql `
    subscription onUpdatedBroadcast {
        onUpdatedBroadcast {
            id
            type
            from
            subject
            body
            delivery
            delivery_date
            state_machine
            status
            created
            updated
            sent
        }
    }
`
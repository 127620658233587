<template>
	<div>
		<h1>Live chat</h1>
		<div class="margin-top--2">
			<div class="meta-box">
				<el-row>
					<el-col
						:xs="24"
						:sm="12"
						:lg="6"
					>
						<strong><i class="fas fa-map-signs"></i>Location</strong>
						<template v-if="location">{{location.name}}</template>
					</el-col>
				</el-row>
			</div>
			<div
				class="chats margin-top--2"
				:class="{ 'chats--group' : location && location.chat_group, 'chats--selected': selected_chat, 'chats--empty': location && !location.chat_group && threads.length === 0 }"
			>
				<div
					class="chats__side"
					v-if="location && ! location.chat_group && threads.length > 0 "
				>
					<h1>Chats</h1>
					<perfect-scrollbar ref="scroll">
						<template v-for="(th, thi) in threads_list">
							<div
								:key="thi"
								class="chats__chat"
								v-on:click.prevent="selected_chat = th.id"
								:class="{ selected: selected_chat === th.id }"
							>
								<i
									class="connected "
									:class="{ 'color-green' : online.indexOf( th.author.id ) >= 0, 'color-danger' : online.indexOf( th.author.id ) < 0  }"
								></i>
								<avatar
									:username="th.author.name"
									:backgroundColor="getRandomColor(th.author.email || th.author.id)"
									:lighten="-40"
									:size="45"
								></avatar>
								<strong>{{th.author.name}}</strong>
								<div
									class="excerpt"
									v-if="th.last_message"
								>
									<div class="desc">{{th.last_message.body}}</div>
									<div class="time "><span class="margin-left--05 margin-right--05">&middot;</span>{{th.last_message.created | moment('HH:mm')}}</div>
								</div>
							</div>
						</template>
					</perfect-scrollbar>
				</div>

				<div class="chats__content">
					<template v-if="selected_chat">
						<div
							class="header"
							v-if="selected_thread"
						>
							<el-dropdown size="small">
								<avatar
									:username="selected_thread.author.name"
									:backgroundColor="getRandomColor(selected_thread.author.email || selected_thread.author.id)"
									:lighten="-40"
									:size="45"
								></avatar>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item>
										<el-button
											size="small"
											class="color-black"
											type="text"
											v-on:click.prevent="kickUser(selected_thread.author.id)"
										><i class="fad fa-user-times margin-right--1"></i>Kick user</el-button>
									</el-dropdown-item>
									<el-dropdown-item>
										<el-button
											size="small"
											class="color-black"
											type="text"
											v-on:click.prevent="banUser(selected_thread.author.id)"
										><i class="fad fa-user-slash margin-right--1"></i>Ban user
										</el-button>
									</el-dropdown-item>
									<el-dropdown-item>
										<el-button
											size="small"
											class="color-black"
											type="text"
											v-on:click.prevent="deleteMessage(message.id)"
										>
											<i class="fad fa-trash-a margin-right--1"></i>
											Delete message
										</el-button>
									</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
							<h2>
								<div
									v-on:click.prevent="goBack"
									class="name"
								><i class="far fa-fw fa-sm visible--tablet fa-chevron-left margin-right--1"></i>{{selected_thread.author.name}}</div>
							</h2>
							{{selected_thread.author.email}}<template v-if="selected_thread.author.phone"> · {{selected_thread.author.phone}}</template>
							<div class="meta">
								<template v-if="online.indexOf( selected_thread.author.id ) >= 0">
									<el-tag
										size="mini"
										type="success"
										round
									><i class="fas fa-circle"></i><span class="margin-left--5px">ONLINE</span></el-tag>
								</template>
								<template v-else>
									<el-tag
										size="mini"
										type="danger"
										round
									><i class="fas fa-circle"></i><span class="margin-left--5px">OFFLINE</span></el-tag>
								</template>
							</div>
						</div>
						<div class="content">
							<perfect-scrollbar ref="scroll">
								<div
									class="messages"
									v-loading="loading_chat"
								>
									<div
										v-for="(message, message_index) in chat_list"
										:key="message_index"
										class="message"
										:class="{ author__me: message.type === 1, author__visitor: message.type === 0, 'message--system-kick': message.type === -1, 'message--system-ban': message.type === -2  }"
									>

										<el-tooltip placement="left">
											<div
												class="tooltip-chat"
												slot="content"
											>
												<span>{{message.from.name}}</span>
												<a
													href="#"
													v-on:click.prevent="kickUser(location && location.chat_group ? message.from.id: selected_thread.author.id)"
												>
													<i class="fad fa-user-times margin-right--1"></i>Kick user</a>
												<a
													href="#"
													v-on:click.prevent="banUser(location && location.chat_group ? message.from.id: selected_thread.author.id)"
												><i class="fad fa-user-slash margin-right--1"></i>Ban user
												</a>
												<a
													href="#"
													v-on:click.prevent="deleteMessage(message.id)"
												><i class="fad fa-trash-alt margin-right--1"></i>Delete message
												</a>
											</div>
											<avatar
												v-if="message.type === 0"
												:username="location && location.chat_group ? message.from.name: selected_thread.author.name"
												:backgroundColor="getRandomColor(location && location.chat_group ? message.from.id : selected_thread.author.email || selected_thread.author.id)"
												:lighten="-40"
												:size="33"
											></avatar>
										</el-tooltip>
										<div class="body">
											<template v-if="message.body.indexOf('kick-user') >= 0 ">
												<em class="fad fa-user-times fa-2x fa-fw"></em>
												<div>Kicked user</div>
											</template>
											<template v-else-if="message.body.indexOf('ban-user') >= 0">
												<em class="fad fa-user-slash fa-2x fa-fw"></em>
												<div>Banned user</div>
											</template>
											<template v-else>
												<div
													class="text-size--xxs"
													:class="{['text-align--right']:message.type === 1}"
												>
													{{getTimeFromDate(message.created)}}
												</div>
												<span>
													{{message.body}}
												</span>
											</template>
										</div>
									</div>
								</div>
							</perfect-scrollbar>
						</div>
						<div class="footer">
							<el-form>
								<el-form-item>
									<textarea
										ref="message_input"
										type="textarea"
										class="message-input"
										v-model="message_input"
                    v-on:keyup.enter="() => sendMessage()"
										:rows="4"
									></textarea>
									<div class="live-chat-message-actions">
										<el-popover
											placement="top"
											trigger="click"
										>
											<VEmojiPicker @select="selectEmoji" />
											<el-button slot="reference">😊</el-button>
										</el-popover>
										<el-button
											type="primary"
											:disabled="is_send_message_disabled"
											class="send"
											round
											v-on:click.prevent="sendMessage()"
										>
											<i class="fas fa-paper-plane"></i>
										</el-button>
									</div>
								</el-form-item>

							</el-form>
						</div>
					</template>
					<template v-else>
						<div class="text-align--center">
							<img
								src="@/assets/empty.svg"
								class="empty-svg"
							>
							<p class="margin-top--2">There are no chat threads opened yet</p>
						</div>
					</template>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
import Avatar from "vue-avatar/src/Avatar";
import _ from 'lodash';
import store from '@/store';
//import moment from "moment";
import { ApolloClient } from "@/main"
import titleMixin from '@/mixins'
import listThreads from '@/appsync/queries/chat/listThreads'
import listChat from '@/appsync/queries/chat/listChat'
import onCreatedThread from '@/appsync/subscriptions/chat/onCreatedThread'
import createChatMessage from '@/appsync/mutations/chat/create'
import deleteChatMessage from '@/appsync/mutations/chat/delete'
import onCreatedPublicMessage from '@/appsync/subscriptions/chat/onCreatedPublicMessage'
import onPong from '@/appsync/subscriptions/chat/onPong'
import pingLocation from '@/appsync/mutations/chat/ping'
import { emojis } from './emojis'
var emoji = require('node-emoji')

export default {
	name: "LiveChat",
	props: ['id'],
	mixins: [titleMixin],
	data: function () {
		return {
			chat: [],
			message_input: '',
			threads: [],
			threads_next_token: null,
			selected_chat: null,
			chat_next_token: null,
			loading_chat: false,
			online: [],
			ping_freq: 1000 * 60,
			ping_started: false
		}
	},
	components: { Avatar },
	mounted: function () {
		let vm = this
		if (!_.isUndefined(this.location) && window.innerWidth > 834) {
			if (this.location.chat_group) {
				this.selected_chat = this.id
			} else {
				this.listThreads()
				vm.initPing()
			}
		}
		this.$apollo.subscriptions.onCreatedThread.refresh()
		this.$apollo.subscriptions.onCreatedPublicMessage.refresh()
		this.$apollo.subscriptions.onPong.refresh()
	},
	apollo: {
		$subscribe: {
			onCreatedThread: {
				query: onCreatedThread,
				variables: function () {
					return {
						location: this.id
					}
				},
				result ({ data }) {
					this.threads.push(data.onCreatedThread)
					this.setOnline(data.onCreatedThread.last_message.from.id)
				},
				skip () {
					return this.location && this.location.chat_group
				}
			},
			onCreatedPublicMessage: {
				query: onCreatedPublicMessage,
				variables: function () {
					return {
						location: this.id
					}
				},
				result (result) {
					const { data } = result
					if (data.onCreatedPublicMessage.thread === this.selected_chat) {
						this.chat.push(data.onCreatedPublicMessage)
						this.scrollChat()
					}
					if (!this.location.chat_group) {
						this.setOnline(data.onCreatedPublicMessage.from.id)
						let th = _.findIndex(this.threads, { id: data.onCreatedPublicMessage.thread })
						if (!_.isUndefined(th)) {
							this.threads[th].last_message = data.onCreatedPublicMessage
						}
					}

				},
				skip () {
					return _.isNull(this.selected_chat)
				}
			},
			onPong: {
				query: onPong,
				variables: function () {
					return {
						location: this.id
					}
				},
				result (result) {
					const { data } = result
					let vm = this
					if (data.onPong.body === 'pong') {
						vm.setOnline(data.onPong.id)
					}
				},
				skip () {
					return _.isUndefined(this.id)
				}
			}
		}
	},
	watch: {
		chat: function () {
			// let vm = this
			// this.$nextTick(function () {
			// 	if (!_.isUndefined(vm.$refs.scroll)) {
			// 		vm.$refs.scroll.$el.scrollTop = 1000000;
			// 		vm.$refs.message_input.$el.focus()
			// 	}
			// })
		},
		location: function (n, o) {
			if (_.isUndefined(o) && !_.isUndefined(n)) {
				if (n.chat_group) {
					if (this.chat.length === 0 && window.innerWidth > 834) {
						this.selected_chat = n.id
					}
				} else {
					if (this.threads.length === 0) {
						this.listThreads()
					}
					this.initPing()
				}

			}
		},
		selected_chat: function (n) {
			this.chat = []
			if (!_.isNull(n)) {
				this.getChat(n)
			}

		},
		threads: {
			handler: function (n) {
				if (!_.isNull(n) && n.length > 0 && _.isNull(this.selected_chat) && window.innerWidth > 834) {
					this.selected_chat = n[0].id
				}
			},
			deep: true
		}
	},
	computed: {
		threads_list () {
			return _.orderBy(this.threads, ['last_message.created'], ['desc'])
		},
		page_title () {
			return `Live chat`
		},
		location: function () {
			return _.find(store.getters.locations, { id: this.id })
		},
		me: function () {
			return _.find(store.getters.hosts, { objectID: this.logged_user.sub })
		},
		sender_name: function () {
			try {
				return `${this.me.user_metadata.first_name} ${this.me.user_metadata.last_name}`
			} catch (e) {
				return this.logged_user.sub
			}
		},
		chat_list: function () {
			return _.orderBy(this.chat, ['created'])
		},
		selected_thread: function () {
			return _.find(this.threads, { id: this.selected_chat })
		},
		is_send_message_disabled: function () {
			return this.message_input.split(' ').join('').length === 0
		}
	},
	methods: {
		scrollChat () {
			let vm = this
			this.$nextTick(function () {
				if (!_.isUndefined(vm.$refs.scroll)) {
					vm.$refs.scroll.$el.scrollTop = 1000000;
					vm.$refs.message_input.focus()
				}
			})
		},
		emojifyText (text) {
			const words = text.split(' ')
			words.forEach((word, index) => {
				if (emojis[word.toLowerCase()]) {
					const emojiIcon = emoji.get(emojis[word.toLowerCase()].name)
					words[index] = emojiIcon
				}
			})
			return words.join(' ')
		},
		getTimeFromDate (date) {
			return new Date(date).toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
		},
		selectEmoji (emoji) {
			this.message_input += emoji.data
		},
		initPing: function () {
			let vm = this
			if (!vm.ping_started) {
				vm.ping()
				setInterval(function () {
					vm.ping()
				}, vm.ping_freq)
				vm.ping_started = true
			}
		},
		setOnline: function (id) {
			let vm = this
			vm.online.push(id)
			setTimeout(function () {
				vm.online.splice(vm.online.indexOf(id), 1)
			}, vm.ping_freq);
		},
		goBack: function () {
			if (window.innerWidth <= 834) {
				this.selected_chat = null
			}
		},
		ping: async function () {
			let vm = this
			try {
				let variables = {
					location: '*',
					body: `ping::${vm.id}`
				}
				await ApolloClient.mutate({
					mutation: pingLocation,
					variables: variables
				})
			} catch (e) {
				window.console.log('error ping', e)
			}
		},
		getChat: async function (thread) {
			this.loading_chat = true
			try {
				let vm = this
				let variables = {
					thread
				}
				if (vm.chat_next_token !== null) {
					variables.nextToken = vm.chat_next_token
				}
				let list = await ApolloClient.query({
					query: listChat,
					variables: variables
				})
				vm.chat = _.concat(vm.chat, list.data.listChat.items)
				if (!_.isNull(list.data.listChat.nextToken)) {
					vm.chat_next_token = list.data.listChat.nextToken
				}
				this.loading_chat = false
			} catch (e) {
				this.loading_chat = false
			}
		},
		listThreads: async function () {
			try {
				let vm = this
				let variables = {
					location: vm.id
				}
				if (vm.threads_next_token !== null) {
					variables.nextToken = vm.threads_next_token
				}
				let list = await ApolloClient.query({
					query: listThreads,
					variables: variables
				})
				vm.threads = _.concat(vm.threads, list.data.listThreads.items)
				if (!_.isNull(list.data.listThreads.nextToken)) {
					vm.threads_next_token = list.data.listThreads.nextToken
				}
			} catch (e) {
				window.console.log('ss', e)
			}
		},
		sendMessage: async function (message, type) {
			const parsedMessage = this.emojifyText(this.message_input)
      const inputText = message || parsedMessage
      console.log('submit', inputText)
			try {
			  if( ! _.isNil( inputText ) && inputText.length > 0 ){
          let vm = this
          let input = {
            thread: vm.selected_chat,
            from: {
              id: vm.logged_user.sub,
              name: vm.sender_name
            },
            body: inputText,
            type: type || 1
          }
          let created = await ApolloClient.mutate({
            mutation: createChatMessage,
            variables: {
              input: input
            }
          })
          vm.chat.push(created.data.createChatMessage)
          this.message_input = ''
          this.scrollChat()
        }
			} catch (e) {
				this.message_input = ''
			}

		},
		kickUser: function (id) {
			this.sendMessage(`kick-user::${id}`, -1)
		},
		banUser: function (id) {
			this.sendMessage(`ban-user::${id}`, -2)
		},
		async deleteMessage (id) {
			try {
				let vm = this
				let deleted = await ApolloClient.mutate({
					mutation: deleteChatMessage,
					variables: {
						id
					}
				})
				const deletedId = deleted.data.deleteChatMessage.id
				let deletedIndex = _.findIndex(this.chat, { id: deletedId })
				vm.chat.splice(deletedIndex, 1)
			} catch (e) {
				console.log(e);
			}
		}
	},
	beforeRouteLeave: function (to, from, next) {
		next()
	}
}
</script>

<style scoped lang="scss">
.chats__content {
	.ps {
		height: 80vh;

		@include breakpoint(tablet) {
			height: 50vh;
		}
	}
}
.chats__side {
	.ps {
		height: 636px;
	}
}
.messages {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-top: 1rem;

	.message {
		width: 100%;

		& + .message {
			margin-top: 4px;
		}

		.body {
			display: inline-block;
			border-radius: 15px;
			padding: 6px 17px;
			line-height: 1.4;
		}

		&.author__me {
			text-align: right;

			.body {
				margin-left: auto;
				background-color: $color-primary;
				color: white;
				text-align: left;
			}
		}
		&.author__visitor {
			display: flex;
			position: relative;
			padding-left: 2.5rem;

			.body {
				margin-right: auto;
				background-color: $color-bg-light;
			}
			.vue-avatar--wrapper {
				position: absolute;
				left: 0;
			}
		}

		@include breakpoint(tablet) {
			.body {
				max-width: 50%;
			}
		}

		&.message--system {
			&-kick,
			&-ban {
				border-radius: 10px;
				margin-top: 0.5rem;
				padding: 1rem 0;

				.body {
					text-align: center;
					max-width: none;
					font-size: 0.75rem;
					text-transform: uppercase;
					display: block;
					line-height: 2;
				}
			}
			&-kick {
				background-color: rgba($color-primary, 0.1);
				color: $color-primary;
			}
			&-ban {
				background-color: rgba($color-red, 0.1);
				color: $color-red;
			}
		}
	}
}
.chats {
	background-color: $color-bg-box;
	border-radius: 5px;
	box-shadow: 0 2px 8px -1px rgba($color-black, 0.11);
	transition: all 200ms ease;

	&.chats--group {
		display: block;
	}

	&__side {
		padding: 1rem 1.5rem;
		border-right: 1px solid $color-border;
		display: block;

		h1 {
			margin: 0;
		}
	}
	&__chat {
		position: relative;
		padding: 1.5rem 1rem 1.5rem 4rem;
		cursor: pointer;
		overflow: hidden;
		.vue-avatar--wrapper {
			position: absolute;
			left: 0;
			top: 1.25rem;
		}
		& + .chats__chat {
			margin-top: 0.25rem;
		}
		.connected {
			width: 15px;
			height: 15px;
			border-radius: 100px;
			background-color: currentColor;
			position: absolute;
			z-index: 1;
			left: 2.15rem;
			top: 3.15rem;
			border: 2px solid white;
		}
		&::before {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			right: -0.75rem;
			left: -0.75rem;
			bottom: 0;
			background-color: $color-bg;
			border-radius: 5px;
			z-index: 0;
			opacity: 0;
			transition: all 200ms ease;
		}
		&:hover {
			&::before {
				opacity: 1;
				background-color: rgba($color-primary, 0.1);
			}
			.connected {
				border-color: $color-bg;
			}
		}
		&.selected {
			&::before {
				opacity: 1;
			}
			.connected {
				border-color: $color-bg;
			}
		}
		strong {
			color: $color-black;
			font-weight: 500;
			display: block;
			position: relative;
			text-overflow: ellipsis;
			white-space: nowrap;
			overflow: hidden;
		}
		.excerpt {
			font-size: 0.8125rem;
			display: flex;
			width: 100%;
			flex-wrap: nowrap;
			line-height: 1.5rem;
			position: relative;

			.desc {
				height: 1.5rem;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			.time {
				flex-shrink: 0;
			}
		}
	}
	&__content {
		flex-direction: column;
		min-height: 50vh;

		.header {
			padding: 1.25rem 1rem 1rem 5rem;
			border-bottom: 1px solid $color-border;
			position: relative;
			min-height: 4.8125rem;

			.el-dropdown {
				position: absolute;
				top: 1.2rem;
				left: 1rem;
			}
			h2 {
				font-size: 0.9375rem;
				color: $color-black;
				font-weight: 500;
				margin: 0 0 0.5rem;
			}
			.name {
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;
				margin-right: 2rem;
			}
		}
		.meta {
			position: absolute;
			right: 1rem;
			top: 1rem;

			.el-tag {
				span {
					display: none;
				}
			}
		}
		.content {
			flex-grow: 1;
			padding: 0 1.5rem;
		}
		.footer {
			padding: 1.5rem;
			position: relative;

			// .send {
			//     position: absolute;
			//     z-index: 1;
			//     bottom: 2.5rem;
			//     right: 2.5rem;
			//     width: 2.5rem;
			//     height: 2.5rem;
			//     padding: 0;
			//     line-height: 2.5rem;
			//     text-align: center;
			// }
			.live-chat-message-actions {
				position: absolute;
				z-index: 1;
				top: 10px;
				right: 10px;
				padding: 0;
				line-height: 2.5rem;
				text-align: center;

        > span{
          margin-right: 5px;

          button{
            border-radius: 1000px;
          }
        }
			}
      textarea{
        width: 100%;
        padding: 10px 5% 10px 10px;
      }
		}
	}

	&--selected {
		.chats {
			&__side {
				display: none;

				@include breakpoint(laptop) {
					display: block;
				}
			}
			&__content {
				display: flex;
			}
		}
	}

	@include breakpoint(tablet) {
		&__content {
			.header {
				padding: 1.5rem 1.5rem 1.5rem 5rem;

				h2 {
					margin-bottom: 0.25rem;
				}
			}
			.meta {
				position: absolute;
				right: 1.5rem;
				top: 1.5rem;

				.el-tag {
					span {
						display: inline-block;
					}
				}
			}
		}
	}
	@include breakpoint(laptop) {
		display: grid;
		grid-template-columns: 17.5rem 1fr;

		&__side {
			display: block;
		}
	}
	@include breakpoint(desktop) {
		grid-template-columns: 25rem 1fr;
	}

	&--empty {
		display: block;

		.chats__content {
			display: flex;
			justify-content: center;
			justify-items: center;
		}
	}
}
.meta-box {
	background-color: $color-bg-box;
	border-radius: 5px;
	box-shadow: 0 2px 8px -1px rgba($color-black, 0.11);
	padding: 1rem 1.5rem;
	transition: all 200ms ease;

	.el-col {
		position: relative;
		padding-left: 2rem;
		color: $color-gray;
		font-size: 0.8125rem;

		& + .el-col {
			margin-top: 1rem;

			@include breakpoint(tablet) {
				margin-top: 0;
			}
		}
	}

	strong {
		color: $color-black;
		font-weight: 500;
		display: block;
		margin-bottom: 0.35rem;
		font-size: 0.9375rem;
	}
	i:not(.fa-play):not(.fa-stop) {
		position: absolute;
		left: 0;
		top: 2px;
	}
}
</style>
import gql from 'graphql-tag'
export default gql `
    query getFaq{
        getFaq{
            id
            list{
                answer
                question
                
            }
         }
    }
`
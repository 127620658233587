<template>
    <div>
        <template v-if="on_page">
            <div class="margin-top--2">
                <h1 id="results">Poll results <em v-on:click.prevent="$router.push({ name: 'results', id: id })"
                                                  class="fad fa-fw float--right margin-top--15px cursor--pointer color-primary margin-left--1 fa-external-link-alt"></em>
                </h1>
                <div class="margin-top--2 content-box">
                    <div v-if="stats.length === 0" class="text-align--center">
                        <img src="@/assets/empty.svg" class="empty-svg">
                        <p class="margin-top--2">No results available yet.</p>
                    </div>
                    <div v-else>
                        <PollResults :chart-data="datacollection" :options="chart_options" :height="170"
                                     v-loading="loading"></PollResults>
                    </div>
                </div>
                <div class="results-stats margin-top--2" v-if="current_event" v-loading="loading">
                    <div class="box" v-for="(question, questioni) in questions_list" :key="question.id">
                        <h1><span>Q{{questioni+1}}.</span>{{question.question}}</h1>
                        <el-row class="margin-top--1">
                            <el-col :xs="24" :sm="19">
                                <div v-for="(answer) in question.answers" :key="answer.id" class="answer"
                                     :class="{ selected: winners.indexOf(answer.id) >= 0  }">
                                    <i v-if="winners.indexOf(answer.id) >= 0" class="fas fa-check"></i>
                                    {{getQuestionWinner(question)}}
                                    <strong>{{answer.answer}}</strong>
                                    <el-progress :text-inside="true" :stroke-width="26"
                                                 :percentage="getQuestionPercentage(question.id, answer.id)"
                                                 :status="winners.indexOf(answer.id) >= 0 ? 'success' : null"></el-progress>
                                </div>
                            </el-col>
                            <el-col :xs="0" :sm="5" align="right">
                                <div class="progress-cicle">
                                    <el-progress type="circle"
                                                 :percentage="totals_by_question[question.id] > 0 ? 25 : 0"
                                                 :strokeWidth="12" :show-text="false" color="#bbc0d7"></el-progress>
                                    <div class="nr">
                                        <template v-if="totals_by_question[question.id] > 0"><strong>{{totals_by_question[question.id]}}</strong>answers
                                        </template>
                                        <template v-else>no answers yet</template>
                                    </div>
                                </div>
                            </el-col>
                        </el-row>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <div v-if="selected_question" class="results-stats margin-top--2" v-loading="loading">
                <div class="box">
                    <h1><span>Q{{selected_question_index + 1}}.</span>{{selected_question.question}}</h1>
                    <el-row class="margin-top--1">
                        <el-col :xs="24" :sm="19">
                            <div v-for="(answer) in selected_question.answers" :key="answer.id" class="answer"
                                 :class="{ selected: winners.indexOf(answer.id) >= 0  }">
                                <i v-if="winners.indexOf(answer.id) >= 0" class="fas fa-check"></i>
                                {{getQuestionWinner(selected_question)}}
                                <strong>{{answer.answer}}</strong>
                                <el-progress :text-inside="true" :stroke-width="26"
                                             :percentage="getQuestionPercentage(selected_question.id, answer.id)"
                                             :status="winners.indexOf(answer.id) >= 0 ? 'success' : null"></el-progress>
                            </div>
                        </el-col>
                        <el-col :xs="0" :sm="5" align="right">
                            <div class="progress-cicle">
                                <el-progress type="circle"
                                             :percentage="totals_by_question[selected_question.id] > 0 ? 25 : 0"
                                             :strokeWidth="12" :show-text="false" color="#bbc0d7"></el-progress>
                                <div class="nr">
                                    <template v-if="totals_by_question[selected_question.id] > 0"><strong>{{totals_by_question[selected_question.id]}}</strong>answers
                                    </template>
                                    <template v-else>no answers yet</template>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </div>
                <div class="text-align--center margin-top--2">
                    <em v-on:click.prevent="showPrevQuestion()"
                        class="fas fa-lg cursor--pointer fa-fw fa-arrow-left margin-right--1"></em>
                    <em v-on:click.prevent="showNextQuestion()"
                        class="fas fa-lg cursor--pointer fa-fw fa-arrow-right"></em>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import _ from 'lodash'
    import {ApolloClient} from "@/main"
    import getPollResults from '@/appsync/queries/events/getPollResults'
    import PollResults from './PollResults'
    import store from '@/store'
    import onUpdatedPollQuestion from "@/appsync/subscriptions/events/onUpdatedPollQuestion"

    export default {
        name: "Results",
        props: ['id', 'questions', 'on_page'],
        components: {
            PollResults
        },
        data: function () {
            return {
                stats: [],
                loading: false,
                chart_options: {
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true
                            }
                        }]
                    },
                    responsive: true,
                    maintainAspectRatio: true
                },
                selected_question: null
            }
        },
        beforeRouteEnter: async function (to, from, next){
          if( ! _.isUndefined( to.params ) && !_.isNull(to.params) && ! _.isUndefined( to.params.id ) ){
            await store.dispatch('events/get', to.params.id)
          }
          next()
        },
        computed: {
            selected_question_index: function () {
                try {
                    return _.findIndex(this.questions_list, {id: this.selected_question.id})
                } catch (e) {
                    return -1
                }
            },
            current_event: function () {
                return _.find(store.getters.events, {id: this.id})
            },
            questions_list: function () {
                let vm = this
                let default_questions = _.sortBy(_.filter(store.getters.questions, {event: this.id}), function (q) {
                    return vm.current_event.poll_order.indexOf(q.id)
                }, ['desc'])
                return _.filter(this.questions || default_questions, {separator: false})
            },
            stats_by_question: function () {
                return _.groupBy(this.stats, 'question')
            },
            winners: function () {
                return _.map(_.orderBy(this.stats_by_question, ['total'], ['desc']), function (s) {
                    return s[0].a
                })
            },
            totals_by_question: function () {
                return _.mapValues(this.stats_by_question, function (q) {
                    let total = 0
                    _.each(q, function (a) {
                        total += a.total
                    })
                    return total
                })
            },
            questions_ids: function () {
                return _.map(this.questions_list, function (question) {
                    return question.id
                })
            },
            questions_colors: function () {
                let vm = this
                return _.map(this.questions_ids, function (id) {
                    return vm.hexToRgbA(vm.getRandomColor(id), 0.5)
                })
            },
            datacollection: function () {
                let vm = this
                let range = _.map(_.range(0, this.questions_list.length), function (r) {
                    return `Q${r + 1}`
                })
                let data = _.map(this.questions_list, function (question) {
                    return vm.totals_by_question[question.id]
                })
                return {
                    labels: range,
                    datasets: [
                        {
                            label: 'Answers',
                            backgroundColor: this.questions_colors,
                            data: data
                        }
                    ]
                }
            }
        },
        mounted: function () {
            if (!_.isUndefined(this.id)) {
                this.getResults()
            }
            if (!this.on_page && this.questions_list.length > 0) {
                this.selected_question = this.questions_list[0]
            }
        },
        apollo: {
            $subscribe: {
                onUpdatedPollQuestion: {
                    query: onUpdatedPollQuestion,
                    result (result) {
                        const { data } = result
                        if( data.onUpdatedPollQuestion.event === this.current_event.id && data.onUpdatedPollQuestion.status === 2 ){
                            this.refreshResults()
                        }
                    }
                }
            }
        },
        watch: {
            id: function (n) {
                if (!_.isUndefined(n)) {
                    this.getResults()
                }
            },
            questions_list: function (n) {
                if (n.length > 0 && _.isNull(this.selected_question)) {
                    this.selected_question = n[0]
                }
            }
        },
        methods: {
            refreshResults: function () {
                this.loading = true
                let vm = this
                setTimeout(function(){
                    vm.getResults()
                }, 1000)
            },
            showPrevQuestion: function () {
                if (!_.isUndefined(this.questions_list[this.selected_question_index - 1])) {
                    this.selected_question = this.questions_list[this.selected_question_index - 1]
                }
            },
            showNextQuestion: function () {
                if (!_.isUndefined(this.questions_list[this.selected_question_index + 1])) {
                    this.selected_question = this.questions_list[this.selected_question_index + 1]
                }
            },
            getQuestionPercentage: function (question, answer) {
                try {
                    return Math.round((100 * _.find(this.stats_by_question[question], {a: answer}).total) / this.totals_by_question[question])
                } catch (e) {
                    return 0
                }
            },
            getResults: async function () {
                this.loading = true
                try {
                    let stats = await ApolloClient.query({
                        query: getPollResults,
                        variables: {
                            id: this.id
                        }
                    })
                    this.stats = stats.data.getPollResults
                    this.loading = false
                } catch (e) {
                    this.loading = false
                    return
                }
            },
            getQuestionWinner: function (question) {
                try {
                    return _.orderBy(this.stats_by_question[question], ['total'], ['desc'])[0]
                } catch (e) {
                    return null
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .results-stats {
        .progress-cicle {
            text-align: center;
        }
        .box {
            background-color: $color-bg-box;
            border-radius: 5px;
            box-shadow: 0 2px 8px -1px rgba($color-black, 0.11);
            padding: 0.25rem 1rem 2rem;
            transition: all 200ms ease;

            h1 {
                margin: 0;
                font-size: 1rem;

                span {
                    display: inline-block;
                    width: 2rem;
                }
            }

            .answer {
                padding-left: 2rem;

                & + .answer {
                    margin-top: 1rem;
                }
                strong {
                    font-weight: 500;
                    color: $color-black;
                    display: inline-block;
                    margin-bottom: 0.5rem;
                }

                &.selected {
                    color: $color-green;
                    position: relative;

                    strong {
                        color: inherit;
                    }

                    .fa-check {
                        position: absolute;
                        left: 0;
                        transform: translateY(2px);
                    }
                }
            }

            & + .box {
                margin-top: 2rem;
            }
            @include breakpoint(tablet) {
                padding: 1rem 1.5rem 4rem;
            }
            @include breakpoint(laptop) {
                padding: 2rem 2.5rem 4rem;

                h1 {
                    font-size: 1.25rem;

                    span {
                        width: 3rem;
                    }
                }

                .answer {
                    padding-left: 3rem;

                    & + .answer {
                        margin-top: 2rem;
                    }
                }
            }
        }
    }
</style>
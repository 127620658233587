import gql from 'graphql-tag'

export default gql `
    mutation cancelBroadcast($id: String!, $arn: String!){
        cancelBroadcast(
            id: $id
            arn: $arn
        ){
            id
            status
        }
    }
`
import _ from 'lodash'
import Vue from 'vue'
import {ApolloClient} from "@/main"

import listPollQuestions from '@/appsync/queries/poll_questions/list'
import createPollQuestion from '@/appsync/mutations/poll_questions/create'
import updatePollQuestion from '@/appsync/mutations/poll_questions/update'
import deletePollQuestion from '@/appsync/mutations/poll_questions/delete'
import cleanDeep from "clean-deep"
import webAuth from "@/auth"
import PollQuestion from '@/schemas/PollQuestion'


const state = {
    list: [],
    nextToken: null
}

// getters
const getters = {
    list: function(state){
        return state.list
    },
    nextToken: function(state){
        return state.nextToken
    }
}

// actions
const actions = {
    list: async function ({dispatch, commit, getters}) {
        dispatch('loader', 'questions/list', { root: true })
        try{
            let variables = {}
            if( getters.nextToken !== null ){
                variables.nextToken = getters.nextToken
            }
            let list = await ApolloClient.query({
                query: listPollQuestions,
                variables: {}
            })
            commit('SET_LIST', list.data.listPollQuestions.items )

            if( ! _.isNull( list.data.listPollQuestions.nextToken ) ){
                commit('SET_NEXT_TOKEN', list.data.listPollQuestions.nextToken )
                dispatch( 'list' )
            }
        } catch (e) {
            if( ! _.isUndefined( e.networkError ) &&  ! _.isUndefined( e.networkError.statusCode ) && e.networkError.statusCode === 401 ){
                webAuth.authorize()
            } else {
                commit('LOG', { type: 'error', message: e.message || e }, {root: true})
            }
        }
        dispatch('loader', 'questions/list', { root: true })
    },
    create: async function ({dispatch, commit}, form) {
        dispatch('loader', 'questions/create', { root: true })
        try{
            form = new PollQuestion(form).toObject()
            form = cleanDeep(form,{
                emptyArrays: false,
                nullValues: true
            })
            if( ! _.isUndefined( form.created ) ){
                delete form.created
            }
            if( ! _.isUndefined( form.updated ) ){
                delete form.updated
            }
            if( ! _.isUndefined( form.results ) ){
                delete form.results
            }
            if( form.separator && ( _.isUndefined( form.question ) || form.question.length === 0 ) ){
                form.question = 'Untitled Separator'
            }
            let updated = await ApolloClient.mutate({
                mutation: createPollQuestion,
                variables: {
                    input: form
                }
            })
            if( ! _.isNull( updated.data.createPollQuestion ) ){
                commit( 'ADD', updated.data.createPollQuestion )
                commit( 'LOG', { type: 'success', message: 'Successfully created broadcast' }, {root: true})
            }
        } catch (e) {
            if( ! _.isUndefined( e.networkError ) &&  ! _.isUndefined( e.networkError.statusCode ) && e.networkError.statusCode === 401 ){
                webAuth.authorize()
            } else {
                commit('LOG', { type: 'error', message: e.message || e }, {root: true})
            }
        }
        dispatch('loader', 'questions/create', { root: true })
    },
    update: async function ({dispatch, commit}, form) {
        dispatch('loader', 'questions/update', { root: true })
        try{
            console.log('save question', form)
            form = new PollQuestion(form).toObject()
            form = cleanDeep(form,{
                emptyArrays: false,
                nullValues: false
            })
            if( ! _.isUndefined( form.results ) ){
                delete form.results
            }
            console.log('save question222222', form)
            let updated = await ApolloClient.mutate({
                mutation: updatePollQuestion,
                variables: {
                    input: form
                }
            })
            console.log('save question', updated)
            if( updated.data.updatePollQuestion.status === 0 ){
                dispatch('events/get', updated.data.updatePollQuestion.event ,{ root: true })
            }
            if( ! _.isNull( updated.data.updatePollQuestion ) ){
                commit( 'PATCH', updated.data.updatePollQuestion )
            }
        } catch (e) {
            if( ! _.isUndefined( e.networkError ) &&  ! _.isUndefined( e.networkError.statusCode ) && e.networkError.statusCode === 401 ){
                webAuth.authorize()
            } else {
                commit('LOG', { type: 'error', message: e.message || e }, {root: true})
            }
        }
        dispatch('loader', 'questions/update', { root: true })
    },
    updateStatus: async function ({dispatch, commit}, form) {
        dispatch('loader', 'questions/update', { root: true })
        try{
            console.log('save question', form)
            let updated = await ApolloClient.mutate({
                mutation: updatePollQuestion,
                variables: {
                    input: form
                }
            })
            console.log('save question', updated)
            if( updated.data.updatePollQuestion.status === 0 ){
                dispatch('events/get', updated.data.updatePollQuestion.event ,{ root: true })
            }
            if( ! _.isNull( updated.data.updatePollQuestion ) ){
                commit( 'PATCH', updated.data.updatePollQuestion )
            }
        } catch (e) {
            if( ! _.isUndefined( e.networkError ) &&  ! _.isUndefined( e.networkError.statusCode ) && e.networkError.statusCode === 401 ){
                webAuth.authorize()
            } else {
                commit('LOG', { type: 'error', message: e.message || e }, {root: true})
            }
        }
        dispatch('loader', 'questions/update', { root: true })
    },
    delete: async function ({dispatch, commit}, id) {
        dispatch('loader', 'questions/delete', { root: true })
        try{
            let removed = await ApolloClient.mutate({
                mutation: deletePollQuestion,
                variables: {
                    id: id
                }
            })
            commit('REMOVE', removed.data.deletePollQuestion)
            commit('LOG', { type: 'success', message: "Successfully removed" }, {root: true})
        } catch (e) {
            if( ! _.isUndefined( e.networkError ) &&  ! _.isUndefined( e.networkError.statusCode ) && e.networkError.statusCode === 401 ){
                webAuth.authorize()
            } else {
                commit('LOG', { type: 'error', message: e.message || e }, {root: true})
            }
        }
        dispatch('loader', 'questions/delete', { root: true })
    }
}

// mutations
const mutations = {
    SET_LIST(state, list) {
        list = _.map(list, function (v) {
            let item = new PollQuestion(cleanDeep(v)).toObject()
            return item
        })
        _.each(list, function (i) {
            if( _.isUndefined( _.find( state.list, { id: i.id } ) ) ){
                state.list.push(i)
            } else {
                Vue.set( state.list, _.findIndex( state.list, { id: i.id } ), i )
            }
        })
    },
    SET_NEXT_TOKEN: function (state, token) {
        state.nextToken = token
    },
    PATCH: function(state, item){
        Vue.set( state.list, _.findIndex( state.list, { id: item.id } ), item )
    },
    ADD: function(state, item){
        state.list.push( new PollQuestion(cleanDeep(item)).toObject() )
    },
    REMOVE: function(state, item){
        state.list.splice( _.findIndex( state.list, { id: item.id } ), 1 )
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

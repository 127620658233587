import gql from 'graphql-tag'
export default gql `
    query getUser($user_id: ID!){
        getUser(
            user_id: $user_id
        ){
            user_id
            email
            user_metadata{
                first_name
                last_name
                phone
                custom_1
                custom_2
            }
            app_metadata{
                role
                permissions
            }
            created_at
            updated_at
         }
    }
`
import gql from 'graphql-tag'
export default gql `
    query listQAMessages($limit: Int, $nextToken: String, $thread: String!, $filter: QAMessagesFilterInput){
        listQAMessages(
            limit: $limit,
            nextToken: $nextToken,
            thread: $thread,
            filter: $filter
        ){
            items{
                id
                body
                sender
                sender_name
                sender_id
                read
                created
                updated
                ts
                thread
                status
            },
            nextToken
         }
    }
`
import gql from 'graphql-tag'

export default gql `
    subscription onCreatedThread($location: String!) {
        onCreatedThread(location: $location) {
            id
            location
            ts
            created
            last_message{
                id
                body
                from{
                    name
                    id
                }
                read
                created
                ts
                thread
                type
                location
            }
            author{
                name
                id
                email
                phone
            }
        }
    }
`
import SchemaObject from "schema-object";

const Screen = new SchemaObject({
    left: {
        type: Number,
        default: 0
    },
    top: {
        type: Number,
        default: 0
    },
    width: {
        type: Number,
        default: 100
    },
    screen: {
        type: String,
        default: '375x812'
    }
})
const HeatMap = new SchemaObject({
    name: {
        type: String,
        default: ''
    },
    d: {
        type: String,
        default: ''
    }
})

export default new SchemaObject({
    id: String,
    name: {
        type: String,
        default: ''
    },
    scannable: {
        type: String,
        default: '*'
    },
    bg: {
        type: String,
        default: 'templates/default/lobby.jpg'
    },
    bg_width: {
        type: Number,
        default: 0
    },
    bg_height: {
        type: Number,
        default: 0
    },
    position: {
        type: [Screen],
        default: [
            {
                left: 0,
                top: 0,
                width: 100,
                screen: '320x568'
            },
            {
                left: 0,
                top: 0,
                width: 100,
                screen: '375x812'
            },
            {
                left: 0,
                top: 0,
                width: 100,
                screen: '568x350'
            },
            {
                left: 0,
                top: 0,
                width: 100,
                screen: '768x1024'
            },
            {
                left: 0,
                top: 0,
                width: 100,
                screen: '812x375'
            },
            {
                left: 0,
                top: 0,
                width: 100,
                screen: '834x1194'
            },
            {
                left: 0,
                top: 0,
                width: 100,
                screen: '1024x768'
            },
            {
                left: 0,
                top: 0,
                width: 100,
                screen: '1280x720'
            },
            {
                left: 0,
                top: 0,
                width: 100,
                screen: '1280x800'
            },
            {
                left: 0,
                top: 0,
                width: 100,
                screen: '1280x1024'
            }
        ]
    },
    heat_maps: {
        type: [HeatMap],
        default: []
    },
    has: {
        type: [String],
        default: []
    },
    video: {
        type: [Screen],
        default: [
            {
                left: 0,
                top: 0,
                width: 100,
                screen: '320x568'
            },
            {
                left: 0,
                top: 0,
                width: 100,
                screen: '375x812'
            },
            {
                left: 0,
                top: 0,
                width: 100,
                screen: '568x350'
            },
            {
                left: 0,
                top: 0,
                width: 100,
                screen: '768x1024'
            },
            {
                left: 0,
                top: 0,
                width: 100,
                screen: '812x375'
            },
            {
                left: 0,
                top: 0,
                width: 100,
                screen: '834x1194'
            },
            {
                left: 0,
                top: 0,
                width: 100,
                screen: '1024x768'
            },
            {
                left: 0,
                top: 0,
                width: 100,
                screen: '1280x720'
            },
            {
                left: 0,
                top: 0,
                width: 100,
                screen: '1280x800'
            },
            {
                left: 0,
                top: 0,
                width: 100,
                screen: '1280x1024'
            }
        ]
    },
    heat_map: {
        type: [Screen],
        default: [
            {
                left: 0,
                top: 0,
                width: 100,
                screen: '320x568'
            },
            {
                left: 0,
                top: 0,
                width: 100,
                screen: '375x812'
            },
            {
                left: 0,
                top: 0,
                width: 100,
                screen: '568x350'
            },
            {
                left: 0,
                top: 0,
                width: 100,
                screen: '768x1024'
            },
            {
                left: 0,
                top: 0,
                width: 100,
                screen: '812x375'
            },
            {
                left: 0,
                top: 0,
                width: 100,
                screen: '834x1194'
            },
            {
                left: 0,
                top: 0,
                width: 100,
                screen: '1024x768'
            },
            {
                left: 0,
                top: 0,
                width: 100,
                screen: '1280x720'
            },
            {
                left: 0,
                top: 0,
                width: 100,
                screen: '1280x800'
            },
            {
                left: 0,
                top: 0,
                width: 100,
                screen: '1280x1024'
            }
        ]
    }
})
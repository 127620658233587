<template>
	<div>
		<LocationsHeader title="Locations"></LocationsHeader>
		<el-table
			class="margin-top--2 content-box--shadow"
			:data="locations"
			v-loading="loading_global"
		>
			<el-table-column label="Name">
				<template slot-scope="scope">
					<router-link :to="{ name: 'Location', params: { id: scope.row.id } }">
						<span
							v-if="scope.row.is_home"
							class="fad fa-fw fa-home margin-right--025"
						></span>
						<span
							v-if="scope.row.parent && scope.row.slug"
							class="dashes"
						>
							<span
								v-for="i in countDashes(scope.row.slug)"
								:key="i"
								class="fad fa-fw fa-horizontal-rule margin-right--025"
							></span>
						</span>
						{{ scope.row.name }}
					</router-link>
				</template>
			</el-table-column>
			<el-table-column
				width="150"
				label="Parent"
				prop="parent"
			>
				<template slot-scope="scope">
					<router-link
						v-if="scope.row.parent"
						:to="{ name: 'Location', params: { id: scope.row.parent } }"
					>{{
                        scope.row.parent | location_name }}
					</router-link>
				</template>
			</el-table-column>
			<el-table-column
				label="Order"
				align="center"
				width="100"
				sortable
				prop="menu_order"
			>
				<template slot-scope="scope">
					{{ scope.row.menu_order !== 0 ? scope.row.menu_order : '-' }}
				</template>
			</el-table-column>
			<el-table-column
				label="Chat"
				align="center"
				width="100"
				prop="chat"
			>
				<template slot-scope="scope">
					<em
						class="fas fa-xs"
						:class="{ 'fa-check color-green': scope.row.chat, 'fa-minus opacity--50': !scope.row.chat }"
					></em>
				</template>
			</el-table-column>
			<el-table-column
				width="120"
				align="right"
			>
				<template slot-scope="scope">
					<a
						href="#"
						v-on:click.prevent="$router.push({ name: 'Location', params: { id: scope.row.id } })"
					>
						<em class="fad fa-fw fa-edit"></em>
					</a>
					<a
						href="#"
						class="margin-left--1"
						v-on:click.prevent="duplicateLocation(scope.row.id)"
					><em class="fad fa-fw fa-clone"></em></a>
					<a
						:class="{['cursor--not-allowed']:isParent(scope.row.id)}"
						:title="isParent(scope.row.id) ? 'Delete all child locations bfore deleting this one' : ''"
						href="#"
						class="margin-left--1 color-red"
						v-on:click.prevent="!isParent(scope.row.id) ? deleteLocation(scope.row.id) :   $message({
                        type: 'info',
                        message: 'Delete all child locations bfore deleting this one'
                    });"
					>
						<em
							class="far fa-fw fa-times"
							:class="{['color-gray']:isParent(scope.row.id)}"
						></em>
					</a>
				</template>
			</el-table-column>
		</el-table>
	</div>
</template>

<script>
import store from '@/store';
import _ from "lodash";
import Location from "@/schemas/Location"
import LocationsHeader from "@/components/locations/LocationsHeader"
import titleMixin from '@/mixins'

export default {
	name: "Locations",
	components: { LocationsHeader },
	mixins: [titleMixin],
	computed: {

		page_title () {
			return 'Locations'
		},
		locations: function () {
			let vm = this
			let locations = store.getters.locations
			locations = _.map(locations, function (l) {
				return {
					...l,
					abs_menu_order: vm.getAbsMenuOrder(l)
				}
			})
			return _.orderBy(locations, ['is_home', 'abs_menu_order'], ['desc', 'asc'])
		}
	},
	methods: {
		isParent (id) {
			let IsParent = false
			_.forEach(store.getters.locations, function (value, key) {
				if (value.parent && value.parent === id) {
					IsParent = true
					return
				}
			})
			return IsParent
		},
		countDashes: function (slug) {
			try {
				return slug.split('/').length - 1
			} catch (e) {
				return 0
			}
		},
		getAbsMenuOrder: function (l) {
			let order = ''
			let mo = l.menu_order || 0
			mo = mo <= 9 ? `00${mo}` : (mo <= 99 ? `0${mo}` : `${mo}`)
			if (!_.isUndefined(l.parent) && l.parent.length) {
				order = this.prefixLocation(`${mo}`, l)
			} else {
				order = `${mo}`
			}
			return order
		},
		prefixLocation: function (prefix, el) {
			if (el.parent) {
				let parent = _.find(store.getters.locations, { id: el.parent })
				prefix = !_.isUndefined(parent) ? `${this.prefixLocation(prefix, parent)}_${prefix}` : prefix
			} else {
				let mo = el.menu_order || 0
				mo = mo <= 9 ? `00${mo}` : (mo <= 99 ? `0${mo}` : `${mo}`)
				prefix = mo
			}
			return prefix
		},
		duplicateLocation: function (id) {
			let orginal = _.find(this.locations, { id: id })
			if (!_.isUndefined(orginal)) {
				let replica = new Location(orginal).toObject()
				replica.name = `${replica.name} (copy)`
				replica.slug = `${replica.slug}-copy`
				store.dispatch('locations/create', replica)
			}
		},
		deleteLocation: function (id) {
			let location = _.find(this.locations, { id: id })
			this.$confirm(`This will permanently delete the "${location.name}" location. Continue?`, 'Warning', {
				confirmButtonText: 'OK',
				cancelButtonText: 'Cancel',
				type: 'warning'
			}).then(() => {
				store.dispatch('locations/delete', id)
			}).catch(() => {
				this.$message({
					type: 'info',
					message: 'Delete canceled'
				});
			});
		}
	}
}
</script>

<style lang="scss">
.dashes {
	.fad {
		//opacity: 0;
	}
	.fad:last-child {
		opacity: 1;
	}
	.fa-horizontal-rule + .fa-horizontal-rule {
		margin-left: 0.25rem;
	}
}
</style>
import _ from 'lodash'
import Vue from 'vue'
import {ApolloClient} from "@/main"
import listEvents from '@/appsync/queries/events/list'
import getEvent from '@/appsync/queries/events/get'
import update from '@/appsync/mutations/events/update'
import cleanDeep from "clean-deep"
import webAuth from "@/auth"
import Event from '@/schemas/Event'
import createEvent from '@/appsync/mutations/events/createEvent'
import deleteEvent from '@/appsync/mutations/events/deleteEvent'

const state = {
    list: [],
    nextToken: null
}

// getters
const getters = {
    list: function(state){
        return state.list
    },
    nextToken: function(state){
        return state.nextToken
    }
}

// actions
const actions = {
    list: async function ({dispatch, commit, getters}) {
        dispatch('loader', 'events/list', { root: true })
        try{
            let variables = {}
            if( getters.nextToken !== null ){
                variables.nextToken = getters.nextToken
            }
            let list = await ApolloClient.query({
                query: listEvents,
                variables: variables
            })
            commit('SET_LIST', list.data.listEvents.items )

            if( ! _.isNull( list.data.listEvents.nextToken ) ){
                commit('SET_NEXT_TOKEN', list.data.listEvents.nextToken )
                dispatch( 'list' )
            }
        } catch (e) {
            if( ! _.isUndefined( e.networkError ) &&  ! _.isUndefined( e.networkError.statusCode ) && e.networkError.statusCode === 401 ){
                webAuth.authorize()
            } else {
                commit('LOG', { type: 'error', message: e.message || e }, {root: true})
            }
        }
        dispatch('loader', 'events/list', { root: true })
    },
    patch_poll_order: async function ({dispatch, commit}, form) {
        dispatch('loader', 'events/patch_poll_order', { root: true })
        try{
            form = new Event(form).toObject()
            form = _.pickBy(form, function(value, key) {
                return ['id', 'poll_order'].indexOf(key) >= 0;
            });
            form = cleanDeep(form,{
                emptyArrays: false,
                nullValues: true
            })
            let updated = await ApolloClient.mutate({
                mutation: update,
                variables: {
                    input: form
                }
            })
            if( ! _.isNull( updated.data.updateEvent ) ){
                commit( 'PATCH', updated.data.updateEvent )
                commit( 'LOG', { type: 'success', message: 'Successfully updated' }, {root: true})
            }
        } catch (e) {
            if( ! _.isUndefined( e.networkError ) &&  ! _.isUndefined( e.networkError.statusCode ) && e.networkError.statusCode === 401 ){
                webAuth.authorize()
            } else {
                commit('LOG', { type: 'error', message: e.message || e }, {root: true})
            }
        }
        dispatch('loader', 'events/patch_poll_order', { root: true })
    },
    patch: async function ({commit}, input) {
        try{
            input = new Event(input).toObject()
            input = cleanDeep(input,{
                emptyArrays: false,
                nullValues: true
            })
            input = _.pickBy(input, function(value, key) {
                return ['id', 'poll_questions', 'status', 'qa_status', 'poll_status'].indexOf(key) >= 0;
            });
            input.poll_questions = _.map( input.poll_questions, function(g){
                if( ! _.isUndefined( g.results ) ){
                    delete g.results
                }
                return g;
            })
            let updated = await ApolloClient.mutate({
                mutation: update,
                variables: {
                    input: input
                }
            })
            if( ! _.isNull( updated.data.updateEvent ) ){
                commit( 'PATCH', updated.data.updateEvent )
            }
        } catch (e) {
            if( ! _.isUndefined( e.networkError ) &&  ! _.isUndefined( e.networkError.statusCode ) && e.networkError.statusCode === 401 ){
                webAuth.authorize()
            } else {
                commit('LOG', { type: 'error', message: e.message || e }, {root: true})
            }
        }
    },
    patch_status: async function ({commit}, input) {
        try{
            let updated = await ApolloClient.mutate({
                mutation: update,
                variables: {
                    input: input
                }
            })
            if( ! _.isNull( updated.data.updateEvent ) ){
                commit( 'PATCH', updated.data.updateEvent )
            }
        } catch (e) {
            if( ! _.isUndefined( e.networkError ) &&  ! _.isUndefined( e.networkError.statusCode ) && e.networkError.statusCode === 401 ){
                webAuth.authorize()
            } else {
                commit('LOG', { type: 'error', message: e.message || e }, {root: true})
            }
        }
    },
    patch_local: function({commit}, item){
        commit('PATCH', item)
    },
    get: async function ({commit}, id) {
        try{
            let get = await ApolloClient.query({
                query: getEvent,
                variables: {
                    id
                }
            })
            commit('PATCH', get.data.getEvent )
        } catch (e) {
            if( ! _.isUndefined( e.networkError ) &&  ! _.isUndefined( e.networkError.statusCode ) && e.networkError.statusCode === 401 ){
                webAuth.authorize()
            } else {
                commit('LOG', { type: 'error', message: e.message || e }, {root: true})
            }
        }
    },
    create: async function ({dispatch, commit}, form) {
        dispatch('loader', 'events/create', { root: true })
        try{
            form.start = form.start instanceof Date ? form.start.toString() : form.start
            form = new Event(form).toObject()
            if( ! _.isUndefined( form.id ) ){
                delete form.id
            }
            if( ! _.isUndefined( form.created ) ){
                delete form.created
            }
            if( ! _.isUndefined( form.updated ) ){
                delete form.updated
            }
            form.excerpt = form.excerpt || null
            form.hosts_excerpt = form.hosts_excerpt || null
            form = cleanDeep(form,{
                emptyArrays: false,
                nullValues: false
            })
            form.scannable = '*'
            let updated = await ApolloClient.mutate({
                mutation: createEvent,
                variables: {
                    input: form
                }
            })
            if( ! _.isNull( updated.data.createEvent ) ){
                commit( 'ADD', updated.data.createEvent )
                commit( 'LOG', { type: 'success', message: 'Successfully updated' }, {root: true})
            }
        } catch (e) {
            if( ! _.isUndefined( e.networkError ) &&  ! _.isUndefined( e.networkError.statusCode ) && e.networkError.statusCode === 401 ){
                webAuth.authorize()
            } else {
                commit('LOG', { type: 'error', message: e.message || e }, {root: true})
            }
        }
        dispatch('loader', 'events/create', { root: true })
    },
    delete: async function ({dispatch, commit}, id) {
        dispatch('loader', 'events/delete', { root: true })
        try{
            let updated = await ApolloClient.mutate({
                mutation: deleteEvent,
                variables: {
                    id: id
                }
            })
            if( ! _.isNull( updated.data.deleteEvent ) ){
                commit( 'REMOVE', updated.data.deleteEvent )
            }
        } catch (e) {
            if( ! _.isUndefined( e.networkError ) &&  ! _.isUndefined( e.networkError.statusCode ) && e.networkError.statusCode === 401 ){
                webAuth.authorize()
            } else {
                commit('LOG', { type: 'error', message: e.message || e }, {root: true})
            }
        }
        dispatch('loader', 'events/delete', { root: true })
    },
    update_event: async function ({dispatch, commit}, form) {
        dispatch('loader', 'events/update', { root: true })
        try{
            form.start = form.start instanceof Date ? form.start.toString() : form.start
            form = new Event(form).toObject()
            if( ! _.isUndefined( form.created ) ){
                delete form.created
            }
            if( ! _.isUndefined( form.updated ) ){
                delete form.updated
            }
            form.excerpt = form.excerpt || null
            form.hosts_excerpt = form.hosts_excerpt || null
            form.live_video__de = form.live_video__de || null
            form.sync_video__de = form.sync_video__de || null
            form.live_video = form.live_video || null
            form.sync_video = form.sync_video || null
            form.live_video__fr = form.live_video__fr || null
            form.sync_video__fr = form.sync_video__fr || null
            form.live_video__es = form.live_video__es || null
            form.sync_video__es = form.sync_video__es || null
            form.live_video__it = form.live_video__it || null
            form.sync_video__it = form.sync_video__it || null
            form.live_video__ro = form.live_video__ro || null
            form.sync_video__ro = form.sync_video__ro || null
            form.zoom_meeting = form.zoom_meeting || null
            form.zoom_pass = form.zoom_pass || null
            form = cleanDeep(form,{
                emptyArrays: false,
                nullValues: false
            })
            form.scannable = '*'
            let updated = await ApolloClient.mutate({
                mutation: update,
                variables: {
                    input: form
                }
            })
            console.log('updated', updated)
            if( ! _.isNull( updated.data.updateEvent ) ){
                commit( 'PATCH', updated.data.updateEvent )
                commit( 'LOG', { type: 'success', message: 'Successfully updated' }, {root: true})
            }
        } catch (e) {
            if( ! _.isUndefined( e.networkError ) &&  ! _.isUndefined( e.networkError.statusCode ) && e.networkError.statusCode === 401 ){
                webAuth.authorize()
            } else {
                commit('LOG', { type: 'error', message: e.message || e }, {root: true})
            }
        }
        dispatch('loader', 'events/update', { root: true })
    },
}

// mutations
const mutations = {
    SET_LIST(state, list) {
        list = _.map(list, function (v) {
            let item = new Event(v).toObject()
                item.poll_questions = item.poll_questions || []
            return item
        })
        _.each(list, function (i) {
            let index = _.findIndex( state.list, { id: i.id } )
            if( index === -1 ){
                state.list.push(i)
            } else {
                Vue.set( state.list, index, i )
            }
        })
    },
    SET_NEXT_TOKEN: function (state, token) {
        state.nextToken = token
    },
    PATCH: function(state, item){
        Vue.set( state.list, _.findIndex( state.list, { id: item.id } ), new Event(cleanDeep(item)).toObject() )
    },
    ADD: function(state, item){
        state.list.push( new Event(cleanDeep(item)).toObject() )
    },
    REMOVE: function(state, item){
        state.list.splice( _.findIndex( state.list, { id: item.id } ), 1 )
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

import gql from 'graphql-tag'

export default gql `
    mutation updateUser($input: UpdateUserInput!){
        updateUser(
            input: $input
        ){
            user_id
            email
            user_metadata{
                first_name
                last_name
                phone
                custom_1
                custom_2
            }
            app_metadata{
                role
                permissions
            }
        }
    }
`
import _ from 'lodash'
import Vue from 'vue'
import {ApolloClient} from "@/main"

import listBroadcasts from '@/appsync/queries/broadcasts/list'
import createBroadcast from '@/appsync/mutations/broadcasts/create'
import updateBroadcast from '@/appsync/mutations/broadcasts/update'
import cancelBroadcast from '@/appsync/mutations/broadcasts/cancel'
import cleanDeep from "clean-deep"
import webAuth from "@/auth"
import Broadcast from '@/schemas/Broadcast'


const state = {
    list: [],
    nextToken: null
}

// getters
const getters = {
    list: function(state){
        return state.list
    },
    nextToken: function(state){
        return state.nextToken
    }
}

// actions
const actions = {
    patchLocalData: function({commit}, item){
        commit('PATCH', item)
    },
    list: async function ({dispatch, commit, getters}) {
        dispatch('loader', 'broadcasts/list', { root: true })
        try{
            let variables = {}
            if( getters.nextToken !== null ){
                variables.nextToken = getters.nextToken
            }
            let list = await ApolloClient.query({
                query: listBroadcasts,
                variables: variables
            })
            commit('SET_LIST', list.data.listBroadcasts.items )

            if( ! _.isNull( list.data.listBroadcasts.nextToken ) ){
                commit('SET_NEXT_TOKEN', list.data.listBroadcasts.nextToken )
                dispatch( 'list' )
            }
        } catch (e) {
            if( ! _.isUndefined( e.networkError ) &&  ! _.isUndefined( e.networkError.statusCode ) && e.networkError.statusCode === 401 ){
                webAuth.authorize()
            } else {
                commit('LOG', { type: 'error', message: e.message || e }, {root: true})
            }
        }
        dispatch('loader', 'broadcasts/list', { root: true })
    },
    create: async function ({dispatch, commit}, form) {
        dispatch('loader', 'broadcasts/create', { root: true })
        try{
            form = new Broadcast(form).toObject()
            form = cleanDeep(form,{
                emptyArrays: false,
                nullValues: true
            })
            if( ! _.isUndefined( form.created ) ){
                delete form.created
            }
            if( ! _.isUndefined( form.updated ) ){
                delete form.updated
            }
            if( ! _.isUndefined( form.sent ) ){
                delete form.sent
            }
            if( form.type === 2 ){
                form.status = 3
            }
            let updated = await ApolloClient.mutate({
                mutation: createBroadcast,
                variables: {
                    input: form
                }
            })
            if( ! _.isNull( updated.data.createBroadcast ) ){
                commit( 'ADD', updated.data.createBroadcast )
                commit( 'LOG', { type: 'success', message: 'Successfully created broadcast' }, {root: true})
            }
        } catch (e) {
            if( ! _.isUndefined( e.networkError ) &&  ! _.isUndefined( e.networkError.statusCode ) && e.networkError.statusCode === 401 ){
                webAuth.authorize()
            } else {
                commit('LOG', { type: 'error', message: e.message || e }, {root: true})
            }
        }
        dispatch('loader', 'broadcasts/create', { root: true })
    },
    update: async function ({dispatch, commit}, form) {
        dispatch('loader', 'broadcasts/update', { root: true })
        try{
            form = new Broadcast(form).toObject()
            form = cleanDeep(form,{
                emptyArrays: false,
                nullValues: false
            })
            let updated = await ApolloClient.mutate({
                mutation: updateBroadcast,
                variables: {
                    input: form
                }
            })
            if( ! _.isNull( updated.data.updateBroadcast ) ){
                commit( 'PATCH', updated.data.updateBroadcast )
                commit( 'LOG', { type: 'success', message: 'Successfully updated broadcast' }, {root: true})
            }
        } catch (e) {
            if( ! _.isUndefined( e.networkError ) &&  ! _.isUndefined( e.networkError.statusCode ) && e.networkError.statusCode === 401 ){
                webAuth.authorize()
            } else {
                commit('LOG', { type: 'error', message: e.message || e }, {root: true})
            }
        }
        dispatch('loader', 'broadcasts/update', { root: true })
    },
    cancel: async function ({dispatch, commit}, { id, arn }) {
        dispatch('loader', 'broadcasts/cancel', { root: true })
        try{
            let canceled = await ApolloClient.mutate({
                mutation: cancelBroadcast,
                variables: {
                    id,
                    arn
                }
            })
            if( ! _.isNull( canceled.data.cancelBroadcast ) ){
                commit( 'CANCEL' )
                commit( 'LOG', { type: 'success', message: 'Successfully canceled broadcast' }, {root: true})
            }
        } catch (e) {
            if( ! _.isUndefined( e.networkError ) &&  ! _.isUndefined( e.networkError.statusCode ) && e.networkError.statusCode === 401 ){
                webAuth.authorize()
            } else {
                commit('LOG', { type: 'error', message: e.message || e }, {root: true})
            }
        }
        dispatch('loader', 'broadcasts/cancel', { root: true })
    }
}

// mutations
const mutations = {
    SET_LIST(state, list) {
        list = _.map(list, function (v) {
            let item = new Broadcast(cleanDeep(v)).toObject()
            return item
        })
        _.each(list, function (i) {
            if( _.isUndefined( _.find( state.list, { id: i.id } ) ) ){
                state.list.push(i)
            } else {
                Vue.set( state.list, _.findIndex( state.list, { id: i.id } ), i )
            }
        })
    },
    ADD: function(state, item){
      state.list.push( new Broadcast(cleanDeep(item)).toObject() )
    },
    SET_NEXT_TOKEN: function (state, token) {
        state.nextToken = token
    },
    PATCH: function(state, item){
        let index = _.findIndex( state.list, { id: item.id } )
        item = new Broadcast(cleanDeep(item)).toObject()
        if( index < 0 ){
            state.list.push(item)
        } else {
            state.list[ index ].status = item.status || state.list[ index ].status
            state.list[ index ].sent = item.sent || state.list[ index ].sent
            state.list[ index ].state_machine = item.state_machine || state.list[ index ].state_machine
        }
    },
    CANCEL: function(state, id){
        let index = _.findIndex( state.list, { id: id } )
        if( index > 0 ){
            state.list[ index ].status = -1
        }
    },
    PATCH_LOCAL_DATA: function(state, list){
        _.each(list, function(item, index){
            state.list[ index ].status = item.status || state.list[ index ].status
            state.list[ index ].sent = item.sent || state.list[ index ].sent
            state.list[ index ].state_machine = item.state_machine || state.list[ index ].state_machine
        })
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

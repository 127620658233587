import gql from 'graphql-tag'
export default gql `
    query getExport{
        getExport{
            id
            email
            ttl
            download
            status
            job_id
            created
         }
    }
`
import gql from 'graphql-tag'

export default gql `
    mutation ping($thread: String, $location: String, $body: String){
        ping(
            thread: $thread,
            location: $location,
            body: $body
        ){
            location
            thread
            body
        }
    }
`
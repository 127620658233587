<template>
  <div>
    <LocationsHeader :title="id === 'new' ? 'Add location' : 'Edit location'"></LocationsHeader>
    <el-form
        class="content-box margin-top--2"
        label-position="top"
        v-loading="loading_global"
    >
      <el-row :gutter="32">
        <el-col :span="18">
          <el-form-item
              prop="name"
              label="Location name"
          >
            <el-input
                v-model="form.name"
                placeholder="Enter location name"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item
              prop="is_home"
              label="Lobby page"
          >
            <el-switch
                v-model="form.is_home"
                :disabled="is_home_set"
                active-text="Yes"
            ></el-switch>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row
          :gutter="32"
          class="margin-top--3"
      >
        <el-col :span="18">
          <el-form-item
              prop="parent"
              label="Parent"
          >
            <el-select
                filterable
                v-model="form.parent"
                placeholder="Please choose location"
            >
              <el-option
                  label="None"
                  value=""
              ></el-option>
              <el-option
                  v-for="item in locations"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item
              prop="menu_order"
              label="Menu order"
          >
            <el-input-number
                v-model="form.menu_order"
                :min="0"
                :max="200"
                placeholder="Enter menu order"
            ></el-input-number>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item
          v-if="!form.is_home"
          prop="slug"
          label="Slug"
          class="margin-top--3"
      >
        <el-input
            v-model="slug"
            placeholder="Enter slug "
        >
          <template slot="prepend"><em
              class="fad fa-lock"
              style="margin-right: 10px"
          ></em>
            <span v-text="parent_slug ? `${site_url}/${parent_slug}/` : `${site_url}/`"></span>
          </template>
          <template slot="append">
            <em
                class="fas"
                :class="{ 'fa-check color-green': slug_is_valid, 'fa-times color-danger': ! slug_is_valid }"
            ></em>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item
          prop="template"
          label="Template"
          class="margin-top--3"
      >
        <el-tooltip v-if="form.template">
          <template #content>
            <el-image
                v-if="activeTemplate && activeTemplate[0]"
                :style="{
								maxWidth:'300px'
							}"
                :src="getImgSrc({ w:600, h: 600 }, activeTemplate[0].bg )"
                fit="cover"
            ></el-image>
          </template>
          <el-select
              v-model="form.template"
              filterable
              placeholder="Please choose the template"
          >
            <el-option
                v-for="item in templates"
                :key="item.id"
                :label="item.name.substr(item.name.indexOf(' ') + 1)"
                :value="item.id"
            >
              <el-tooltip v-if="getImgSrc({ w:600, h: 600 }, item.bg )">
                <template #content>
                  <el-image
                      :style="{
											maxWidth:'300px'
										}"
                      :src="getImgSrc({ w:600, h: 600 }, item.bg )"
                      fit="cover"
                  ></el-image>
                </template>
                <div class="template_option_wrapper">
                  <span>{{ item.name.substr(item.name.indexOf(' ') + 1) }}</span>
                  <span style="color: #8492a6; font-size: 13px; text-transform: capitalize">
										{{ item.group }}
									</span>
                </div>
              </el-tooltip>
              <div
                  class="template_option_wrapper"
                  v-else
              >
                <span>{{ item.name.substr(item.name.indexOf(' ') + 1) }}</span>
                <span style="color: #8492a6; font-size: 13px; text-transform: capitalize">
									{{ item.group }}
								</span>
              </div>
            </el-option>
          </el-select>

        </el-tooltip>
      </el-form-item>
      <el-form-item
          prop="bg"
          label="Background"
          class="margin-top--3"
      >
        <image-upload
            v-model="form.bg"
            :prefix="`${prefix}/backgrounds/`"
        ></image-upload>
      </el-form-item>
      <!--<el-form-item
        prop="thumb"
        label="Thumbnail"
        class="margin-top&#45;&#45;3"
      >
        <image-upload
          v-model="form.thumb"
          :prefix="`${prefix}/thumbs/`"
        ></image-upload>
        <el-button
          v-if="form.thumb"
          type="text"
          v-on:click.prevent="form.thumb = ''"
        >Clear image</el-button>
      </el-form-item>-->
      <el-form-item prop="svg" label="Animated SVG" class="margin-top--3">
        <image-upload v-model="form.svg" :prefix="`${prefix}/svgs/`"/>
      </el-form-item>
      <el-form-item
          prop="video_wall"
          label="Video wall"
          class="margin-top--3"
      >
        <el-input
            v-model="form.video_wall"
            placeholder="https://vimeo.com/wd3254frfd/pomt9midj"
        ></el-input>
      </el-form-item>
      <el-form-item
          prop="vod"
          label="Video on demand"
          class="margin-top--3"
      >
        <el-input
            v-model="form.vod"
            placeholder="https://vimeo.com/wd3254frfd/pomt9midj"
        ></el-input>
      </el-form-item>
      <el-row :gutter="32" class="margin-top--3">
        <el-col :span="24">
          <el-form-item label="Permissions">
            <el-input placeholder="User permissions" v-model="form.permissions"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row
          :gutter="32"
          class="margin-top--3"
      >

        <el-col :span="6">
          <el-form-item
              prop="chat"
              label="Chat"
          >
            <el-switch
                v-model="form.chat"
                active-text="Yes"
            ></el-switch>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item
              v-if="form.chat"
              prop="chat_group"
              label="Public"
          >
            <el-switch
                v-model="form.chat_group"
                active-text="Yes"
            ></el-switch>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item
              v-if="form.chat"
              prop="hosts"
              label="Chat host"
          >
            <el-select
                size="mini"
                filterable
                v-model="host"
                placeholder="Please choose host"
            >
              <el-option
                  v-for="item in hosts"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
              >
                <span style="float: left">{{ item.name }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px"> {{ item.email }}</span>
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

      </el-row>
      <el-row
          :gutter="32"
          class="margin-top--3"
      >
        <el-col :span="6">
          <el-form-item
              prop="agenda"
              label="Agenda"
          >
            <el-switch
                v-model="form.agenda"
                active-text="Yes"
            ></el-switch>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item
              v-if="form.agenda"
              prop="agenda_date_format"
              label="Agenda date format"
          >
            <el-input
                v-model="form.agenda_date_format"
                placeholder="dddd[,] DD MMMM"
            ></el-input>
            Leave empty for default
          </el-form-item>
        </el-col>
      </el-row>
      <el-row
          :gutter="32"
          class="margin-top--3"
      >
        <el-col :span="24">
          <el-form-item
              prop="chat_group"
              label="Free login"
          >
            <el-switch
                v-model="form.not_protected"
                active-text="Yes"
                inactive-text="No"
            ></el-switch>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item prop="password" label="Password for location only" class="margin-top--3">
        <el-input v-model="form.password" placeholder="Leave empty for no password"></el-input>
      </el-form-item>
      <el-form-item v-if="form.chat" prop="phone" label="Phone number for SMS notifications" class="margin-top--3">
        <el-input v-model="form.phone" placeholder="Leave empty for no sms notifications"></el-input>
      </el-form-item>
      <el-form-item
          prop="buttons"
          label="Buttons"
          class="margin-top--3"
      >
        <template v-if="form.buttons && form.buttons.length > 0">
          <LocationButton
              v-for="(button, button_key) in form.buttons"
              v-on:delete="deleteButton(button_key, false)"
              v-on:move="move(button_key, false, $event)"
              :first="button_key === 0"
              :last="button_key === form.buttons.length -1"
              :key="button_key"
              v-model="form.buttons[button_key]"
              :for="form.id"
          ></LocationButton>
        </template>
        <template v-else>
          <el-alert
              type="info"
              :closable="false"
          >There are no buttons.
          </el-alert>
        </template>
        <el-button
            size="mini"
            type="text"
            class="no-padding margin-top--1"
            v-on:click.prevent="addNewButton()"
        >
          <em class="far fa-fw fa-plus fa-lg"></em> Add new button
        </el-button>
      </el-form-item>
      <el-form-item
          prop="buttons"
          label="Heat maps"
          class="margin-top--3"
          v-if="! form.agenda"
      >
        <template v-if="form.heat_maps && form.heat_maps.length > 0">
          <LocationButton
              v-for="(button, button_key) in form.heat_maps"
              v-on:delete="deleteButton(button_key, true)"
              v-on:move="move(button_key, true, $event)"
              :first="button_key === 0"
              :last="button_key === form.heat_maps.length"
              :is_heat="true"
              :key="button_key"
              :template="form.template"
              v-model="form.heat_maps[button_key]"
              :for="form.id"
          ></LocationButton>
        </template>
        <template v-else>
          <el-alert
              type="info"
              :closable="false"
          >There are no heat maps.
          </el-alert>
        </template>
        <el-button
            size="mini"
            type="text"
            class="no-padding margin-top--1"
            v-on:click.prevent="addNewHeatMap()"
        >
          <em class="far fa-fw fa-plus fa-lg"></em> Add new heat map
        </el-button>
      </el-form-item>
      <el-form-item
          class="margin-top--4"
          prop="info"
          label="Location info modal"
      >
        <el-input
            type="textarea"
            :rows="5"
            v-model="form.info"
        ></el-input>
      </el-form-item>
      <div class="margin-top--4 form-footer">
        <el-button
            type="primary"
            :disabled="!form_has_changed"
            round
            v-on:click.prevent="save()"
        ><i class="margin-right--05 fas fa-save"></i> Save location
        </el-button>
        <el-button
            type="text"
            class="color-gray"
            v-if="form_has_changed"
            v-on:click.prevent="resetForm()"
        ><i class="margin-right--05 fas fa-undo"></i> Reset changes
        </el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import Vue from "vue"
import Location from "@/schemas/Location";
import store from "@/store";
import _ from "lodash";
import LocationButton from "@/components/locations/LocationButton";
import ImageUpload from "@/components/ImageUpload";
import slugify from 'slugify';
import LocationsHeader from "@/components/locations/LocationsHeader";
import titleMixin from '@/mixins'

export default {
  name: "Location",
  props: ['id'],
  mixins: [titleMixin],
  components: {LocationsHeader, ImageUpload, LocationButton},
  computed: {
    page_title() {
      return this.is_new ? 'Locations | Add location' : 'Locations | Edit location'
    },
    is_new: function () {
      return _.isUndefined(this.id) || this.id === 'new'
    },
    form_has_changed: function () {
      return JSON.stringify(this.form) !== this.form_witness
    },
    prefix: function () {
      return `projects/${process.env.VUE_APP_SLUG}`
    },
    locations: function () {
      return _.orderBy(store.getters.locations, ['name'])
    },
    location: function () {
      return _.find(this.locations, {id: this.id})
    },
    slug_is_valid: function () {
      if (_.isUndefined(this.slug) || this.slug.length === 0) {
        return false
      }
      return _.isUndefined(_.find(this.locations, {slug: this.slug}))
    },
    slug: {
      get: function () {
        return this.has_parent ? (this.form.slug || '').replace(`${this.parent_slug}/`, '') : this.form.slug
      },
      set: function (n) {
        n = n.replace(/\//g, '')
        n = slugify(n, {remove: /[\][*+~.,`=\\;{}§±#^?()'"!:@]/g})
        this.form.slug = this.has_parent ? `${this.parent_slug}/${n}` : n
      }
    },
    has_parent: function () {
      return !_.isUndefined(this.form) && !_.isNull(this.form) && !_.isUndefined(this.form.parent) && this.form.parent.length > 3
    },
    parent_slug: function () {
      if (!this.has_parent) {
        return ''
      }
      try {
        let parent = _.find(this.locations, {id: this.form.parent})
        return parent.slug
      } catch (e) {
        return ''
      }

    },
    hosts: function () {
      return _.orderBy(store.getters.hosts_by_name, ['name'])
    },
    host: {
      get: function () {
        try {
          return this.form.hosts[0]
        } catch (e) {
          return null
        }
      },
      set: function (n) {
        Vue.set(this.form, 'hosts', this.form.hosts || [])
        Vue.set(this.form.hosts, 0, n)
      }
    },
    is_home_set: function () {
      try {
        let home = _.find(this.locations, {is_home: true})
        if (_.isUndefined(home)) {
          return false
        }
        if (home.id === this.location.id) {
          return false
        }
        return true
      } catch (e) {
        return true
      }
    },
    templates: function () {
      return _.orderBy(_.map(store.getters.templates, function (t) {
        return {
          ...t,
          group: t.name.substr(0, t.name.indexOf(' ')).toLowerCase()
        }
      }), ['group', 'name'])
    },
    activeTemplate() {
      return _.filter(this.templates, (template) => {
        return template.id === this.form.template
      })
    }
  },
  data: function () {
    return {
      witness: JSON.stringify(new Location().toObject()),
      form: new Location().toObject(),
      search: '',
      search_host: '',
      selected_image: null
    }
  },
  mounted: function () {
    this.setForm(new Location(this.location || this.form).toObject())
    let vm = this
    store.subscribe((mutation) => {
      if (mutation.type === 'locations/ADD') {
        if (vm.id === 'new') {
          vm.$router.push({name: 'Location', params: {id: mutation.payload.id}}).catch()
        }
      }
    })
  },
  watch: {
    location: function (n) {
      this.setForm(n || new Location().toObject())
    }
  },
  methods: {

    setForm: function (form) {
      this.form_witness = JSON.stringify(form)
      this.form = JSON.parse(JSON.stringify(form))
    },
    resetForm: function () {
      this.form = JSON.parse(this.form_witness)
    },
    move: function (key, heat, dir) {
      if (heat) {
        let f = this.form.heat_maps.splice(key, 1)[0];
        this.form.heat_maps.splice(dir === 'up' ? key - 1 : key + 1, 0, f);
      } else {
        let f = this.form.buttons.splice(key, 1)[0];
        this.form.buttons.splice(dir === 'up' ? key - 1 : key + 1, 0, f);
      }
    },
    deleteButton: function (key, heat) {
      if (heat) {
        this.form.heat_maps.splice(key, 1);
      } else {
        this.form.buttons.splice(key, 1);
      }
    },
    addNewHeatMap: function () {
      Vue.set(this.form, 'heat_maps', this.form.heat_maps || [])
      this.form.heat_maps.push({type: 'back'})
    },
    addNewButton: function () {
      Vue.set(this.form, 'buttons', this.form.buttons || [])
      this.form.buttons.push({type: 'back'})
    },
    save: function () {
      if (this.id === 'new') {
        store.dispatch('locations/create', this.form)
      } else {
        store.dispatch('locations/update', this.form)
      }
    }
  }
}
</script>

<style lang="scss">
.template_option_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
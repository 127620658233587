<template>
    <div>
        <h1 class="page-title">Live chat</h1>
        <div class="boxes boxes--two margin-top--2"  v-if="locations.length > 0" v-loading="loading_global">
            <div class="box" v-for="(location, evi) in locations" :key="evi">
                <el-row type="flex" align="middle"  class="margin-bottom--5 title">
                    <el-col :span="18">
                        <h1>{{location.name}}</h1>
                    </el-col>
                    <el-col :span="6" align="right" class="color-gray opacity--50 text-size--smaller">
                        {{ location.chat_group ? 'Group chat' : 'Private chat' }}
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :xs="16" :sm="16" :lg="12">
                        <div class="host-name">
                            <avatar v-if="getHost(location)" :username="getHost(location).name" :size="45"></avatar>
                            <div class="name" v-if="getHost(location)">{{ getHost(location).name }}</div>
                        </div>
                    </el-col>
                    <el-col  :xs="8" :sm="8" :lg="12" align="right">
                        <el-button type="primary" round v-on:click.prevent="$router.push({ name: 'chat-host', params: { id: location.id } })">Join</el-button>
                    </el-col>
                </el-row>
            </div>
        </div>
        <div v-else class="content-box text-align--center margin-top--2" v-loading="loading_global">
            <img src="@/assets/empty.svg" class="empty-svg">
            <p class="margin-top--2">No chat rooms available.</p>
        </div>
    </div>
</template>

<script>
    import Avatar from "vue-avatar/src/Avatar";
    import _ from 'lodash'
    import store from '@/store'
    import moment from "moment"
    import titleMixin from '@/mixins'

    export default {
        name: "LiveChatList",
        components: {Avatar},
        mixins: [titleMixin],
        computed: {
            page_title () {
                return `Live chat`
            },
            locations: function(){
                return _.filter( _.orderBy( store.getters.locations, ['name'] ), function(location){
                    let allow = location.chat
                    if( allow && ['360','host', 'client'].indexOf( store.getters.logged_user['https://360live.events/role'] ) === -1 ){
                        allow = false
                    }
                    if( allow && store.getters.logged_user['https://360live.events/role'] === 'host' ){
                        if( ! _.isUndefined( location.hosts ) && location.hosts.length > 0 ){
                          return location.hosts.indexOf( store.getters.logged_user.sub ) >= 0
                        }
                    }
                    return allow
                })
            },
            hosts: function(){
                return store.getters.hosts
            }
        },
        methods: {
            eventStarted: function(ev){
                return moment().unix() >= moment(ev.start).unix()
            },
            getHost: function(location){
                try{
                    let host = _.find( this.hosts, { objectID: location.hosts[0] } )
                    return _.isUndefined( host ) ? false : {
                        name: `${host.user_metadata.first_name} ${host.user_metadata.last_name}`,
                        email: host.email
                    }
                } catch (e) {
                    return false
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .boxes--two{
        .box{
            padding: 1rem;
            display: flex;
            flex-direction: column;

            .title{
                flex-grow: 1;
            }

            .host-name{
                position: relative;
                padding-left: 3.5rem;
                min-height: 2.8125rem;
                display: flex;
                align-items: center;
                align-content: center;
                width: 100%;
                flex-wrap: wrap;
                justify-items: center;

                .vue-avatar--wrapper{
                    position: absolute;
                    left: 0
                }

                .name{
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    margin-right: 10px;
                }
            }
        }
        h1{
            line-height: 1.35;
            font-size: 1rem;
        }
        @include breakpoint(tablet){
            .box{
                padding: 2rem;

                h1{
                    font-size: 1.25rem;
                }
            }
        }
        @include breakpoint(desktop){
            .box{
                padding: 3rem;
            }
        }

    }
</style>
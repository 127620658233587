import gql from 'graphql-tag'

export default gql `
    subscription onCreatedPublicMessage($location: String!) {
        onCreatedPublicMessage(
            location: $location
        ) {
            id
            body
            from{
                name
                id
            }
            read
            created
            ts
            thread
            type
            location
        }
    }
`
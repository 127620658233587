<template>
  <div>
    <div class="hidden--mobile">
      <el-row type="flex" align="middle">
        <el-col :xs="24" :lg="12">
          <h1  class="page-title"><span v-text="title"></span><i class="margin-left--1"></i></h1>
        </el-col>
        <el-col :xs="24" :lg="12" align="right">
          <el-button type="primary" round v-on:click="$router.push({ name: 'Event', params: { id: 'new' } })"><i class="far fa-plus margin-right--05"></i> Add new Event</el-button>
        </el-col>
      </el-row>
    </div>
    <div class="mobile-header">
      <h1  class="page-title"><span v-text="title"></span><i class="margin-left--1"></i></h1>
      <el-button type="text" class="color-primary" round v-on:click="$router.push({ name: 'Event', params: { id: 'new' } })"><i class="far fa-plus margin-right--05"></i> Add new event</el-button>
    </div>
  </div>
</template>

<script>
    export default {
        name: "LocationsHeader",
        props: [ 'title' ]
    }
</script>

<style scoped lang="scss">
.mobile-header{
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  h1{
    flex-basis: 100%;
  }

  .color-primary{
    padding-right: 0;
  }

  @include breakpoint(tablet){
    display: none;
  }
}
</style>
import gql from 'graphql-tag'
export default gql `
    query listPollQuestions($limit: Int, $nextToken: String){
        listPollQuestions(
            limit: $limit,
            nextToken: $nextToken
        ){
            items{
                id
                question
                multiple
                excerpt
                answers{
                    id
                    answer
                }
                results
                separator
                status
                event
            },
            nextToken
         }
    }
`
import gql from 'graphql-tag'

export default gql `
    mutation deleteEvent($id: ID!){
        deleteEvent(
            id: $id
        ){
            id
        }
    }
`
<template>
	<div
		id="app"
		:class="'page--' + $route.name"
	>
    <template v-if="$route.name === 'results'">
      <div class="container">
        <router-view class="router-main-view"></router-view>
      </div>

    </template>
    <template v-if="$route.name === 'users-print'">
      <div class="container">
        <router-view class="router-main-view"></router-view>
      </div>

    </template>
		<template v-else>
			<div class="container">
				<div class="cols">
					<div
						class="side"
						:class="{ 'mobile-navigation': show_mobile_nav }"
					>
						<el-row class="logo">
							<el-col
								:xs="20"
								:sm="20"
								:md="24"
							>
								<div
									class="logo cursor--pointer"
									v-on:click.prevent="$router.push('/')"
								>{{ event_name }}</div>
							</el-col>
							<el-col
								:xs="4"
								:sm="4"
								:md="0"
								align="right"
								class="color-black"
							>
								<i
									class="far fa-fw fa-lg cursor--pointer"
									:class="{ 'fa-bars': !show_mobile_nav, 'fa-times': show_mobile_nav }"
									v-on:click.prevent="show_mobile_nav = !show_mobile_nav"
								></i>
							</el-col>
						</el-row>
						<nav>
							<router-link
								:class="{ 'is-disabled': canUserGoTo('/users/') }"
								to="/users/"
							><i class="fad fa-lg fa-user-friends fa-fw"></i>Users
							</router-link>
							<router-link
								:class="{ 'is-disabled': canUserGoTo('/broadcasts/') }"
								to="/broadcasts/"
							><i class="fad fa-lg fa-broadcast-tower fa-fw"></i>Broadcasts
							</router-link>
							<router-link
								:class="{ 'is-disabled': canUserGoTo('/live/') }"
								to="/live/"
							><i class="fad fa-lg fa-camcorder fa-fw"></i>Dashboard
							</router-link>
							<router-link
								:class="{ 'is-disabled': canUserGoTo('/chat/') }"
								to="/chat/"
							><i class="fad fa-lg fa-comments fa-fw"></i>Live chat
							</router-link>
							<router-link
								:class="{ 'is-disabled': canUserGoTo('/moderation/') }"
								to="/moderation/"
							><i class="fad fa-lg fa-check-square fa-fw"></i>Q&A Moderation
							</router-link>
							<router-link
								:class="{ 'is-disabled': canUserGoTo('/polls/') }"
								to="/polls/"
							><i class="fad fa-lg fa-poll fa-fw"></i>Real-time polls
							</router-link>
							<template v-if="has_priviledges">
								<a
									href="#"
									v-on:click.prevent="show_advanced = !show_advanced"
									:class="{ 'color-primary': show_advanced }"
								><i class="fad fa-lg fa-cog fa-fw"></i>Advanced settings</a>
								<div
									class="sub-items"
									v-if="show_advanced"
								>
									<router-link
										:class="{ 'is-disabled': canUserGoTo('/project/') }"
										to="/project/"
									><i class="fal fa-lg fa-minus fa-fw"></i>Project
									</router-link>
									<router-link
										:class="{ 'is-disabled': canUserGoTo('/locations/') }"
										to="/locations/"
									><i class="fal fa-lg fa-minus fa-fw"></i>Locations
									</router-link>
									<router-link
										:class="{ 'is-disabled': canUserGoTo('/events/') }"
										to="/events/"
									><i class="fal fa-lg fa-minus fa-fw"></i>Events
									</router-link>
									<router-link
										:class="{ 'is-disabled': canUserGoTo('/templates/') }"
										to="/templates/"
									><i class="fal fa-lg fa-minus fa-fw"></i>Templates
									</router-link>
								</div>
							</template>
						</nav>
						<div class="logout">
							<a
								href="#"
								v-on:click.prevent="logout"
							><i class="fad fa-lg fa-sign-out fa-fw"></i>Logout</a>
						</div>
					</div>
					<div class="content">
						<router-view class="router-main-view"></router-view>
					</div>
				</div>
			</div>
			<div class="mobile-quick-nav">
				<router-link to="/users/"><i class="fad fa-lg fa-user-friends fa-fw"></i></router-link>
				<router-link to="/broadcasts/"><i class="fad fa-lg fa-broadcast-tower fa-fw"></i></router-link>
				<router-link to="/live/"><i class="fad fa-lg fa-camcorder fa-fw"></i></router-link>
				<router-link to="/chat/"><i class="fad fa-lg fa-comments fa-fw"></i></router-link>
				<router-link to="/moderation/"><i class="fad fa-lg fa-check-square fa-fw"></i></router-link>
				<router-link to="/polls/"><i class="fad fa-lg fa-poll fa-fw"></i></router-link>
			</div>
		</template>
	</div>
</template>

<script>
import onUpdatedEvent from '@/appsync/subscriptions/events/onUpdatedEvent'
import store from '@/store'

export default {
	name: 'app',
	data: function () {
		return {
			show_mobile_nav: false,
			show_advanced: false
		}
	},
	methods: {
		logout: function () {
			this.$auth.logout()
		},
		canUserGoTo: function (route) {
			if (this.user_role === 'moderator') {
				return route.indexOf('/moder') === -1
			}
      if (this.user_role === 'host') {
        return route.indexOf('/chat') === -1 && route.indexOf('/live') === -1
      }
      if (this.user_role === 'client') {
        return route.indexOf('/live') === 0 || route.indexOf('/users') === 0
      }
			return false
		}
	},
	mounted: function () {
		let vm = this
		if (this.$route.path.indexOf('/proj') >= 0 || this.$route.path.indexOf('/loca') >= 0 || this.$route.path.indexOf('/even') >= 0) {
			this.show_advanced = true
		}
		if (this.$auth.isAuthenticated() && !store.getters.setup && this.user_role !== 'visitor') {
			store.dispatch('setup')
		}
		store.subscribe((mutation) => {
			if (mutation.type === 'LOG') {
				vm.$message({
					message: mutation.payload.message,
					type: mutation.payload.type
				});
			}
		})
	},
	watch: {
		show_mobile_nav: function (n) {
			if (n) {
				document.body.classList.add("freeze-body");
			} else {
				document.body.classList.remove("freeze-body")
			}
		},
		'$route': function () {
			this.show_mobile_nav = false
		}
	},
	computed: {
		user_role: function () {
			return store.getters.logged_user_role
		},
		event_name: function () {
			return store.getters.settings.name || process.env.VUE_APP_EVENT_NAME || '360live.events'
		},
		has_priviledges: function () {
			return store.getters.logged_user_priviledges === 'owner'
		}
	},
	apollo: {
		$subscribe: {
      onUpdatedEvent: {
        query: onUpdatedEvent,
        result ({ data }) {
          store.dispatch('events/patch_local', data.onUpdatedEvent)
        },
        skip: function () {
          return ['360', 'host', 'moderator'].indexOf(this.user_role) === -1
        }
      }
		}
	}
}
</script>

<style lang="scss">
.cols .side nav .sub-items {
	padding: 0.75rem 0 0 0;

	a {
		color: rgba($color-gray, 0.75);
		margin-top: 1rem;

		i {
			color: inherit;
		}
	}
}
.mobile-quick-nav {
	display: flex;
	position: fixed;
	bottom: -1px;
	left: 0;
	right: 0;
	background-color: $color-primary;
	color: white;
	padding: 1.25rem 0;
	text-align: center;
	justify-content: center;

	@media (orientation: landscape) {
		display: none;
	}
	@media (orientation: portrait) and (min-width: 768px) {
		display: none;
	}

	a {
		color: rgba(255, 255, 255, 1);
		flex-grow: 1;
		flex-basis: 0;

		&.router-link-active {
			color: rgba(0, 0, 0, 0.55);

			i {
				color: inherit;
			}
		}

		@include breakpoint(phablet) {
			flex-grow: 0;
			padding: 0 1.5rem;
		}
	}
}

.cols {
	width: 100%;

	.side {
		z-index: 2;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		padding: 2rem 0 0;
    position: relative;

/*    &::before{
      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 2rem;
      left: -3rem;
      background-color: currentColor;
      opacity: 0.05;
      //border-radius: 10px;
      z-index: -1;
    }*/

		nav {
			display: none;
			margin-top: 3rem;

			a {
				display: block;
				color: $color-black;
				line-height: 1.25;

				i {
					color: $color-gray;
					margin-right: 1.25rem;
				}

				& + a {
					margin-top: 1.5rem;
				}

				&:hover {
					color: $color-gray;
				}

				&.router-link-active {
					font-weight: 600;
					color: $color-primary;

					i {
						color: inherit;
					}
				}

				&.is-disabled {
					opacity: 0.5;
					cursor: not-allowed;

					&:hover {
						color: $color-black;
					}
				}
			}
			@include breakpoint(phablet) {
				margin-top: 4rem;
			}
			@include breakpoint(tablet) {
				margin-top: 6rem;
				flex-grow: 1;

				a {
					line-height: 1.5;

					& + a {
						margin-top: 2rem;
					}
				}
			}
			a.color-primary {
				color: $color-primary;
				i {
					color: inherit;
				}
			}
		}
		.logout {
			display: none;
      margin-top: 1.5rem;
      padding-bottom: 1rem;
			width: 100%;

			a {
				display: block;
				color: $color-black;
				line-height: 1.5;

				i {
					color: $color-gray;
					margin-right: 1.25rem;
				}

				& + a {
					margin-top: 2rem;
				}

				&:hover {
					color: $color-gray;
				}

				&.router-link-active {
					font-weight: 600;
					color: $color-primary;

					i {
						color: inherit;
					}
				}
			}

			@include breakpoint(laptop) {
				align-self: flex-end;
				margin-bottom: 1rem;
				margin-top: 0;
			}
		}

		&.mobile-navigation {
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			min-height: 100vh;
			padding: 2rem 1.5rem;
			background-color: rgba($color-bg, 0.98);
			overflow-y: scroll;

			nav {
				display: block;
			}
			.logout {
				display: block;
			}
		}
	}

	.content {
		flex-grow: 1;
		flex-shrink: 2;
		width: 100%;
		max-width: 63rem;
		position: relative;
	}

	@include breakpoint(laptop) {
		display: flex;
		justify-content: center;

		.side {
			flex-basis: 15rem;
			flex-shrink: 1;
			position: sticky;
			align-self: flex-start;
			top: 0;
			min-height: 100vh;
			box-sizing: border-box;
			padding: 2rem 0;

			nav {
				display: block;
			}
			.logout {
				display: block;
				margin-bottom: 0;
			}
		}
		.content {
			padding: 2rem 0;
		}
		.logo {
			margin-top: 7px;
		}
	}
	@include breakpoint(desktop) {
		.side {
			flex-basis: 20rem;
		}
	}
}
</style>

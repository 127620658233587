import SchemaObject from "schema-object";
export default new SchemaObject({
    id: String,
    type: {
        type: Number,
        default: 2
    },
    from: {
        type: String,
        default: ''
    },
    subject: {
        type: String,
        default: ''
    },
    body: {
        type: String,
        default: ''
    },
    delivery: {
        type: Number,
        default: 0
    },
    delivery_date: {
        type: String,
        default: ''
    },
    state_machine: {
        type: String,
        default: ''
    },
    status: {
        type: Number,
        default: 0
    },
    sent: {
        type: Number,
        default: 0
    },
    created: {
        type: String,
        default: ''
    },
    updated: {
        type: String,
        default: ''
    },
    recipients: {
        type: Number,
        default: 0
    },
    filters: {
        type: String,
        default: ''
    }
})
import SchemaObject from "schema-object";
const PollQuestionAnswer = new SchemaObject({
    id: String,
    answer: {
        type: String,
        default: ''
    }
})
export default new SchemaObject({
    id: String,
    question: {
        type: String,
        default: ''
    },
    excerpt: {
        type: String,
        default: ''
    },
    multiple: {
        type: Boolean,
        default: false
    },
    answers: [PollQuestionAnswer],
    separator: {
        type: Boolean,
        default: false
    },
    status: {
        type: Number,
        default: 0
    },
    results: {
        type: Number,
        default: 0
    },
    event: {
        type: String,
        default: ''
    },
    order: {
        type: Number,
        default: 0
    }
})
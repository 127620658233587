<template>
    <div>
        <el-button
            type="text"
            class="margin-right--1 color-gray"
            v-on:click.prevent="dialog_export = true"
        ><i class="fad fa-lg fa-cloud-download margin-right--05"></i> Export users</el-button>
        <el-dialog
            title="Export users .csv file"
            :visible.sync="dialog_export"
            width="100%"
            class="dialog--small"
            :before-close="closeDialogExportHook"
        >
            <div v-loading="loading_export">
                <p
                    v-if="export_status === 0"
                    class="text-align--center"
                ><i class="fad color-primary fa-cloud-download fa-3x margin-bottom--2"></i><br>You will receive an email at <span class="color-primary">{{user_email.email}}</span> with the download link once the file is ready for download. This may take a bit.</p>
                <p
                    v-else-if="export_status > 0 && export_status < 3"
                    class="text-align--center"
                >
                    <span class="fa-stack fa-2x color-primary">
                        <i class="fas fa-cloud fa-stack-2x opacity--20"></i>
                        <i class="fad fa-cog fa-xs fa-stack-1x fa-spin"></i>
                    </span><br><br>
                    Building the export right now
                </p>
                <p
                    v-else-if="export_status === 3"
                    class="text-align--center"
                >
                    The export has been built and is ready for download. <br><br>
                    <el-button
                        round
                        type="primary"
                        plain
                        v-on:click.prevent="downloadFile"
                    ><i class="fad fa-lg fa-cloud-download margin-right--05"></i> Download file</el-button>
                </p>
            </div>
            <span
                slot="footer"
                class="dialog-footer"
            >
                <span
                    v-if="ttl > 0 && export_status > 2"
                    class="float--left margin-top--1"
                >
                    Self deleting in {{ttl}} <template v-if="ttl === 1">second</template><template v-else>seconds</template>
                </span>
                <el-button
                    v-if="export_status > 0"
                    type="danger"
                    round
                    @click="confirmDeleteExport"
                >
                    <i class="fas fa-cloud-download margin-right--05"></i>
                    <template v-if="export_status > 2">Delete export</template>
                    <template v-else>Cancel export</template>
                </el-button>
                <el-button
                    v-if="export_status === 0"
                    type="primary"
                    round
                    @click="createExport"
                ><i class="fas fa-cloud-download margin-right--05"></i> Build file</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import _ from "lodash";
import webAuth from "@/auth"
import { ApolloClient } from "@/main"
import createExport from '@/appsync/mutations/users/createExport'
import deleteExport from '@/appsync/mutations/users/deleteExport'
import getExport from '@/appsync/queries/utils/getExport'
import onUpdatedExport from '@/appsync/subscriptions/utils/onUpdatedExport'
import moment from 'moment'

export default {
    name: "ExportUsers",
    data: function () {
        return {
            dialog_export: false,
            loading_export: false,
            status: 0,
            export_ttl: 0,
            download: '',
            now: moment().unix()
        }
    },
    mounted: function () {
        this.getExport()
        this.$apollo.subscriptions.onUpdatedExport.refresh()
        let vm = this
        setInterval(function () {
            vm.now = moment().unix()
        })
    },
    computed: {
        user_email: function () {
            return this.$auth.user
        },
        ttl: function () {
            return this.now < this.export_ttl ? this.export_ttl - this.now : 0
        },
        download_url: function () {
            return this.ttl > 0 ? this.download : ''
        },
        export_status: function () {
            return this.ttl > 0 ? this.status : 0
        }
    },
    watch: {
        ttl: function (n, o) {
            if (o === 1 && n === 0) {
                this.deleteExport()
            }
        }
    },
    methods: {
        downloadFile: function () {
            window.open(this.download_url, "_blank");
        },
        confirmDeleteExport: function () {
            let vm = this
            this.$confirm('This will permanently delete the export file. Continue?', 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'danger'
            }).then(() => {
                vm.deleteExport()
            }).catch(() => {
                return;
            });
        },
        deleteExport: async function () {
            try {
                this.loading_export = true
                await ApolloClient.mutate({
                    mutation: deleteExport
                })
                this.status = 0
                this.loading_export = false
                this.export_ttl = 0
            } catch (e) {
                this.loading_export = false
                if (!_.isUndefined(e.networkError) && !_.isUndefined(e.networkError.statusCode) && e.networkError.statusCode === 401) {
                    webAuth.authorize()
                } else {
                    // commit('LOG', { type: 'error', title:'Could not get team members', message: e.message || e }, {root: true})
                }
            }
        },
        createExport: async function () {
            try {
                this.loading_export = true
                let exported = await ApolloClient.mutate({
                    mutation: createExport
                })
                this.status = exported.data.createExport.status
                this.export_ttl = exported.data.createExport.ttl
            } catch (e) {

                if (!_.isUndefined(e.networkError) && !_.isUndefined(e.networkError.statusCode) && e.networkError.statusCode === 401) {
                    webAuth.authorize()
                } else {
                    // commit('LOG', { type: 'error', title:'Could not get team members', message: e.message || e }, {root: true})
                }
            }
            this.loading_export = false
        },
        getExport: async function () {
            try {
                let exported = await ApolloClient.query({
                    query: getExport
                })
                if (!_.isNull(exported.data.getExport)) {
                    this.status = exported.data.getExport.status
                    this.download = exported.data.getExport.download
                    this.export_ttl = exported.data.getExport.ttl
                }
            } catch (e) {
                if (!_.isUndefined(e.networkError) && !_.isUndefined(e.networkError.statusCode) && e.networkError.statusCode === 401) {
                    webAuth.authorize()
                } else {
                    // commit('LOG', { type: 'error', title:'Could not get team members', message: e.message || e }, {root: true})
                }
            }
        },
        closeDialogExportHook: function (done) {
            done()
        },
    },
    apollo: {
        $subscribe: {
            onUpdatedExport: {
                query: onUpdatedExport,
                result ({ data }) {
                    try {
                        this.status = data.onUpdatedExport.status
                        this.download = data.onUpdatedExport.download || ''
                        this.export_ttl = data.onUpdatedExport.ttl || 0
                    } catch (e) {
                        window.console.log(e)
                    }
                },
                skip () {
                    return false
                }
            }
        }
    }
}
</script>

<style scoped>
</style>
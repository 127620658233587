import _ from 'lodash'
import Vue from 'vue'
import { ApolloClient } from "@/main"

import listLocations from '@/appsync/queries/locations/list'
import webAuth from "@/auth"
import Location from '@/schemas/Location'
import createLocation from '@/appsync/mutations/locations/createLocation'
import updateLocation from '@/appsync/mutations/locations/updateLocation'
import deleteLocation from '@/appsync/mutations/locations/deleteLocation'
import cleanDeep from 'clean-deep'


const state = {
	list: [],
	nextToken: null
}

// getters
const getters = {
	list: function (state) {
		return state.list
	},
	nextToken: function (state) {
		return state.nextToken
	}
}

// actions
const actions = {
	list: async function ({ dispatch, commit, getters }) {
		dispatch('loader', 'locations/list', { root: true })
		try {
			let variables = {}
			if (getters.nextToken !== null) {
				variables.nextToken = getters.nextToken
			}
			let list = await ApolloClient.query({
				query: listLocations,
				variables: variables
			})
			commit('SET_LIST', list.data.listLocations.items)

			if (!_.isNull(list.data.listLocations.nextToken)) {
				commit('SET_NEXT_TOKEN', list.data.listLocations.nextToken)
				dispatch('list')
			}
		} catch (e) {
			if (!_.isUndefined(e.networkError) && !_.isUndefined(e.networkError.statusCode) && e.networkError.statusCode === 401) {
				webAuth.authorize()
			} else {
				commit('LOG', { type: 'error', message: e.message || e }, { root: true })
			}
		}
		dispatch('loader', 'locations/list', { root: true })
	},
	create: async function ({ dispatch, commit }, form) {
		dispatch('loader', 'locations/create', { root: true })
		try {
			form = new Location(form).toObject()
			form = cleanDeep(form, {
				emptyArrays: false,
				nullValues: true
			})
			if (!_.isUndefined(form.id)) {
				delete form.id
			}
			if (!_.isUndefined(form.created)) {
				delete form.created
			}
			if (!_.isUndefined(form.updated)) {
				delete form.updated
			}
			let updated = await ApolloClient.mutate({
				mutation: createLocation,
				variables: {
					input: form
				}
			})
			if (!_.isNull(updated.data.createLocation)) {
				commit('ADD', updated.data.createLocation)
			}
		} catch (e) {
			if (!_.isUndefined(e.networkError) && !_.isUndefined(e.networkError.statusCode) && e.networkError.statusCode === 401) {
				webAuth.authorize()
			} else {
				commit('LOG', { type: 'error', message: e.message || e }, { root: true })
			}
		}
		dispatch('loader', 'locations/create', { root: true })
	},
	update: async function ({ dispatch, commit }, form) {
		dispatch('loader', 'locations/update', { root: true })
		try {
			form = new Location(form).toObject()
			form.bg = form.bg || null
			form.video_wall = form.video_wall || null
			form.vod = form.vod || null
			form.parent = form.parent || null
			form.info = form.info || null
			form.thumb = form.thumb || null
			form.buttons = form.buttons || null
			form.heat_maps = form.heat_maps || null
			form.agenda_date_format = form.agenda_date_format || null
			form.permissions = form.permissions || null
			form = cleanDeep(form, {
				emptyArrays: false,
				nullValues: false
			})
			if (!_.isUndefined(form.created)) {
				delete form.created
			}
			if (!_.isUndefined(form.updated)) {
				delete form.updated
			}
			let updated = await ApolloClient.mutate({
				mutation: updateLocation,
				variables: {
					input: form
				}
			})
			if (!_.isNull(updated.data.updateLocation)) {
				commit('PATCH', updated.data.updateLocation)
			}
		} catch (e) {
			if (!_.isUndefined(e.networkError) && !_.isUndefined(e.networkError.statusCode) && e.networkError.statusCode === 401) {
				webAuth.authorize()
			} else {
				commit('LOG', { type: 'error', message: e.message || e }, { root: true })
			}
		}
		dispatch('loader', 'locations/update', { root: true })
	},
	delete: async function ({ dispatch, commit }, id) {
		dispatch('loader', 'locations/delete', { root: true })
		try {
			let updated = await ApolloClient.mutate({
				mutation: deleteLocation,
				variables: {
					id: id
				}
			})
			if (!_.isNull(updated.data.deleteLocation)) {
				commit('REMOVE', updated.data.deleteLocation)
			}
		} catch (e) {
			if (!_.isUndefined(e.networkError) && !_.isUndefined(e.networkError.statusCode) && e.networkError.statusCode === 401) {
				webAuth.authorize()
			} else {
				commit('LOG', { type: 'error', message: e.message || e }, { root: true })
			}
		}
		dispatch('loader', 'locations/delete', { root: true })
	}
}

// mutations
const mutations = {
	SET_LIST (state, list) {
		list = _.map(list, function (v) {
			return new Location(v).toObject()
		})
		_.each(list, function (i) {
			if (_.isUndefined(_.find(state.list, { id: i.id }))) {
				state.list.push(i)
			} else {
				Vue.set(state.list, _.findIndex(state.list, { id: i.id }), i)
			}
		})
	},
	SET_NEXT_TOKEN: function (state, token) {
		state.nextToken = token
	},
	ADD: function (state, item) {
		state.list.push(new Location(cleanDeep(item)).toObject())
	},
	PATCH: function (state, item) {
		Vue.set(state.list, _.findIndex(state.list, { id: item.id }), new Location(cleanDeep(item)).toObject())
	},
	REMOVE: function (state, item) {
		state.list.splice(_.findIndex(state.list, { id: item.id }), 1)
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}

import SchemaObject from "schema-object";

export default new SchemaObject({
	id: String,
	allow_late_registration: {
		type: Boolean,
		default: false
	},
	allow_registration: {
		type: Boolean,
		default: false
	},
	excerpt: String,
	flyer: {
		type: String,
		default: ''
	},
	generic_code: {
		type: String,
		default: ''
	},
	name: {
		type: String,
		default: ''
	},
	start: {
		type: String,
		default: ''
	},
	stop: {
		type: String,
		default: ''
	},
	teaser_timeout: {
		type: Number,
		default: 0
	},
	login: {
		type: String,
		default: 'none'
	},
	url: {
		type: String,
		default: ''
	},
	css: {
		type: String,
		default: ''
	},
	logo: {
		type: String,
		default: ''
	},
	lanyard: {
		type: String,
		default: ''
	},
	favicon: {
		type: String,
		default: ''
	},
	bg_badge: {
		type: String,
		default: ''
	},
	modal_support: {
		type: String,
		default: ''
	},
	modal_info: {
		type: String,
		default: ''
	},
	ga: {
		type: String,
		default: ''
	},
	cb: {
		type: String,
		default: ''
	},
	lang: {
		type: String,
		default: 'ro'
	},
	utc: {
		type: String,
		default: ''
	},
	badge: {
		type: Boolean,
		default: true
	},
	gdpr: {
		type: Boolean,
		default: false
	},
	available_languages: {
		type: Array,
		default: ['ro']
	},
	color_primary: {
		type: String,
		default: ''
	},
	toolbox: {
		type: String,
		default: ''
	},
	navigation: {
		type: Boolean,
		default: false
	},
	meta_description: {
		type: String,
		default: ''
	},
	ecuson: {
		type: String,
		default: ''
	},
	segment: {
		type: Boolean,
		default: true
	}
})
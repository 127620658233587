<template>
  <div>
    <el-row
        :gutter="5"
        type="flex"
        align="middle"
    >
      <el-col :span="19">
        <el-tooltip
            :disabled="!image"
            placement="top"
        >
          <div slot="content">
            <el-image
                v-if="image && image.length > 5"
                :style="`max-width: ${width || 300}px`"
                :src="getImgSrc({ w: w || 600, h: h || 600 }, image )"
                fit="cover"
            ></el-image>
          </div>
          <el-input
              v-model="image"
              disabled
              placeholder="No image selected"
          ></el-input>
        </el-tooltip>
      </el-col>
      <el-col :span="5">
        <el-button
            type="outline"
            class="display--block"
            v-on:click="dialog = true"
        >
          Choose image
        </el-button>
      </el-col>
    </el-row>
    <el-button
        type="text"
        v-if="image"
        v-on:click.prevent="image = ''"
    >
      Remove image
    </el-button>
    <el-dialog
        custom-class="modal-image"
        :visible.sync="dialog"
        width="100%"
        top="5%"
        :before-close="handleClose"
    >
      <el-tabs
          v-model="tab"
          @tab-click="handleClick"
          :stretch="true"
      >
        <el-tab-pane
            label="From library"
            name="library"
            v-loading="loading_global"
        >
          <el-row :gutter="10">
            <el-col :span="12">
              <el-input
                  v-model="search"
                  placeholder="Search image by name"
                  size="small"
              ></el-input>
            </el-col>
            <el-col :span="12">
              <el-select
                  v-model="folder"
                  size="small"
                  filterable
                  allow-create
                  placeholder="/"
              >
                <el-option value="">.. /</el-option>
                <el-option
                    v-if="prefix"
                    :value="prefix"
                    :label="prefix"
                ></el-option>
                <el-option
                    v-for="(f, fi) in folders"
                    :key="`folder-${fi}`"
                    :label="f.key"
                    :value="f.key"
                ></el-option>
              </el-select>
            </el-col>
          </el-row>

          <div class="images-grid">
            <div
                v-for="img in images_list"
                :key="img.key"
                class="images-grid__item"
                :class="{ 'images-grid__item--selected': selected_image === img.key }"
            >
              <el-tooltip
                  class="item"
                  effect="dark"
                  :content="img.key"
                  placement="top"
              >
                <el-image
                    v-on:click.prevent="selected_image = img.key"
                    :src="getImgSrc({ w: 300, h: 300, fit: 'crop' }, img.key)"
                ></el-image>
              </el-tooltip>
            </div>
          </div>
          <div style="text-align: center; margin-top: 1rem">
            <el-pagination
                :page-size="page_size"
                :current-page.sync="page"
                layout="prev, pager, next"
                :total="images.length"
            >
            </el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane
            label="Upload new"
            name="upload"
            v-loading="loading_global"
        >
          <el-upload
              action=""
              drag
              :show-file-list="false"
              :http-request="uploadToS3"
              :before-upload="beforeAvatarUpload"
          >
            <div class="el-upload__text">
              <i class="fad fa-cloud-upload color-primary fa-3x margin-top--3"></i>
              <div class="el-upload__text">Drag you file here or <em>click </em> to upload</div>
            </div>
          </el-upload>
        </el-tab-pane>
      </el-tabs>
      <span
          slot="footer"
          class="dialog-footer"
      >
				<el-button
            @click="dialog = false"
            type="text"
        >Cancel</el-button>
				<el-button
            type="primary"
            round
            @click="chooseImage()"
        >Choose</el-button>
			</span>
    </el-dialog>
  </div>
</template>

<script>
import store from "@/store"
import {ApolloClient} from "@/main"
import gql from 'graphql-tag'
import axios from "axios"
import slugify from "slugify"
import _ from "lodash"

export default {
  name: "ImageUpload",
  props: ['value', 'placeholder', 'w', 'h', 'width', 'prefix'],
  computed: {
    image: {
      get: function () {
        return this.value
      },
      set: function (n) {
        this.$emit('input', n)
      }
    },
    images: function () {
      let vm = this
      return _.orderBy(_.filter(store.getters.images, function (img) {
        return img.key.toLowerCase().indexOf(vm.search.toLowerCase()) >= 0
      }), ['updated'], ['desc'])
    },
    images_list: function () {
      return JSON.parse(JSON.stringify(this.images)).splice((this.page - 1) * this.page_size, this.page_size)
    },
    folders: function () {
      return _.orderBy(store.getters.folders, ['key'], ['asc'])
    }
  },
  data: function () {
    return {
      dialog: false,
      tab: 'upload',
      selected_image: null,
      page: 1,
      page_size: 25,
      search: '',
      folder: ''
    }
  },
  mounted: function () {
    this.folder = this.prefix || ''
  },
  watch: {
    dialog: function (n) {
      if (n && this.images.length === 0) {
        store.dispatch('images/list', this.folder)
      }
      this.selected_image = n ? this.value : null
    },
    folder: function (n) {
      store.dispatch('images/list', n)
    },
    prefix: function (n) {
      this.folder = n
    }
  },
  methods: {
    chooseImage: function () {
      if (this.value !== this.selected_image && !_.isNull(this.selected_image)) {
        this.image = this.selected_image
      }
      this.dialog = false
    },
    handleClose: function (done) {
      done();
    },
    handleClick: function () {

    },
    normalizeFileName: function (name) {
      let fileExtension = name.split('.').pop();
      let fileName = name.replace(fileExtension, '')
      fileName = slugify(fileName, {remove: /[*+~.()'"!:@+=±§#$&`?/><,;\\}{[\]^%]/g}).toLowerCase()
      fileName = fileName + '.' + fileExtension.toLowerCase()
      return fileName
    },
    uploadToS3: async function (n) {
      store.dispatch('loader', 'getSignedUrl')
      let fileName = this.normalizeFileName(n.file.name)
      let vm = this
      try {
        let variables = {
          key: fileName,
          type: n.file.type
        }
        let has_prefix = vm.prefix && vm.prefix.length > 3
        if (has_prefix) {
          variables.prefix = vm.prefix
        }
        let signedUrl = await ApolloClient.query({
          query: gql`
              query getImageSignedUrl( $key: String!, $type: String!, $prefix: String ){
                  getImageSignedUrl( key: $key, type: $type, prefix: $prefix ){
                      file
                      path
                  }
               }
          `,
          variables: variables
        })
        let response = await axios.put(signedUrl.data.getImageSignedUrl.path, n.file, {
          headers: {
            'Content-Type': n.file.type
          }
        }).catch(function (e) {
          window.console.log('eer', e)
        })
        if (response.status === 200) {
          fileName = has_prefix ? `${vm.prefix}${fileName}` : fileName
          store.dispatch('images/add', fileName)
          vm.selected_image = fileName
          vm.tab = 'library'
        }
      } catch (e) {
        window.console.log('eroare upload', e)
        //store.dispatch( 'logEntry', { type: 'error', title: 'Something went wrong uploading your image' } )
      }
      store.dispatch('loader', 'getSignedUrl')
    },
    beforeAvatarUpload() {
      return true
    }
  }
}
</script>

<style lang="scss">
.modal-image {
  max-width: 800px;

  .el-dialog {
    &__body {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

.el-upload {
  display: block;

  .el-upload-dragger {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.el-upload__text {
}

.images-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 0.5rem;
  margin-top: 0.5rem;
  line-height: 0;

  &__item {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    padding: 10px;
    cursor: pointer;

    .el-image {
      border-radius: 3px;
    }

    &--selected {
      background-color: rgba(#cdd322, 0.65);
      position: relative;

      &::after {
        content: "\f00c";
        position: absolute;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
        width: 3rem;
        height: 2rem;
        font-family: "Font Awesome 5 Pro";
        font-size: 1.25rem;
        top: 0;
        right: 0;
        background-color: #cdd322;
        color: #431c5d;
        text-align: center;
        border-radius: 20px 3px 0 20px;
        padding-top: 2px;
        box-sizing: border-box;
      }
    }
  }
}
</style>
<template>
    <div>
        <h1 class="page-title">Q&A moderation</h1>
        <div class="margin-top--2" v-if="event">
            <h1 class="display">{{event.name}}</h1>
            <p v-if="event.excerpt">{{event.excerpt}}</p>
            <div class="meta-box margin-top--2">
                <el-row v-if="event">
                    <el-col :xs="24" :sm="8" :md="5" >
                        <strong><i class="far fa-calendar-alt"></i>Event start</strong>
                        {{ event.start | moment( 'DD MMM YYYY [@] HH:mm' ) }}
                    </el-col>
                    <el-col :xs="24" :sm="8" :md="5">
                        <strong><i class="fas fa-map-signs"></i>Location</strong>
                        {{ event.location | location_name }}
                    </el-col>
                    <el-col :xs="24" :sm="8" :md="6" :lg="5">
                        <strong><i class="fas fa-users-class"></i>Hosts</strong>
                        <el-tooltip placement="right">
                            <div slot="content">
                                <div v-for="host in event.hosts" :key="host">{{ host | host_name }}</div>
                            </div>
                            <div v-if="event.hosts && event.hosts.length === 1" class="host">
                                <span>{{ event.hosts[0] | host_name }}</span>
                            </div>
                            <div v-else-if="event.hosts && event.hosts.length > 1" class="host">
                                <span>{{ event.hosts[0] | host_name }}</span>  <em>+{{event.hosts.length - 1}}</em>
                            </div>
                            <div v-else class="opacity--50" >
                                None
                            </div>
                        </el-tooltip>
                    </el-col>
                    <el-col :xs="24" :sm="8" :md="4">
                        <strong>Status</strong>
                        <el-tag size="mini" round>scheduled</el-tag>
                    </el-col>
                    <el-col :xs="24" :sm="16" :md="4" :lg="5" align="right">
                        <el-button :type="status ? 'danger' : 'success'" round v-on:click.prevent="status = ! status"><i class="fas  fa-fw margin-right--05" :class="{ 'fa-play' : !status, 'fa-stop' : status }"></i> {{ !status ? 'Start' : 'Stop' }}</el-button>
                    </el-col>
                </el-row>
            </div>
            <qa :moderation_status="status" :moderator="true" :id="id" class="margin-top--2"></qa>
        </div>
    </div>
</template>

<script>
    import _ from 'lodash'
    import store from '@/store'
    import qa from "@/components/video/qa"
    import onUpdatedEvent from '@/appsync/subscriptions/events/onUpdatedEvent'
    import titleMixin from '@/mixins'

    export default {
        name: "Moderation",
        props: ['id'],
        mixins: [titleMixin],
        data: function(){
            return{
                status: false
            }
        },
        components: {
            qa
        },
        computed: {
            page_title () {
                return `Q&A Moderation`
            },
            event: function(){
                return _.find( store.getters.events, { id: this.id } )
            },
            qa_status: function(){
                try{
                    return this.event.qa_status
                } catch (e) {
                    return 0
                }
            }
        },
        watch: {
            qa_status: function (n, o) {
              if( n === 0 && o === 1 ){
                  this.status = false
              }
          }
        },
        mounted: function(){
            let vm = this
            store.subscribe((mutation) => {
                if( mutation.type === 'events/PATCH' && mutation.payload.id === vm.id ) {
                    vm.loading_status = false
                }
            })
        },
        apollo: {
            $subscribe: {
                onUpdatedEvent: {
                    query: onUpdatedEvent,
                    result({data}) {
                        store.dispatch( 'events/patch_local', data.onUpdatedEvent)
                    }
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .q-a{
        background-color: $color-bg-box;
        border-radius: 5px;
        box-shadow: 0 2px 8px -1px rgba($color-black, 0.11);
        padding: 1.5rem;
        transition: all 200ms ease;
        margin-top: 2rem;

        strong{
            color: $color-black;
            font-weight: 500;
        }
        p{
            margin: .25rem 0 0 0;
            line-height: 1.4;
        }


        .question{
            position: relative;
            padding-left: 6rem;

            .actions{
                display: none;
            }

            &:hover{
                .fa-ellipsis-v{
                    display: none;
                }
                .actions{
                    display: block;
                }
            }

            & + .question{
                border-top: 1px solid $color-border;
                margin-top: 2rem;
                padding-top: 2rem;
            }
        }
        .vue-avatar--wrapper{
            position: absolute;
            left: 1rem
        }
    }
    .meta-box{
        background-color: $color-bg-box;
        border-radius: 5px;
        box-shadow: 0 2px 8px -1px rgba($color-black, 0.11);
        padding: 1rem 1.5rem;
        transition: all 200ms ease;

        .el-col{
            position: relative;
            padding-left: 2rem;
            color: $color-gray;
            font-size: 0.8125rem;

            & + .el-col{
                margin-top: 1rem;

                @include breakpoint(tablet){
                    &:nth-child(2),
                    &:nth-child(3){
                        margin-top: 0;
                    }
                }

                @include breakpoint(laptop){
                    margin-top: 0;

                    &:nth-last-child(){
                        text-align: right;
                    }
                }
            }
        }

        strong{
            color: $color-black;
            font-weight: 500;
            display: block;
            margin-bottom: 0.35rem;
            font-size: 0.9375rem;
        }
        i:not(.fa-play):not(.fa-stop){
            position: absolute;
            left: 0;
            top: 2px;
        }
    }
</style>
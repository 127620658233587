import gql from 'graphql-tag'

export default gql `
    subscription onUpdatedQAMessage($thread: String!, $status: Int!) {
        onUpdatedQAMessage(
            thread: $thread,
            status: $status
        ) {
            id
            body
            sender
            sender_name
            sender_id
            read
            created
            updated
            ts
            thread
            status
        }
    }
`
<template>
	<div>
		<EventsHeader :title="id === 'new' ? 'Add event' : 'Edit event'"></EventsHeader>
		<el-form class="content-box margin-top--2" label-position="top" v-loading="loading_global">
			<el-form-item prop="name" label="Event name" class="margin-top--2">
				<el-input v-model="form.name" placeholder="Enter event name"></el-input>
			</el-form-item>
			<el-row :gutter="32" class="margin-top--3">
				<el-col :span="12">
					<el-form-item label="Starting date & time" prop="start">
						<el-date-picker v-model="form.start" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="Select date and time"></el-date-picker>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="Ending date & time" prop="end">
						<el-date-picker v-model="form.end" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="Select date and time"></el-date-picker>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row :gutter="32" class="margin-top--3">
				<el-col :span="4">
					<el-form-item prop="hidden" label="Hidden">
						<el-switch v-model="form.hidden" active-text="Yes"></el-switch>
					</el-form-item>
				</el-col>
				<el-col :span="4">
					<el-form-item prop="qa" label="Q&A">
						<el-switch v-model="form.qa" active-text="Yes"></el-switch>
					</el-form-item>
				</el-col>
				<el-col :span="4">
					<el-form-item prop="poll" label="Poll">
						<el-switch v-model="form.poll" active-text="Yes"></el-switch>
					</el-form-item>
				</el-col>
			</el-row>
      <el-row :gutter="32" class="margin-top--3">
        <el-col :span="5">
          <el-form-item label="Video stream">
            <el-select v-model="type">
              <el-option label="Live" value="live">Live</el-option>
              <el-option label="Sync" value="sync">Sync</el-option>
              <el-option label="Zoom" value="zoom">Zoom</el-option>
              <el-option label="Wowza" value="wowza">Wowza</el-option>
              <el-option label="YouTube" value="youtube">YouTube</el-option>
              <el-option label="Twitch" value="twitch">Twitch</el-option>
              <el-option label="Daily" value="daily">Daily</el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <template v-if="type === 'youtube'">
          <el-col :span="19">
            <el-form-item label="YouTube live video stream">
              <el-input v-model="form.youtube_live_video" placeholder=""></el-input>
            </el-form-item>
          </el-col>
        </template>
        <template v-else-if="type === 'daily'">
          <el-col :span="19">
            <el-form-item label="Daily live video stream">
              <el-input v-model="form.daily_live_video" placeholder=""></el-input>
            </el-form-item>
          </el-col>
        </template>
        <template v-else-if="type === 'twitch'">
          <el-col :span="19">
            <el-form-item label="Twitch live video stream">
              <el-input v-model="form.twitch_live_video" placeholder=""></el-input>
            </el-form-item>
          </el-col>
        </template>
        <template v-else-if="type === 'zoom'">
          <el-col :span="10">
            <el-form-item label="Zoom Meeting ID">
              <el-input v-model="form.zoom_meeting" placeholder="843 0752 3326"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="9">
            <el-form-item label="Zoom Password">
              <el-input v-model="form.zoom_pass" placeholder="Bm3wwU"></el-input>
            </el-form-item>
          </el-col>
        </template>
        <template v-else-if="type === 'wowza'">
          <el-col :span="19">
            <el-form-item label="Wowza live video stream">
              <el-input v-model="form.wowza_live_video" placeholder="https://cdn3.wowza.com/..../hls/live/playlist.m3u8"></el-input>
            </el-form-item>
          </el-col>
        </template>
        <template v-else>
          <el-col :span="5">
            <el-form-item label="Feed language">
              <el-select v-model="lang">
                <el-option v-for="(language, index) in available_languages" :key="index"  :label="languages[language]" :value="language">
                  {{languages[language]}}
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="9">
            <el-form-item v-if="lang === 'ro'" :label="type === 'live' ? 'Live feed url (Romanian)' : 'Sync video feed url (Romanian)'">
              <el-input v-if="type === 'live'" v-model="form.live_video__ro" placeholder="https://vimeo.com/wd3254frfd/pomt9midj"></el-input>
              <el-input v-else v-model="form.sync_video__ro" placeholder="https://vimeo.com/wd3254frfd/pomt9midj"></el-input>
            </el-form-item>
            <el-form-item v-else-if="lang === 'es'" :label="type === 'live' ? 'Live feed url (Spanish)' : 'Sync video feed url (Spanish)'">
              <el-input v-if="type === 'live'" v-model="form.live_video__es" placeholder="https://vimeo.com/wd3254frfd/pomt9midj"></el-input>
              <el-input v-else v-model="form.sync_video__es" placeholder="https://vimeo.com/wd3254frfd/pomt9midj"></el-input>
            </el-form-item>
            <el-form-item v-else-if="lang === 'it'" :label="type === 'live' ? 'Live feed url (Italian)' : 'Sync video feed url (Italian)'">
              <el-input v-if="type === 'live'" v-model="form.live_video__it" placeholder="https://vimeo.com/wd3254frfd/pomt9midj"></el-input>
              <el-input v-else v-model="form.sync_video__it" placeholder="https://vimeo.com/wd3254frfd/pomt9midj"></el-input>
            </el-form-item>
            <el-form-item v-else-if="lang === 'de'" :label="type === 'live' ? 'Live feed url (German)' : 'Sync video feed url (German)'">
              <el-input v-if="type === 'live'" v-model="form.live_video__de" placeholder="https://vimeo.com/wd3254frfd/pomt9midj"></el-input>
              <el-input v-else v-model="form.sync_video__de" placeholder="https://vimeo.com/wd3254frfd/pomt9midj"></el-input>
            </el-form-item>
            <el-form-item v-else-if="lang === 'fr'" :label="type === 'live' ? 'Live feed url (French)' : 'Sync video feed url (French)'">
              <el-input v-if="type === 'live'" v-model="form.live_video__fr" placeholder="https://vimeo.com/wd3254frfd/pomt9midj"></el-input>
              <el-input v-else v-model="form.sync_video__fr" placeholder="https://vimeo.com/wd3254frfd/pomt9midj"></el-input>
            </el-form-item>
            <el-form-item v-else :label="type === 'live' ? 'Live feed url' : 'Sync video feed url'">
              <el-input v-if="type === 'live'" v-model="form.live_video" placeholder="https://vimeo.com/wd3254frfd/pomt9midj"></el-input>
              <el-input v-else v-model="form.sync_video" placeholder="https://vimeo.com/wd3254frfd/pomt9midj"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="3">
            <el-form-item prop="replay" label="Replay">
              <el-switch v-model="form.replay" active-text="Yes"></el-switch>
            </el-form-item>
          </el-col>
        </template>
      </el-row>
			<el-form-item
				prop="location"
				label="Location"
				class="margin-top--3"
			>
				<el-select
					v-model="form.location"
					filterable
				>
					<el-option
						v-for="loc in locations"
						:key="`loc-${loc.id}`"
						:label="loc.name"
						:value="loc.id"
					></el-option>
				</el-select>
			</el-form-item>
			<el-form-item
				prop="hosts"
				label="Host"
				class="margin-top--3"
			>
				<el-select
					v-model="host"
					filterable
					clearable
				>
					<el-option
						v-for="host in hosts"
						:key="`host-${host.id}`"
						:label="host.name"
						:value="host.id"
					>
						<span style="float: left">{{ host.name }}</span>
						<span style="float: right; color: #8492a6; font-size: 13px">{{ host.email }}</span>
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item
				prop="hosts_excerpt"
				label="Hosts description"
				class="margin-top--3"
			>
				<el-input
					type="textarea"
					v-model="form.hosts_excerpt"
				></el-input>
			</el-form-item>
      <el-form-item v-if="form.qa" prop="phone" label="Phone number for SMS notifications" class="margin-top--3">
        <el-input v-model="form.phone" placeholder="Leave empty for no sms notifications"></el-input>
      </el-form-item>
			<el-form-item
				v-if="form.qa"
				prop="qa_moderator"
				label="Q&A moderator"
				class="margin-top--3"
			>
				<el-select
					v-model="moderator"
					filterable
					remote
					reserve-keyword
					placeholder="Please search moderator"
					:remote-method="remoteMethod"
					:loading="loading_moderator"
				>
					<el-option
						v-for="host in all_moderators"
						:key="`moderator-${host.objectID}`"
						:label="getUserName(host)"
						:value="host.objectID"
					>
						<span style="float: left">{{ getUserName(host) }}</span>
						<span style="float: right; color: #8492a6; font-size: 13px">{{ host.email }}</span>
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item
				prop="excerpt"
				label="Excerpt"
				class="margin-top--3"
			>
				<el-input
					type="textarea"
					v-model="form.excerpt"
				></el-input>
			</el-form-item>
			<div class="margin-top--4 form-footer">
				<el-button
					type="primary"
					:disabled="!form_has_changed"
					round
					v-on:click.prevent="save()"
				><i class="margin-right--05 fas fa-save"></i> Save event
				</el-button>
				<el-button
					type="text"
					class="color-gray"
					v-if="form_has_changed"
					v-on:click.prevent="resetForm()"
				><i class="margin-right--05 fas fa-undo"></i> Reset changes
				</el-button>
			</div>
		</el-form>
	</div>
</template>

<script>
import store from "@/store";
import Event from "@/schemas/Event";
import Settings from '@/schemas/Settings';
import _ from "lodash";
import Vue from "vue";
import algoliasearch from "algoliasearch";
import EventsHeader from "@/components/events/EventsHeader";
import titleMixin from '@/mixins'

let client = algoliasearch(process.env.VUE_APP_ALGOLIA_APP_ID || 'RDGKIGZ5MV', process.env.VUE_APP_ALGOLIA_SEARCH_API_KEY || '489633ec46587787f706b713d69a9bb2');
let index = client.initIndex(process.env.VUE_APP_ALGOLIA_USERS_INDEX || process.env.VUE_APP_SLUG);
export default {
	name: "Event",
	props: ['id'],
	mixins: [titleMixin],
	components: {
		EventsHeader
	},
	methods: {
		getHostName (id) {
			if (this.$options.filters.host_name(id).indexOf('auth0') === -1) {
				return this.$options.filters.host_name(id)
			} else {
				return 'No host is set'
			}
		},
		setForm: function (form) {
		  console.log('form is', form)
			this.form_witness = JSON.stringify(form)
			this.form = JSON.parse(JSON.stringify(form))
		},
		resetForm: function () {
			this.form = JSON.parse(this.form_witness)
		},
		addMinutesToDate (date, minutes) {
			if (date && minutes) {
				const added = new Date(date.getTime() + minutes * 60000);
				const formatted =
					added.getFullYear() + "-" + ("00" + (added.getMonth() + 1)).slice(-2) + "-" + ("00" + added.getDate()).slice(-2) + " " + ("00" + added.getHours()).slice(-2) + ":" +
					("00" + added.getMinutes()).slice(-2) + ":" +
					("00" + added.getSeconds()).slice(-2);
				return formatted
			}
		},
		save: function () {
      if (this.type === 'daily') {
        this.form.live = false
        this.form.sync = false
        this.form.zoom = false
        this.form.wowza = false
        this.form.twitch = false
        this.form.youtube = false
        this.form.daily = true
      }
      else if (this.type === 'twitch') {
        this.form.live = false
        this.form.sync = false
        this.form.zoom = false
        this.form.wowza = false
        this.form.twitch = true
        this.form.youtube = false
        this.form.daily = false
      }
      else if (this.type === 'youtube') {
        this.form.live = false
        this.form.sync = false
        this.form.zoom = false
        this.form.wowza = false
        this.form.twitch = false
        this.form.youtube = true
        this.form.daily = false
      }
      else if (this.type === 'zoom') {
        this.form.live = false
        this.form.sync = false
        this.form.zoom = true
        this.form.wowza = false
        this.form.twitch = false
        this.form.youtube = false
        this.form.daily = false
      } else if (this.type === 'wowza') {
        this.form.live = false
        this.form.sync = false
        this.form.zoom = false
        this.form.wowza = true
        this.form.twitch = false
        this.form.youtube = false
        this.form.daily = false
      }
      else if (this.type === 'live') {
				this.form.live = true
        this.form.sync = false
        this.form.zoom = false
        this.form.wowza = false
        this.form.twitch = false
        this.form.youtube = false
        this.form.daily = false
			} else {
				this.form.live = false
				this.form.sync = true
        this.form.zoom = false
        this.form.wowza = false
        this.form.twitch = false
        this.form.youtube = false
        this.form.daily = false
			}
			if (this.form.start && (!this.form.end || this.form.end === '')) {
				this.form.end = this.addMinutesToDate(new Date(this.form.start), 45)
			}
			if (this.id === 'new') {
				store.dispatch('events/create', this.form)
			} else {
				store.dispatch('events/update_event', this.form)
			}
		},
		remoteMethod: function (query) {
			let vm = this
			if (query !== '') {
				vm.loading_moderator = true;
				index.search(query, {
					hitsPerPage: 50,
					page: 0
				}).then(({ hits }) => {
					vm.loading_moderator = false;
					vm.moderators = hits;
				}).catch(function (e) {
					window.console.log(e);
					vm.loading_moderator = false;
				})
			} else {
				this.moderators = [];
			}
		},

		getUserName: function (host) {
			let name = host.user_metadata.first_name ? host.user_metadata.first_name.trim() : ''
			name += host.user_metadata.last_name ? ' ' + host.user_metadata.last_name.trim() : ''
			name = name.trim()
			name = name.length > 0 ? name : host.email
			return name
		},
		getHostExists (id) {
			return _.filter(this.hosts, (host) => {
				return host.id === id
			}).length > 0
		},
		getModeratorExists (id) {
			return _.filter(this.all_moderators, (moderator) => {
				return moderator.objectID === id
			}).length > 0
		}
	},
	data: function () {
		return {
			witness: JSON.stringify(new Event().toObject()),
			form: new Event().toObject(),
			loading_moderator: false,
			moderators: [],
			lang: 'en',
			languages: {
				'en': 'English',
				'ro': 'Romanian',
				'it': 'Italian',
				'es': 'Spanish',
				'de': 'German',
				'fr': 'French'
			}
		}
	},
	mounted: function () {

		this.setForm(this.event || this.form)
		let vm = this
		store.subscribe((mutation) => {
			if (mutation.type === 'events/ADD') {
				if (vm.id === 'new') {
					vm.$router.push({ name: 'Event', params: { id: mutation.payload.id } }).catch()
				}
			}
		})
		if (this.form.qa_moderator && this.form.qa_moderator.length > 5) {
			this.remoteMethod(this.form.qa_moderator)
		} else {
			this.remoteMethod('')
		}
		this.lang = store.getters.settings.lang || this.lang
	},
	watch: {
		event: function (n) {
		  console.log('watcher event', n)
			this.setForm(n || new Event().toObject())
		}
	},
	computed: {
		all_moderators () {
			return store.getters.moderators
		},
		available_languages () {
			return store.getters.settings.available_languages
		},
		page_title () {
			return this.is_new ? 'Events | Add event' : 'Events | Edit event'
		},
		is_new: function () {
			return _.isUndefined(this.id) || this.id === 'new'
		},
		form_has_changed: function () {
			return JSON.stringify(this.form) !== this.form_witness
		},
		events: function () {
			return store.getters.events
		},
		event: function () {
			return _.find(this.events, { id: this.id })
		},
		type: {
			get: function () {
        if( this.form.daily ){
          return 'daily'
        }
        if( this.form.twitch ){
          return 'twitch'
        }
        if( this.form.youtube ){
          return 'youtube'
        }
        if( this.form.zoom ){
          return 'zoom'
        }
        if( this.form.wowza ){
          return 'wowza'
        }
        return this.form.live ? 'live' : 'sync'
			},
			set: function (n) {
        let falses = []
        if( n === 'twitch' ){
          falses = ['live', 'sync', 'zoom', 'wowza', 'youtube', 'daily']
        }
        else if( n === 'youtube' ){
          falses = ['live', 'sync', 'zoom', 'wowza', 'twitch', 'daily']
        }
        else if( n === 'daily' ){
          falses = ['live', 'sync', 'zoom', 'wowza', 'twitch', 'youtube']
        }
        else if( n === 'zoom' ){
          falses = ['live', 'sync', 'youtube', 'wowza', 'twitch', 'daily']
        }
        else if( n === 'wowza' ){
          falses = ['live', 'sync', 'youtube', 'zoom', 'twitch', 'daily']
        }
				else if (n === 'live') {
          falses = ['wowza', 'sync', 'youtube', 'zoom', 'twitch', 'daily']
				}
				else {
          falses = ['wowza', 'live', 'youtube', 'zoom', 'twitch', 'daily']
				}
				this.$set(this.form, n || 'sync', true)
				_.each(falses, (tag) => {
          this.$set(this.form, tag, false)
        })
			}
		},
		locations: function () {
			return _.orderBy(store.getters.locations, ['name'])
		},
		host: {
			get: function () {
				try {
					return this.getHostExists(this.form.hosts[0]) ? this.form.hosts[0] : null
				} catch (e) {
					return null
				}
			},
			set: function (n) {
				Vue.set(this.form, 'hosts', this.form.hosts || [])
				Vue.set(this.form.hosts, 0, n)
			}
		},
		hosts: function () {
			return store.getters.hosts_by_name
		},
		moderator: {
			get: function () {
				try {
					return this.getModeratorExists(this.form.qa_moderator) ? this.form.qa_moderator : null
				} catch (e) {
					return null
				}
			},
			set: function (n) {
				Vue.set(this.form, 'qa_moderator', n)
			}
		},
	}
}
</script>

<style scoped>
</style>
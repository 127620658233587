import { render, staticRenderFns } from "./ModerationList.vue?vue&type=template&id=0d61a415&scoped=true&"
import script from "./ModerationList.vue?vue&type=script&lang=js&"
export * from "./ModerationList.vue?vue&type=script&lang=js&"
import style0 from "./ModerationList.vue?vue&type=style&index=0&id=0d61a415&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d61a415",
  null
  
)

export default component.exports
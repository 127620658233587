import gql from 'graphql-tag'
export default gql `
    query getEvent($id: ID!){
        getEvent(
            id: $id
        ){
            id
            name
            excerpt
            start
            end
            live
            poll
            poll_order
            chat
            chat_group
            location
            hosts
            results
            qa
            qa_moderator
            status
            qa_status
            poll_status
            sync
            sync_video
            sync_updated
            started
            stopped
            live_video
            hidden
            live_video__ro
            sync_video__ro
            live_video__es
            sync_video__es
            live_video__it
            sync_video__it
            live_video__fr
            sync_video__fr
            live_video__de
            sync_video__de
            hosts_excerpt
            replay
            zoom
            zoom_meeting
            zoom_pass
            wowza
            wowza_live_video
            youtube
            youtube_live_video
            twitch
            twitch_live_video
            daily
            daily_live_video
            phone
         }
    }
`
<template>
	<div>
		<EventsHeader title="Events"></EventsHeader>
		<el-row
			:gutter="5"
			class="margin-top--2 margin-bottom--1"
		>
			<el-col :span="16">
				<el-input
					v-model="search"
					placeholder="Search event"
				></el-input>
			</el-col>
			<el-col :span="8">
				<el-select v-model="location">
					<el-option
						label="All"
						value=""
					></el-option>
					<el-option
						v-for="loc in locations"
						:key="loc.id"
						:label="loc.name"
						:value="loc.id"
					></el-option>
				</el-select>
			</el-col>
		</el-row>
		<el-table
			:data="events"
			class="content-box--shadow table-events"
			v-loading="loading_global"
		>
			<el-table-column label="Name">
				<template slot-scope="scope">
					<router-link :to="{ name: 'Event', params: { id: scope.row.id } }">{{ scope.row.name }}</router-link>
				</template>
			</el-table-column>
			<el-table-column
				width="140"
				sortable
				label="Start"
				prop="start"
			>
				<template slot-scope="scope">
					{{ scope.row.start | moment("DD/MM @ HH:mm") }}
				</template>
			</el-table-column>
			<el-table-column
				width="175"
				sortable
				prop="location"
				label="Location"
			>
				<template slot-scope="scope">
					<router-link
						v-if="scope.row.location"
						:to="{ name: 'Location', params: { id: scope.row.location } }"
					>{{ scope.row.location | location_name }}</router-link>
				</template>
			</el-table-column>
			<el-table-column
				width="150"
				prop="host"
				label="Host"
			>
				<template slot-scope="scope">
					<template v-if="scope.row.hosts && scope.row.hosts.length > 0 && scope.row.hosts[0].length > 2">
						{{ getHostName(scope.row.hosts[0])  }}
					</template>
				</template>
			</el-table-column>
			<el-table-column
				width="120"
				align="right"
			>
				<template slot-scope="scope">
					<a
						href="#"
						v-on:click.prevent="$router.push({ name: 'Event', params: { id: scope.row.id } })"
					><em class="fad fa-fw fa-edit"></em></a>
					<a
						href="#"
						class="margin-left--1"
						v-on:click.prevent="duplicateEvent(scope.row.id)"
					><em class="fad fa-fw fa-clone"></em></a>
					<a
						href="#"
						class="margin-left--1 color-red"
						v-on:click.prevent="deleteEvent(scope.row.id)"
					><em class="far fa-fw fa-times "></em>
					</a>
				</template>
			</el-table-column>
		</el-table>
	</div>
</template>

<script>
//import _ from "lodash";
import store from "@/store";
import _ from "lodash";
import Event from "@/schemas/Event";
import EventsHeader from "@/components/events/EventsHeader"
import titleMixin from '@/mixins'

export default {
	name: "Events",
	components: {
		EventsHeader
	},
	mixins: [titleMixin],
	computed: {
		page_title () {
			return 'Events'
		},
		events: function () {
			let events = store.getters.events
			let search = this.search
			if (search.length > 0) {
				events = _.filter(events, function (e) {
					return e.name.toLowerCase().indexOf(search.toLowerCase()) >= 0
				})
			}
			if (this.location.length > 0) {
				events = _.filter(events, { location: this.location })
			}
			return _.orderBy(events, ['start'])
		},
		locations: function () {
			return _.orderBy(store.getters.locations, ['name'])
		}
	},
	methods: {
		getHostName (id) {
			const userExists = this.$options.filters.host_name(id).indexOf('auth0') < 0 && this.$options.filters.host_name(id).indexOf('google-oauth2') < 0
			if (userExists) {
				return this.$options.filters.host_name(id)
			} else {
				return 'No host is set'
			}
		},
		duplicateEvent: function (id) {
			let orginal = _.find(this.events, { id: id })
			if (!_.isUndefined(orginal)) {
				let replica = new Event(orginal).toObject()
				replica.name = `${replica.name} (copy)`
				replica.slug = `${replica.slug}-copy`
				store.dispatch('events/create', replica)
			}
		},
		deleteEvent: function (id) {
			let ev = _.find(store.getters.events, { id: id })
			this.$confirm(`This will permanently delete the "${ev.name}" location. Continue?`, 'Warning', {
				confirmButtonText: 'OK',
				cancelButtonText: 'Cancel',
				type: 'warning'
			}).then(() => {
				store.dispatch('events/delete', id)
			}).catch(() => {
				this.$message({
					type: 'info',
					message: 'Delete canceled'
				});
			});
		}
	},
	data: function () {
		return {
			search: '',
			location: ''
		}
	}
}
</script>

<style lang="scss">
.el-table.table-events .cell.cell {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
</style>
import _ from 'lodash'
import Vue from 'vue'
import {ApolloClient} from "@/main"
import listImages from '@/appsync/queries/images/list'
import webAuth from "@/auth"
import moment from "moment"


const state = {
    list: []
}

// getters
const getters = {
    list: function(state){
        return _.filter( state.list, function (i) {
            let extension = i.key.split('.').pop()
            return [ 'jpg', 'jpeg', 'png', 'svg' ].indexOf( extension.toLowerCase() ) >= 0
        })
    },
    folders: function(state){
        return _.filter( state.list, function (i) {
            let extension = i.key.split('.').pop()
            return [ 'jpg', 'jpeg', 'png', 'svg' ].indexOf( extension.toLowerCase() ) === -1
        })
    }
}

// actions
const actions = {
    list: async function ({dispatch, commit}, prefix ) {
        dispatch('loader', 'images/list', { root: true })
        try{
            let get = await ApolloClient.query({
                query: listImages,
                variables: {
                    prefix
                }
            })
            commit( 'SET_LIST', get.data.listImages )
        } catch (e) {
            window.console.log(e)
            if( ! _.isUndefined( e.networkError ) &&  ! _.isUndefined( e.networkError.statusCode ) && e.networkError.statusCode === 401 ){
                webAuth.authorize()
            } else {
                commit('LOG', { type: 'error', title: 'Could not get settings', message: e.message || e }, {root: true})
            }
        }
        dispatch('loader', 'images/list', { root: true })
    },
    add: async function({commit}, file){
        commit('ADD', file)
    }
}

// mutations
const mutations = {
    SET_LIST: function (state, c){
        Vue.set( state, 'list', c )
    },
    ADD: function(state, i){
        state.list.push({ key: i, updated: moment().format() })
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
<template>
	<div>
		<UsersHeader
			title="Users"
			:search="true"
			v-on:search="searchUser"
		></UsersHeader>
		<div
			class="boxes boxes--three margin-top--2"
			v-loading="loading_global"
			v-if="list.length > 0"
		>
			<div
				class="box box--user"
				v-for="user in list"
				v-bind:key="user.objectID"
				v-on:click.prevent="$router.push({ name: 'users-edit', params: { id: user.objectID } })"
			>
				<avatar
					:username="getFullName(user)"
					:backgroundColor="getRandomColor(user.email)"
					:lighten="-40"
					:size="72"
				></avatar>
				<h3>{{getFullName(user)}}</h3>
				{{user.email}}
				<i
					class="icon fa-lg fad fa-ghost"
					v-if="user.app_metadata && user.app_metadata.role === '360'"
				></i>
			</div>
		</div>
		<div
			v-else
			class="content-box text-align--center margin-top--2"
		>
			<img
				src="@/assets/empty.svg"
				class="empty-svg"
			>
			<p class="margin-top--2">No users in the database.</p>
		</div>
		<el-row
			type="flex"
			align="middle"
			class="margin-top--2 hidden--mobile"
		>
			<el-col :span="6">
				<export-users></export-users>
			</el-col>
			<el-col
				:span="12"
				align="center"
			>
				<el-pagination
					layout="prev, pager, next"
					:total="total"
					@current-change="changePage"
				></el-pagination>
			</el-col>
			<el-col
				:span="6"
				align="right"
			>
				{{total}} total users
			</el-col>
		</el-row>
		<div class="visible--mobile margin-top--2 text-align--center">
			<div class="text-size--xs margin-bottom--2">{{total}} total users</div>
			<el-pagination
				layout="prev, pager, next"
				:total="total"
				@current-change="changePage"
			></el-pagination>
		</div>

	</div>
</template>

<script>
import _ from "lodash";
import Avatar from 'vue-avatar'
import UsersHeader from "@/components/users/UsersHeader";
import store from '@/store'
import ExportUsers from "@/components/users/ExportUsers"
import titleMixin from '@/mixins'

export default {
	name: "UsersList",
	data: function () {
		return {
			search: '',
			page: 0
		}
	},
	mixins: [titleMixin],
	components: {
		Avatar,
		UsersHeader,
		ExportUsers
	},
	watch: {
		search: _.debounce(function (v) {
			this.listUsers(v)
		}, 100),
		page: function (n) {
			store.dispatch('users/set_page', { page: n, search: this.search })
		}
	},
	beforeRouteEnter: function (to, from, next) {
		store.dispatch('users/list', { search: '' })
		next()
	},
	computed: {
		page_title () {
			return `Users`
		},
		list: function () {
			return store.getters.users
		},
		total: function () {
			return store.getters.total_users
		}
	},

	methods: {
		searchUser: function (v) {
			this.search = v
		},

		listUsers: function (search) {
			store.dispatch('users/list', { search: search || this.search })
		},
		getFullName: function (o) {
			if (_.isUndefined(o.user_metadata)) {
				return o.email
			}
			if (!_.isUndefined(o)) {
				let name = o.user_metadata.first_name || ''
				return !_.isUndefined(o.user_metadata.last_name) ? `${name} ${o.user_metadata.last_name}` : name
			}
			return `${o.user_metadata.first_name} ${o.user_metadata.last_name}`
		},
		changePage: function (p) {
			this.page = p - 1
		}
	}
}
</script>

<style scoped lang="scss">
</style>
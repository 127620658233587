<template>
  <div>
    <h1 class="page-title">
      <span v-html="page_title"></span>
      <em v-if="!is_host" v-on:click.prevent="list = !list"
          class="fa-fw float--right margin-top--15px cursor--pointer color-primary margin-left--1"
          :class="{ 'fad fa-th-list': !list, 'fas fa-th-large': list }"></em>

      <el-tag size="small" type="info" effect="dark" class="margin-left--2"><span
          class="text-size--small margin-top--1px "> <em class="fas fa-fw fa-id-badge"></em> {{ badges }}</span>
      </el-tag>
      <el-tag size="small" type="success" effect="dark" class="margin-left--05" :class="loading_online ? 'cursor--not-allowed' : 'cursor--pointer'" v-on:click.prevent="checkWhoIsOnline"><span class="text-size--small margin-top--1px"><em
          class="fas fa-fw fa-plug"></em> {{ online }} <em class="far fa-spin fa-spinner margin-left--5px" v-if="loading_online"></em></span></el-tag>
      <em v-if="!is_host && list"
          v-on:click.prevent="details = !details"
          class="fa-fw float--right margin-top--15px cursor--pointer color-primary margin-left--1"
          :class="{ 'fad fa-folder': !details, 'fas fa-folder-open': details }"></em>
    </h1>
    <template v-if="!list">
      <div v-if="events.length > 0" class="boxes boxes--two margin-top--2" v-loading="loading_global">
        <div class="box" v-for="(ev, evi) in events" :key="evi">
          <el-row type="flex" align="middle">
            <el-col :span="12">
              <el-tag size="mini" :type="getTimerTagType(ev.start)" round>
                            <span class="status-type">
                                <template
                                    v-if="['primary','warning', 'danger'].indexOf( getTimerTagType(ev.start) ) >= 0">
                                    Starting
                                </template>
                                <template v-else>Started</template>
                            </span>
                {{ ev.start | timestamp | timeago }}
              </el-tag>
            </el-col>
            <el-col :span="12" align="right" class="color-gray opacity--50 text-size--smaller">
              {{ ev.start | moment('MMM DD') }}
            </el-col>
          </el-row>
          <h1 class="margin-top--1 margin-bottom--5 title">{{ ev.name }}</h1>
          <el-row type="flex" align="middle">
            <el-col :xs="16" :sm="16" :lg="12">
              <div class="host-name">
                <avatar v-if="getHost(ev)" :username="getHost(ev).name" :size="45"></avatar>
                <div class="name" v-if="getHost(ev)">{{ getHost(ev).name }}</div>
              </div>
            </el-col>
            <el-col :xs="8" :sm="8" :lg="12" align="right">
              <el-button type="primary" round
                         v-on:click.prevent="$router.push({ name: 'live-host', params: { id: ev.id } })">
                Join
              </el-button>
            </el-col>
          </el-row>
        </div>
      </div>
      <div v-else class="content-box text-align--center margin-top--2" v-loading="loading_global">
        <img src="@/assets/empty.svg" class="empty-svg">
        <p class="margin-top--2">There are no available events.</p>
      </div>
    </template>
    <template v-else>
      <div class="margin-top--3">
        <template v-if="details">
          <div v-for="(locations, index) in events_grouped" :key="index">
            <h3 class="date-grouping" v-on:click.prevent="toggleDate(index)">{{ index | moment('MMMM DD, dddd') }}</h3>
            <div v-if="hide.indexOf(index) === -1" v-for="(events, events_index) in locations" :key="events_index">
              <h5 class="location-grouping" v-on:click.prevent="toggleDate(events_index)">
                {{ events_index | location_name }}</h5>
              <div v-if=" hide.indexOf(events_index) === -1" class="events-table">
                <div class="events-table__header">
                  <div class="events-table__expand"></div>
                  <div class="events-table__date">Date</div>
                  <div class="events-table__time">Time</div>
                  <div class="events-table__type"></div>
                  <div class="events-table__name">Name</div>
                  <div class="events-table__actions"></div>
                </div>
                <template v-for="(event, bi) in events">
                  <div :key="bi" class="events-table__item">
                    <div class="events-table__expand cursor--pointer"
                         v-on:click.prevent="expanded = expanded !== event.id ? event.id : ''">
                      <em class="far cursor--pointer fa-fw fa-lg color-black"
                          :class="{ 'fa-angle-down': expanded !== event.id, 'fa-angle-up': expanded === event.id }"></em>
                    </div>
                    <div class="events-table__date">{{ event.start | moment('DD/MM') }}</div>
                    <div class="events-table__time">{{ event.start | moment('HH:mm') }}</div>
                    <div class="events-table__type">
                      <el-tag v-if="event.sync" size="mini" round>SYNC</el-tag>
                      <el-tag v-else-if="event.live" size="mini" round type="danger">LIVE</el-tag>
                      <el-tag v-else-if="event.zoom" size="mini" round type="info">ZOOM</el-tag>
                    </div>
                    <div class="events-table__name">
                      <router-link :to="{ name: 'live-host', params: event }">{{ event.name }}</router-link>
                    </div>
                    <div class="events-table__actions">
                      <el-button-group>
                        <el-button v-if="event.qa" size="mini"
                                   :type="event.qa_status === 0 ? 'success' : 'danger'"
                                   v-on:click.prevent="toggleStatus(event, 'qa_status')" round>
                          <template v-if="event.qa_status === 0">Start Q&A</template>
                          <template v-else>Stop Q&A</template>
                        </el-button>
                        <el-button v-if="event.poll" size="mini"
                                   :type="event.poll_status === 0 ? 'success' : 'danger'"
                                   v-on:click.prevent="toggleStatus(event, 'poll_status')" round>
                          <template v-if="event.poll_status === 0">Start Poll</template>
                          <template v-else>Stop Poll</template>
                        </el-button>
                        <el-button v-on:click.prevent="toggleEvent(event)" size="mini"
                                   :type="event.status === 0 ? 'success' : 'danger'" round><em
                            class="fas fa-fw"
                            :class="{ 'fa-play': event.status === 0, 'fa-stop': event.status === 1 }"></em>
                        </el-button>
                      </el-button-group>
                    </div>
                  </div>
                  <div v-if="expanded === event.id" class="events-table__details" :key="`details_${bi}`">
                    <p><span>Location:</span> {{ event.location | location_name }}</p>
                    <p v-if="event.hosts.length > 0"><span>Host:</span>
                      <router-link :to="{ name: 'users-edit', params: { id: event.hosts[0] } }">
                        {{ event.hosts[0] | host_name }}
                      </router-link>
                    </p>
                    <p v-if="event.qa && event.qa_moderator"><span>Q&A Moderator:</span>
                      <router-link :to="{ name: 'users-edit', params: { id: event.qa_moderator } }">
                        {{ event.qa_moderator | moderator_name }}
                      </router-link>
                    </p>
                    <p><span>Video feed:</span>
                      <template v-if="event.sync">{{ event.sync_video }}</template>
                      <template v-else>{{ event.live_video }}</template>
                    </p>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="events-table">
            <div class="events-table__header">
              <div class="events-table__expand"></div>
              <div class="events-table__date">Date</div>
              <div class="events-table__time">Time</div>
              <div class="events-table__type"></div>
              <div class="events-table__name">Name</div>
              <div class="events-table__actions"></div>
            </div>
            <template v-for="(event, bi) in events_sorted">
              <div :key="bi" class="events-table__item">
                <div class="events-table__expand cursor--pointer"
                     v-on:click.prevent="expanded = expanded !== event.id ? event.id : ''">
                  <em class="far cursor--pointer fa-fw fa-lg color-black"
                      :class="{ 'fa-angle-down': expanded !== event.id, 'fa-angle-up': expanded === event.id }"></em>
                </div>
                <div class="events-table__date">{{ event.start | moment('DD/MM') }}</div>
                <div class="events-table__time">{{ event.start | moment('HH:mm') }}</div>
                <div class="events-table__type">
                  <el-tag v-if="event.sync" size="mini" round>SYNC</el-tag>
                  <el-tag v-else-if="event.live" size="mini" round type="danger">LIVE</el-tag>
                  <el-tag v-else-if="event.zoom" size="mini" round type="info">ZOOM</el-tag>
                </div>
                <div class="events-table__name">
                  <router-link :to="{ name: 'live-host', params: event }">{{ event.name }}</router-link>
                </div>
                <div class="events-table__actions">
                  <el-button-group>
                    <el-button @click="exitFullScreen(event.location)" round size="mini">
                      <i class="fas fa-compress-arrows-alt"></i>
                    </el-button>
                    <el-button v-if="event.qa" size="mini" :type="event.qa_status === 0 ? 'success' : 'danger'"
                               v-on:click.prevent="toggleStatus(event, 'qa_status')" round>
                      <template v-if="event.qa_status === 0">Start Q&A</template>
                      <template v-else>Stop Q&A</template>
                    </el-button>
                    <el-button v-if="event.poll" size="mini"
                               :type="event.poll_status === 0 ? 'success' : 'danger'"
                               v-on:click.prevent="toggleStatus(event, 'poll_status')" round>
                      <template v-if="event.poll_status === 0">Start Poll</template>
                      <template v-else>Stop Poll</template>
                    </el-button>
                    <el-button v-on:click.prevent="toggleEvent(event)" size="mini"
                               :type="event.status === 0 ? 'success' : 'danger'" round><em
                        class="fas fa-fw"
                        :class="{ 'fa-play': event.status === 0, 'fa-stop': event.status === 1 }"></em>
                    </el-button>
                  </el-button-group>
                </div>
              </div>
              <div v-if="expanded === event.id" class="events-table__details" :key="`details_${bi}`">
                <p><span>Location:</span> {{ event.location | location_name }}</p>
                <p v-if="event.hosts && event.hosts.length > 0"><span>Host:</span>
                  <router-link :to="{ name: 'users-edit', params: { id: event.hosts[0] } }">{{
                      event.hosts[0] | host_name
                    }}
                  </router-link>
                </p>
                <p v-if="event.qa && event.qa_moderator"><span>Q&A Moderator:</span>
                  <router-link :to="{ name: 'users-edit', params: { id: event.qa_moderator } }">
                    {{ event.qa_moderator | moderator_name }}
                  </router-link>
                </p>
                <p><span>Video feed:</span>
                  <template v-if="event.sync">{{ event.sync_video }}</template>
                  <template v-else>{{ event.live_video }}</template>
                </p>
              </div>
            </template>

          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import _ from 'lodash'
import store from '@/store'
import Avatar from "vue-avatar/src/Avatar";
import moment from 'moment'
import titleMixin from '@/mixins'
import algoliasearch from "algoliasearch";
import {ApolloClient} from "@/main";
import pingLocation from "@/appsync/mutations/chat/ping";
import onPong from "@/appsync/subscriptions/chat/onPong";
import Templates from "@/components/templates/Templates";

let client = algoliasearch(process.env.VUE_APP_ALGOLIA_APP_ID || 'RDGKIGZ5MV', process.env.VUE_APP_ALGOLIA_SEARCH_API_KEY || '489633ec46587787f706b713d69a9bb2');
let index = client.initIndex(`${process.env.VUE_APP_ALGOLIA_USERS_INDEX  || process.env.VUE_APP_SLUG}_badges`);

const getState = (key, def = false) => {
  const state = JSON.parse(localStorage.getItem(key))
  return !_.isNil(state) ? state : def
}

export default {
  name: "LiveVideoList",
  components: {Templates, Avatar},
  mixins: [titleMixin],
  computed: {
    page_title() {
      return `Dashboard`
    },
    events_grouped: function () {
      return _.mapValues( _.groupBy(this.events_sorted, (ev) => moment(ev.start).format('YYYY-MM-DD')), (events) => _.groupBy( events, (ev) => ev.location  ) )
    },
    events: function () {
      let vm = this
      return _.filter(_.orderBy(store.getters.events, ['start']), function (ev) {
        let allow = ev.live || ev.zoom || ev.wowza || ev.daily
        allow = !allow ? ev.sync : allow
        if (allow && ['360', 'host'].indexOf(store.getters.logged_user['https://360live.events/role']) === -1) {
          allow = false
        }
        if (allow && vm.is_host) {
          let hosts = ev.hosts || []
          allow = hosts.indexOf(store.getters.logged_user.sub) >= 0
        }
        return allow
      })
    },
    events_sorted: function () {
      return _.orderBy(this.events, ['start'])
    },
    hosts: function () {
      return store.getters.hosts
    },
    is_host: function () {
      return store.getters.logged_user['https://360live.events/role'] === 'host'
    }
  },
  data: function () {
    return {
      list: getState('dashboard_list', true),
      expanded: '',
      badges: 0,
      online: 0,
      details: getState('dashboard_details'),
      hide: [],
      loading_online: false
    }
  },
  methods: {
    toggleDate: function (date) {
      const index = this.hide.indexOf(date)
      if( index >= 0 ){
        this.hide.splice(index, 1)
      } else{
        this.hide.push(date)
      }
    },
    exitFullScreen(id) {
      store.dispatch('ping', `system::exit-fullscreen::${id}`)
    },
    checkWhoIsOnline: async function () {
      if(this.loading_online){
        return
      }
      this.loading_online = true
      try {
        await ApolloClient.mutate({
          mutation: pingLocation,
          variables: {
            location: "*",
            body: 'ping'
          }
        })
        this.online = 0
      } catch (e) {
        this.loading_online = false
      }
    },
    getBadgesCount: async function () {
      try {
        let results = await index.search('', {hitsPerPage: 1})
        this.badges = results.nbHits
      } catch (e) {
      }
    },
    getHost: function (ev) {
      try {
        let host = _.find(this.hosts, {objectID: ev.hosts[0]})
        return {
          name: `${host.user_metadata.first_name} ${host.user_metadata.last_name}`,
          email: host.email
        }
      } catch (e) {
        return false
      }
    },
    toggleEvent: function (ev) {
      let update = {id: ev.id, status: ev.status === 0 ? 1 : 0}
      if (ev.sync) {
        update.sync_updated = moment().unix()
      }
      if (update.status === 0) {
        update.qa_status = 0
        update.poll_status = 0
        update.stopped = moment().unix()
      } else {
        update.started = moment().unix()
      }
      store.dispatch('events/patch_status', update)
    },
    toggleStatus: function (ev, key) {
      let update = {
        id: ev.id
      }
      update[key] = ev[key] === 1 ? 0 : 1
      store.dispatch('events/patch_status', update)
    }
  },
  watch: {
    events: function (n) {
      if (this.is_host && n.length === 1) {
        this.$router.push({name: 'live-host', params: {id: n[0].id}})
      }
    },
    list(list){
      localStorage.setItem('dashboard_list', list)
    },
    details(details){
      localStorage.setItem('dashboard_details', details)
    },
    loading_online(loading){
      if(loading){
        setTimeout(() => this.loading_online = false, 1000 * 10)
      }
    }
  },
  mounted: function () {
    if (this.is_host) {
      this.list = false
    }
    if (this.is_host && this.events.length === 1) {
      this.$router.push({name: 'live-host', params: {id: this.events[0].id}})
    }

    this.getBadgesCount();
    this.$apollo.subscriptions.onPong.refresh()
  },
  apollo: {
    $subscribe: {
      onPong: {
        query: onPong,
        variables: function () {
          return {
            location: "*"
          }
        },
        result(result) {
          const {data} = result
          if (data.onPong.body === 'pong') {
            this.online++
          }
        }
      }
    }
  },
}
</script>

<style scoped lang="scss">
.date-grouping{
  color: $color-black;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  background-color: white;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  box-shadow: 0 2px 8px -1px rgba($color-black, 0.11);
  margin-bottom: 0;

  text-align: center;

  &:hover{
    color: $color-gray;
  }

  > div:first-child .location-grouping{
    margin-top: 0;
  }
}
.location-grouping{
  font-weight: 600;
  cursor: pointer;
  text-align: center;

  &:hover{
    opacity: 0.65;
  }
}
.events-table {
  font-size: 0.9375rem;
  display: grid;
  grid-row-gap: 1.5rem;

  &__header {
    display: none;
  }

  @include breakpoint(tablet) {
    grid-column-gap: 1.5rem;
    grid-template-columns: 1fr 1fr;
  }

  @include breakpoint(laptop) {
    grid-template-columns: 100%;
  }

  &__item {
    background-color: $color-bg-box;
    border-radius: 5px;
    box-shadow: 0 2px 8px -1px rgba($color-black, 0.35);
    color: rgba($color-black, 0.75);
    transition: all 200ms ease;
    cursor: pointer;
    padding: 1.5rem;
    display: flex;
    flex-wrap: wrap;

    .events-table {
      &__name {
        order: 0;
        color: $color-black;
        margin-bottom: 1rem;
        flex-basis: 65%;
      }

      &__date {
        order: 3;
        flex-basis: 25%;

      }

      &__time {
        order: 4;
        flex-basis: 25%;
      }

      &__date,
      &__time {
        margin-bottom: 1rem;

        /*&::before{
            content: attr(data-title);
            font-weight: 500;
            display: inline-block;
            margin-right: 1rem;
        }*/
      }

      &__type {
        order: 2;
        flex-basis: 25%;
        text-align: right;
      }

      &__actions {
        order: 5;
        flex-basis: 100%;
        text-align: right;
        //display: none;
      }

      &__expand {
        display: none;
      }
    }
  }

  @include breakpoint(laptop) {
    grid-template-columns: 1fr;

    &__header {
      display: grid;
      grid-column-gap: 0;
      grid-template-columns: 6% 7% 7% 7.5% 1fr 32%;
      font-weight: 500;
      color: $color-black;
    }

    &__item {
      display: grid;
      grid-column-gap: 0;
      grid-template-columns: 6% 7% 7% 7.5% 1fr 32%;
      padding: 0;
      align-items: center;


      > div {
        padding: 1.25rem 0;
      }

      .events-table {
        &__name {
          color: inherit;
          margin-bottom: 0;
          margin-top: 0;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow-x: hidden;
          max-width: 400px;
          display: inline-block;
          order: 4;
        }

        &__expand {
          padding-left: 1.5rem;
          display: block;
        }

        &__date {
          order: 1;
          margin-bottom: 0;
        }

        &__time {
          order: 2;
          margin-bottom: 0;
        }

        &__actions {
          display: block;
          padding-right: 1.5rem;
          order: 10;

          .el-button.el-button--mini {
            padding: 0 0.9rem
          }
        }

        &__type {
          order: 3;
          text-align: left;
          margin-bottom: 0;
        }
      }

      &:hover {
        box-shadow: 0 18px 30px -4px rgba($color-black, 0.25);
        transform: translateY(-3px);

        .events-table {
          &__title {
            color: rgba($color-black, 1);

            i {
              color: $color-primary
            }
          }
        }
      }
    }
    &__details {
      display: grid;
      grid-template-columns: 50% 50%;
      color: $color-black;
      padding: 0 0 0 5rem;
      margin-bottom: 2rem;

      p {
        margin: 0.35rem 0;
      }

      span {
        width: 12rem;
        display: inline-block;
        color: $color-gray;
      }
    }
  }
}


.boxes--two {
  .box {
    padding: 1rem;
    display: flex;
    flex-direction: column;

    .title {
      flex-grow: 1;
    }

    .status-type {
      display: none;

      @include breakpoint(laptop) {
        display: inline;
      }
    }

    .host-name {
      position: relative;
      padding-left: 3.5rem;

      min-height: 2.8125rem;

      display: flex;
      align-items: center;
      align-content: center;
      max-width: 10rem;
      flex-wrap: wrap;
      justify-items: center;

      .vue-avatar--wrapper {
        position: absolute;
        left: 0
      }

      .name {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        margin-right: 10px;
      }
    }
  }

  h1 {
    line-height: 1.35;
    font-size: 1rem;
  }

  @include breakpoint(tablet) {
    .box {
      padding: 2rem;

      .host-name {
        max-width: 20rem;
      }

      h1 {
        font-size: 1.25rem;
      }
    }
  }
  @include breakpoint(desktop) {
    .box {
      padding: 3rem;
    }
  }

}

.el-timeline-item {
  padding-bottom: 3rem;
}

.tabel {
  .el-table__expanded-cell {
    p {
      font-size: 0.75rem;
    }
  }
}
</style>
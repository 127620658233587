import gql from 'graphql-tag'

export default gql `
    mutation checkForOnlineModerator($thread: String, $body: String, $id: String){
        checkForOnlineModerator(
            thread: $thread,
            body: $body,
            id: $id
        ){
            thread
            body
            id
        }
    }
`
import _ from "lodash"
import {ApolloClient} from "@/main";
import pingLocation from "@/appsync/mutations/chat/ping";
export default {
    setup: async function ({dispatch, rootGetters, commit}) {
        if( rootGetters.loaders.indexOf('utils/settings/get') === -1 ){
            await dispatch('settings/get')
        }
        if( rootGetters.loaders.indexOf('utils/faq/get') === -1 ){
            await dispatch('faq/get')
        }
        if( rootGetters.loaders.indexOf('locations/list') === -1 ){
            await dispatch('locations/list')
        }
        if( rootGetters.loaders.indexOf('hosts/list') === -1 ){
            await dispatch('hosts/list')
        }
        if( rootGetters.loaders.indexOf('moderators/list') === -1 ){
            await dispatch('moderators/list')
        }
        if( rootGetters.loaders.indexOf('events/list') === -1 ){
            await dispatch('events/list')
        }
        if( rootGetters.loaders.indexOf('questions/list') === -1 ){
            await dispatch('questions/list')
        }
        if( ! _.isUndefined( rootGetters.logged_user ) && rootGetters.logged_user_role === '360' && !_.isUndefined( rootGetters.logged_user['https://360live.events/priviledges'] ) && rootGetters.logged_user['https://360live.events/priviledges'] === 'owner' ){
            if( rootGetters.loaders.indexOf('templates/list') === -1 ){
                await dispatch('templates/list')
            }
        }
        commit('READY', true)
    },
    loader: function({commit}, l){
        commit('TOGGLE_LOADER', l)
    },
    set_logged_user: function({commit}, user){
        commit('SET_LOGGED_USER', user || JSON.parse(localStorage.getItem('user')) )
    },
    ping: async function({}, message){
        try {
            let variables = {
                location: '*',
                body: message
            }
            await ApolloClient.mutate({
                mutation: pingLocation,
                variables: variables
            })
        } catch (e) {}
    }
}
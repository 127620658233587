import _ from 'lodash'
import algoliasearch from "algoliasearch";
import webAuth from "@/auth"
import cleanDeep from "clean-deep"
import User from '@/schemas/User'
import { ApolloClient } from "@/main"
import createUser from '@/appsync/mutations/users/create'
import updateUser from '@/appsync/mutations/users/update'
import updateUserPassword from '@/appsync/mutations/users/updateUserPassword'
import getUser from '@/appsync/queries/users/get'
import deleteUser from '@/appsync/mutations/users/delete'

const state = {
	list: [],
	page: 0,
	total: 0,
	single: new User().toObject()
}

// getters
const getters = {
	list: function (state) {
		return state.list
	},
	page: function (state) {
		return state.page
	},
	total: function (state) {
		return state.total
	},
	single: function (state) {
		return state.single
	}
}

// actions
const actions = {
	list: async function ({ dispatch, commit, getters }, { search }) {
		dispatch('loader', 'users/list', { root: true })
		try {
			let client = algoliasearch(process.env.VUE_APP_ALGOLIA_APP_ID || 'RDGKIGZ5MV', process.env.VUE_APP_ALGOLIA_SEARCH_API_KEY || '489633ec46587787f706b713d69a9bb2');
			let index = client.initIndex(process.env.VUE_APP_ALGOLIA_USERS_INDEX || process.env.VUE_APP_SLUG);

			index.search({
				query: search,
				hitsPerPage: 9,
				page: getters.page
			},
				function searchDone (err, content) {
					if (err) throw err;
					commit('SET_LIST', content.hits)
					commit('SET_TOTAL', content.nbHits)
				}
			);
		} catch (e) {
			commit('LOG', { type: 'error', message: e.message || e }, { root: true })
		}
		dispatch('loader', 'users/list', { root: true })
	},
	set_page: function ({ commit, dispatch }, { page, search }) {
		commit('SET_PAGE', page)
		dispatch('list', { search })
	},
	update: async function ({ dispatch, commit }, form) {
		dispatch('loader', 'users/update', { root: true })
		try {
			/*form = cleanDeep(form,{
				emptyArrays: false,
				nullValues: false
			})*/
			if (!_.isUndefined(form.password)) {
				delete form.password
				delete form.retype
			}
			delete form.created_at
			delete form.updated_at
			let updated = await ApolloClient.mutate({
				mutation: updateUser,
				variables: {
					input: form
				}
			})
			commit('PATCH', updated.data.updateUser)
			commit('LOG', { type: 'success', message: "Successfully updated user" }, { root: true })
		} catch (e) {
			if (!_.isUndefined(e.networkError) && !_.isUndefined(e.networkError.statusCode) && e.networkError.statusCode === 401) {
				webAuth.authorize()
			} else {
				commit('LOG', { type: 'error', message: e.message || e }, { root: true })
			}
		}
		dispatch('loader', 'users/update', { root: true })
	},
	update_user_password: async function ({ dispatch, commit }, form) {
		dispatch('loader', 'users/update_user_password', { root: true })
		try {
			let updated = await ApolloClient.mutate({
				mutation: updateUserPassword,
				variables: {
					input: form
				}
			})
			commit('PATCH', updated.data.updateUserPassword)
			commit('LOG', { type: 'success', message: "Successfully updated user password" }, { root: true })
		} catch (e) {
			if (!_.isUndefined(e.networkError) && !_.isUndefined(e.networkError.statusCode) && e.networkError.statusCode === 401) {
				webAuth.authorize()
			} else {
				commit('LOG', { type: 'error', message: e.message || e }, { root: true })
			}
		}
		dispatch('loader', 'users/update_user_password', { root: true })
	},
	create: async function ({ dispatch, commit }, form) {
		dispatch('loader', 'users/create', { root: true })
		try {
			delete form.retype
			form = cleanDeep(form, {
				emptyArrays: false,
				nullValues: false
			})

			let created = await ApolloClient.mutate({
				mutation: createUser,
				variables: {
					input: form
				}
			})
			commit('ADD', created.data.createUser)
			commit('LOG', { type: 'success', message: "Successfully created user" }, { root: true })
		} catch (e) {
			if (!_.isUndefined(e.networkError) && !_.isUndefined(e.networkError.statusCode) && e.networkError.statusCode === 401) {
				webAuth.authorize()
			} else {
				commit('LOG', { type: 'error', message: e.message || e }, { root: true })
			}
		}
		dispatch('loader', 'users/create', { root: true })
	},
	get: async function ({ dispatch, commit }, id) {
		dispatch('loader', 'users/get', { root: true })
		try {
			let item = await ApolloClient.query({
				query: getUser,
				variables: {
					user_id: id
				}
			})
			commit('SET', item.data.getUser)
		} catch (e) {
			commit('LOG', { type: 'error', message: e.message || e }, { root: true })
		}
		dispatch('loader', 'users/get', { root: true })
	},
	remove: async function ({ dispatch, commit }, id) {
		dispatch('loader', 'users/remove', { root: true })
		try {
			let removed = await ApolloClient.mutate({
				mutation: deleteUser,
				variables: {
					user_id: id
				}
			})
			commit('REMOVE', removed.data.deleteUser)
			commit('LOG', { type: 'success', message: "Successfully removed user" }, { root: true })
		} catch (e) {
			if (!_.isUndefined(e.networkError) && !_.isUndefined(e.networkError.statusCode) && e.networkError.statusCode === 401) {
				webAuth.authorize()
			} else {
				commit('LOG', { type: 'error', message: e.message || e }, { root: true })
			}
		}
		dispatch('loader', 'users/remove', { root: true })
	}
}

// mutations
const mutations = {
	SET_LIST (state, list) {
		state.list = list
	},
	SET_PAGE: function (state, page) {
		state.page = page
	},
	SET_TOTAL: function (state, total) {
		state.total = total
	},
	ADD: function (state, item) {
		state.list.push({ ...new User(item).toObject(), objectID: item.user_id })
		state.single = new User(item).toObject()
	},
	PATCH: function (state, item) {
		state.single = new User(item).toObject()
	},
	SET: function (state, item) {
		state.single = new User(item).toObject()
	},
	REMOVE: function (state, item) {
		state.list.splice(_.findIndex(state.list, { objectID: item.user_id }), 1)
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}

import gql from 'graphql-tag'

export default gql `
    mutation kickQAParticipant($input: UpdateQAMessageInput!){
        kickQAParticipant(
            input: $input
        ){
            id
            status
            sender_id
            thread
        }
    }
`
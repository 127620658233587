export const emojis = {
	':)': {
		name: 'smiley'
	},
	':(': {
		name: 'white_frowning_face'
	},
	':))': {
		name: 'smiley'
	},
	':d': {
		name: 'grin'
	},
	':p': {
		name: 'stuck_out_tongue'
	},
	':b': {
		name: 'sunglasses'
	},
	'=))': {
		name: 'rolling_on_the_floor_laughing'
	},
	'=)': {
		name: 'laughing'
	},
}
<template>
    <div>
        <TemplatesHeader title="Templates"></TemplatesHeader>
        <el-table class="margin-top--2 content-box--shadow" :data="templates" v-loading="loading_global">
            <el-table-column width="150">
                <template slot-scope="scope">
                    {{ scope.row.name | template_name }}
                </template>
            </el-table-column>
            <el-table-column label="Name">
                <template slot-scope="scope">
                    {{ scope.row.name.substr(scope.row.name.indexOf(' ') + 1) }}
                </template>
            </el-table-column>
            <el-table-column label="Background"  width="200">
                <template slot-scope="scope">
                    <a :href="getImgSrc({}, scope.row.bg)" target="_blank">{{scope.row.bg_width}}px x {{scope.row.bg_height}}px</a>
                </template>
            </el-table-column>
            <el-table-column label="Heat" width="150">
                <template slot-scope="scope">
                    <span class="fas fa-fw" :class="scope.row.has && scope.row.has.indexOf('heat_map') >= 0 ? 'fa-check color-green' : 'fa-times color-danger'"></span>
                </template>
            </el-table-column>
            <el-table-column label="Video" width="150">
                <template slot-scope="scope">
                    <span class="fas fa-fw" :class="scope.row.has && scope.row.has.indexOf('video') >= 0 ? 'fa-check color-green' : 'fa-times color-danger'"></span>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
    import store from '@/store';
    import TemplatesHeader from "@/components/templates/TemplatesHeader"
    import titleMixin from '@/mixins'
    import _ from 'lodash'

    export default {
        name: "Templates",
        components: {TemplatesHeader},
        mixins: [titleMixin],
        computed: {
            page_title () {
                return 'Templates'
            },
            templates: function () {
                return _.orderBy(store.getters.templates, ['name'])
            }
        },
        filters: {
            template_name: function(t){
                return t.substr(0, t.indexOf(' ')).toUpperCase()
            }
        }
    }
</script>

<style lang="scss">
    .dashes{
        .fad{
            //opacity: 0;
        }
        .fad:last-child{
            opacity: 1;
        }
        .fa-horizontal-rule + .fa-horizontal-rule{
            margin-left: 0.25rem;
        }
    }
</style>
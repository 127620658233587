<template>
    <div>
        <BroadcastsHeader title="Broadcasts"></BroadcastsHeader>
        <div v-if="broadcasts.length > 0" class="margin-top--3 broadcasts-table" v-loading="loading_global">
            <div class="broadcasts-table__header">
                <div class="broadcasts-table__title">Broadcast</div>
                <div class="broadcasts-table__created">Created</div>
                <div class="broadcasts-table__delivery">Delivery</div>
                <div class="broadcasts-table__status">Status</div>
                <div class="broadcasts-table__sent">Sent</div>
                <div class="broadcasts-table__actions"></div>
            </div>
            <div v-for="(broadcast, bi) in broadcasts" :key="bi" class="broadcasts-table__item" v-on:click.prevent="$router.push({ name: 'broadcasts-edit', params: { id: broadcast.id } })">
                <div class="broadcasts-table__title">
                    <i class="fad fa-fw  fa-lg margin-right--1" :class="{ 'fa-sms' : broadcast.type === 0, 'fa-envelope-open' : broadcast.type === 1 }"></i>
                    <template v-if="broadcast.type === 0 || broadcast.type === 2">{{broadcast.body}}</template>
                    <template v-else>{{broadcast.subject}}</template>
                </div>
                <div class="broadcasts-table__created" data-title="Created">{{ broadcast.created | timestamp | timeago}}</div>
                <div class="broadcasts-table__delivery" data-title="Delivery">
                    <template v-if="broadcast.delivery === 0">immediately</template>
                    <template v-else>{{ broadcast.delivery_date | timestamp | timeago }}</template>
                </div>
                <div class="broadcasts-table__status"><el-tag size="mini" :type="getBroadcastTagType(broadcast.status)" round>{{ broadcast.status | broadcast_type }}</el-tag></div>
                <div class="broadcasts-table__sent" data-title="Sent">
                    <span v-if="broadcast.sent === 0" class="color-gray opacity--50">none</span>
                    <span v-else>{{broadcast.sent | number('0,0', { thousandsSeparator: ' ' })}}</span>
                </div>
                <div class="broadcasts-table__actions"><i class="fas fa-chevron-right color-black"></i></div>
            </div>
        </div>
        <div v-else class="margin-top--3 content-box text-align--center" v-loading="loading_global">
            <img src="@/assets/empty.svg" class="empty-svg">
            <p class="margin-top--2">No broadcasts yet.</p>
        </div>
    </div>
</template>

<script>
    import store from '@/store'
    import BroadcastsHeader from "@/components/broadcasts/BroadcastsHeader";
    import onUpdatedBroadcast from '@/appsync/subscriptions/broadcasts/onUpdatedBroadcast'
    import _ from 'lodash'
    import titleMixin from '@/mixins'
    //import gql from 'graphql-tag'
    //import listBroadcasts from '@/appsync/queries/broadcasts/list'
   // import Broadcast from '@/schemas/Broadcast'
    //import cleanDeep from "clean-deep"

    export default {
        name: "BroadcastsList",
        components: { BroadcastsHeader },
        mixins: [titleMixin],
        data: function(){
            return {
                loading: false
            }
        },
        computed: {
            page_title () {
                return 'Broadcasts'
            },
          broadcasts: function(){
              return _.orderBy( store.getters.broadcasts, ['created'], ['desc'] )
          }
        },
        methods: {
        },
        beforeRouteEnter: async function(to, from, next){
            if( store.getters.broadcasts.length === 0 ){
                await store.dispatch('broadcasts/list')
            }
            next()
        },
        mounted: function(){
            //this.$apollo.subscriptions.onUpdatedBroadcast.refresh()
        },
        /*apollo: {
            $subscribe: {
                onUpdatedBroadcast: {
                    query: onUpdatedBroadcast,
                    result ({ data }) {
                        store.dispatch( 'broadcasts/patchLocalData', data.onUpdatedBroadcast )
                    }
                }
            }
        }*/
    }
</script>

<style scoped lang="scss">
    .broadcasts-table{
        font-size: 0.9375rem;
        display: grid;
        grid-row-gap: 1.5rem;

        &__header{
            display: none;
        }
        @include breakpoint(tablet){
            grid-column-gap: 1.5rem;
            grid-template-columns: 1fr 1fr;
        }

        @include breakpoint(laptop){
            grid-template-columns: 100%;
        }

        &__item{
            background-color: $color-bg-box;
            border-radius: 5px;
            box-shadow: 0 2px 8px -1px rgba($color-black, 0.11);
            color: rgba($color-black, 0.75);
            transition: all 200ms ease;
            cursor: pointer;
            padding: 1.5rem;
            display: flex;
            flex-wrap: wrap;

            .broadcasts-table{
                &__title{
                    order: 0;
                    color: $color-black;
                    margin-bottom: 2rem;
                    margin-top: 1rem;
                    flex-basis: 100%;
                }
                &__created{
                    order: 2;
                    flex-basis: 100%;

                }
                &__delivery{
                    order: 3;
                    flex-basis: 100%;
                }
                &__created,
                &__delivery,
                &__sent{
                    margin-bottom: 0.25rem;

                    &::before{
                        content: attr(data-title);
                        font-weight: 500;
                        display: inline-block;
                        margin-right: 1rem;
                    }
                }
                &__status{
                    order: -2;
                    margin-bottom: .5rem;
                    flex-basis: 50%;
                }
                &__sent{
                    order: -1;
                    flex-basis: 50%;

                    text-align: right;
                }
                &__actions{
                    display: none;
                }
            }
        }

        @include breakpoint(laptop){
            grid-template-columns: 1fr;

            &__header{
                display: grid;
                grid-column-gap: 1.5rem;
                grid-template-columns: 1fr 15% 15% 13% 7% 7%;
                font-weight: 500;
                color: $color-black;
            }

            &__item{
                display: grid;
                grid-column-gap: 1.5rem;
                grid-template-columns: 1fr 15% 15% 13% 7% 7%;
                padding: 0;


                > div{
                    padding: 1.5rem 0;
                }

                .broadcasts-table{
                    &__title{
                        padding-left: 1.5rem;
                        color: inherit;
                        margin-bottom: 0;
                        margin-top: 0;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow-x: hidden;
                        max-width: 450px;
                        display: inline-block;
                    }
                    &__actions{
                        display: block;
                        text-align: right;
                        padding-right: 1.5rem;
                        order: 10
                    }
                    &__status{
                        order: 3;
                        text-align: left;
                        margin-bottom: 0;
                    }
                    &__delivery{
                        order: 2;
                        margin-bottom: 0;
                    }
                    &__created{
                        order: 1;
                        margin-bottom: 0;
                    }
                    &__sent{
                        order: 4;
                        margin-bottom: 0;
                    }
                    &__created,
                    &__delivery,
                    &__sent{
                        margin-bottom: 0;
                        text-align: left;

                        &::before{
                            display: none;
                        }
                    }
                }

                &:hover{
                    box-shadow: 0 18px 30px -4px rgba($color-black, 0.25);
                    transform: translateY(-3px);

                    .broadcasts-table {
                        &__title {
                            color: rgba($color-black, 1);
                            i{
                                color: $color-primary
                            }
                        }
                    }
                }
            }
        }





    }
</style>
import gql from 'graphql-tag'

export default gql `
  mutation createExport {
    createExport{
        id
        email
        ttl
        download
        status
        job_id
        created
    }
  }
`
import gql from 'graphql-tag'

export default gql `
    subscription onUpdatedExport{
        onUpdatedExport{
            id
            status
            download
            ttl
        }
    }
`
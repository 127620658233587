import Vue from 'vue'
import Router from 'vue-router'
import _ from 'lodash'
import store from '@/store'
import Public from '@/components/Public'
import Users from '@/components/users/UsersList'
import User from '@/components/users/User'
import UserMissing from '@/components/users/UserMissing'
import BroadcastsList from '@/components/broadcasts/BroadcastsList'
import Broadcast from '@/components/broadcasts/Broadcast'
import PollsList from '@/components/polls/PollsList'
import Poll from '@/components/polls/Poll'
import Moderation from '@/components/moderation/Moderation'
import ModerationList from '@/components/moderation/ModerationList'
import QasDisplay from '@/components/moderation/QasDisplay'
import LiveChat from '@/components/chat/LiveChat'
import LiveChatList from '@/components/chat/LiveChatList'
import LiveVideo from '@/components/video/LiveVideo'
import LiveVideoList from '@/components/video/LiveVideoList'
import Results from '@/components/polls/Results'
import Locations from '@/components/locations/Locations'
import Location from '@/components/locations/Location'
import Project from '@/components/project/Project'
import Events from '@/components/events/Events'
import Event from '@/components/events/Event'
import Templates from '@/components/templates/Templates'

Vue.use(Router)

function setup(){
    if( !store.getters.setup && ['360', 'host', 'moderator'].indexOf(store.getters.logged_user_role) >= 0 ){
        store.dispatch( 'setup' )
    }
}

function guardRoute(to, from, next) {
    if( new Date().getTime() < parseInt(localStorage.getItem('expires_at')) ){
        handleRoleNavigation(to, from, next);
    } else {
        localStorage.setItem( '360-redirect', JSON.stringify(to) )
        router.app.$auth.login()
    }
}

function handleRoleNavigation(to, from, next, home){
    if( store.getters.logged_user_role === 'moderator' ){
        if( to.path.indexOf('/modera') >= 0 ){
            next()
        } else {
            next('/moderation')
        }
    } else if( store.getters.logged_user_role === 'host' ){
        if( to.path.indexOf('/chat') >= 0 || to.path.indexOf('/live') >= 0 ){
            next()
        } else {
            next('/live')
        }
    } else if( store.getters.logged_user_role === 'visitor' ){
        router.app.$auth.logout()
    } else {
        if( home ){
            if(store.getters.logged_user_role === 'client'){
                next('/broadcasts')
            } else{
                next('/users')
            }
        } else {
            if( store.getters.logged_user_role === 'client' && ( to.path.indexOf( '/live' ) >= 0 || to.path.indexOf( '/users' ) >= 0  )){
                next('/broadcasts')
            }
            if( to.path.indexOf( '/proj' ) >= 0 || to.path.indexOf( '/loca' ) >= 0 || to.path.indexOf( '/even' ) >= 0 ){
                if( store.getters.logged_user_role === '360' && store.getters.logged_user_priviledges === 'owner' ){
                    next()
                } else {
                    next('/users')
                }
            } else {
                next()
            }
        }
    }
}

async function handleLogin(to, from, next) {
    if( _.isNull( to.query.login ) && !router.app.$auth.isAuthenticated() ){
        await router.app.$auth.handleAuthentication().then(() => {
            if( store.getters.logged_user_role === 'visitor' ){
                router.app.$auth.logout()
            }
            store.dispatch( 'set_logged_user' )
            setup();
            let old_to = localStorage.getItem( '360-redirect' )
            if( ! _.isUndefined( old_to ) && ! _.isNull( old_to ) ){
                old_to = JSON.parse( old_to )
                if( ! _.isUndefined( old_to.name ) && ! _.isUndefined( old_to.params ) ){
                    localStorage.removeItem('360-redirect')
                    next({ name: old_to.name, params: old_to.params })
                } else {
                    next()
                }
            } else {
                handleRoleNavigation(to, from, next, true)
            }
        }).catch(function(){
            router.app.$auth.login()
        })
    } else if( router.app.$auth.isAuthenticated() ){
        handleRoleNavigation(to, from, next)
    } else {
        router.app.$auth.login()
    }
}

const router =  new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'public',
            component: Public,
            beforeEnter: handleLogin,
            props: true
        },
        {
            path: '/broadcasts',
            name: 'broadcasts',
            beforeEnter: guardRoute,
            component: BroadcastsList
        },
        {
            path: '/broadcasts/edit/:id',
            name: 'broadcasts-edit',
            beforeEnter: guardRoute,
            component: Broadcast,
            props: true
        },
        {
            path: '/broadcasts/new',
            name: 'broadcasts-new',
            beforeEnter: guardRoute,
            component: Broadcast
        },
        {
            path: '/polls',
            name: 'polls',
            beforeEnter: guardRoute,
            component: PollsList
        },
        {
            path: '/polls/:id',
            name: 'polls-edit',
            component: Poll,
            beforeEnter: guardRoute,
            props: true
        },
        {
            path: '/moderation',
            name: 'moderation',
            beforeEnter: guardRoute,
            component: ModerationList
        },
        {
            path: '/moderation/:id',
            name: 'moderation-moderate',
            component: Moderation,
            beforeEnter: guardRoute,
            props: true
        },
        {
            path: '/chat',
            name: 'chat',
            beforeEnter: guardRoute,
            component: LiveChatList
        },
        {
            path: '/chat/host/:id',
            name: 'chat-host',
            beforeEnter: guardRoute,
            component: LiveChat,
            props: true
        },
        {
            path: '/live',
            name: 'live',
            beforeEnter: guardRoute,
            component: LiveVideoList
        },
        {
            path: '/live/:id',
            name: 'live-host',
            beforeEnter: guardRoute,
            component: LiveVideo,
            props: true
        },
        {
            path: '/users/',
            name: 'users',
            beforeEnter: guardRoute,
            component: Users
        },
        {
            path: '/users/new',
            name: 'users-new',
            beforeEnter: guardRoute,
            component: User
        },
        {
            path: '/users/edit/:id',
            name: 'users-edit',
            component: User,
            beforeEnter: guardRoute,
            props: true
        },
        {
            path: '/users/missing',
            name: 'users-404',
            beforeEnter: guardRoute,
            component: UserMissing
        },
        {
            path: '/results/:id',
            name: 'results',
            component: Results,
            beforeEnter: guardRoute,
            props: true
        },
        {
            path: '/locations',
            name: 'Locations',
            component: Locations,
            beforeEnter: guardRoute
        },
        {
            path: '/location/:id',
            name: 'Location',
            component: Location,
            beforeEnter: guardRoute,
            props: true
        },
        {
            path: '/project',
            name: 'Project',
            component: Project,
            beforeEnter: guardRoute
        },
        {
            path: '/events',
            name: 'Events',
            component: Events,
            beforeEnter: guardRoute
        },
        {
            path: '/events/:id',
            name: 'Event',
            component: Event,
            beforeEnter: guardRoute,
            props: true
        },
        {
            path: '/templates',
            name: 'Templates',
            component: Templates,
            beforeEnter: guardRoute
        },
        {
            path: '/qas-display',
            name: 'qas-display',
            component: QasDisplay,
            beforeEnter: guardRoute
        }
    ]
})
/*
router.beforeEach(async (to, from, next) => {
    window.console.log('testeaza', router.app.$auth.isAuthenticated(), store.getters.setup)
    if( router.app.$auth.isAuthenticated() && !store.getters.setup ){
        store.dispatch( 'setup' )
    }
    next()
})*/

export default router

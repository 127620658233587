<template>
  <div>
    <UsersHeader :title="is_new ? 'New user' : 'Edit user'"></UsersHeader>
    <el-form class="content-box margin-top--2" label-position="top" ref="form" :rules="rules" :model="form" @submit.native.prevent v-loading="loading_global">
      <div v-if="!is_new" class="static-user">
        <avatar :username="getFullName(static_form) ? getFullName(static_form) : 'Loading User'" :backgroundColor="static_form.email ? getRandomColor(static_form.email) : '#f8f7ff'" :lighten="-40" :size="120"></avatar>
        <h1>
          <template v-if="getFullName(static_form)">{{ static_form.user_metadata.first_name }} {{ static_form.user_metadata.last_name }} </template
          ><template v-else>Loading User</template>
        </h1>
        <p>
          {{ static_form.app_metadata.role | user_role_name }}
          <span class="display--inline-block margin-left--05 margin-right--05">|</span>
          Joined {{ static_form.created_at | timestamp | timeago }}
          <template v-if="static_form.updated_at !== static_form.created_at">
            <span class="display--inline-block margin-left--05 margin-right--05">|</span>
            Last updated
            {{ static_form.updated_at | timestamp | timeago }}
          </template>
        </p>
        <el-button type="text" class="margin-left--auto" @click="printBadge()"><span class="fas fa-id-badge"></span> Print Badge</el-button>
        <div id="printframe" ref="print_frame">
          <div class="name placeholder-field">{{ getFullName(static_form) }}</div>
          <div class="company placeholder-field">{{ static_form.user_metadata.custom_1 }}</div>
          <div class="state placeholder-field">{{ static_form.user_metadata.custom_2 }}</div>
          <div class="name-copy placeholder-field">{{ getFullName(static_form) }}</div>
          <div class="company-copy placeholder-field">{{ static_form.user_metadata.custom_1 }}</div>
          <div class="state-copy placeholder-field">{{ static_form.user_metadata.custom_2 }}</div>
          <img :src="link_badge">
        </div>
      </div>
      <div class="form-user">
        <el-row :gutter="48">
          <el-col :xs="24" :md="12">
            <el-form-item label="First name" prop="user_metadata.first_name">
              <el-input type="text" v-model="form.user_metadata.first_name" placeholder="Enter the first name here"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :md="12">
            <el-form-item label="Last name" prop="user_metadata.last_name">
              <el-input type="text" v-model="form.user_metadata.last_name" placeholder="Enter the last name here"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="48" class="margin-top--3">
          <el-col :xs="24" :md="12">
            <el-form-item label="Email" prop="email">
              <el-input type="text" v-model="form.email" placeholder="Enter the email here"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :md="12">
            <el-form-item label="Phone" prop="user_metadata.phone">
              <el-input type="text" v-model="form.user_metadata.phone" placeholder="Enter the phone here"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="margin-top--3" :gutter="48">
          <el-col :xs="24" :md="12">
            <el-form-item label="User role">
              <el-select v-model="form.app_metadata.role">
                <el-option value="visitor" label="Visitor"></el-option>
                <el-option value="moderator" label="Moderator"></el-option>
                <el-option value="host" label="Host"></el-option>
                <el-option value="client" label="Client"></el-option>
                <el-option value="360" label="360 Staff"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :md="12">
            <el-form-item label="Permissions">
              <el-input placeholder="User permissions" v-model="form.app_metadata.permissions"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="48" class="margin-top--3" v-if="can_change_password">
          <el-col :xs="24" :md="12">
            <el-form-item label="Password" prop="password">
              <el-input type="password" v-model="form.password" placeholder="Enter the password here"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :md="12">
            <el-form-item label="Re-type password" prop="retype">
              <el-input type="password" v-model="form.retype" placeholder="Re-type the password here"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="48" class="margin-top--3">
          <el-col :xs="24" :md="12">
            <el-form-item label="Meta info #1" prop="user_metadata.custom_1">
              <el-input type="text" v-model="form.user_metadata.custom_1"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :md="12">
            <el-form-item label="Meta info #2" prop="user_metadata.custom_2">
              <el-input type="text" v-model="form.user_metadata.custom_2"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <div class="margin-top--3 form-footer">
        <el-button type="primary" :disabled="!form_has_changed" round v-on:click.prevent="saveForm()"><i class="margin-right--05 fas fa-save"></i> Save user</el-button>
        <el-button type="text" class="color-gray" v-if="form_has_changed" v-on:click.prevent="resetForm()"><i class="margin-right--05 fas fa-undo"></i> Reset changes</el-button>
        <el-button type="text" v-if="!is_new && id" class="color-danger padding-right--0" round v-on:click.prevent="confirmDeleteUser()"><i class="margin-right--05 fas fa-times"></i> Delete user</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import { isUndefined, isNull } from "lodash"
import User from "@/schemas/User"

import Avatar from "vue-avatar/src/Avatar"
import UsersHeader from "@/components/users/UsersHeader"
import store from "@/store"
const default_user = new User().toObject()
import titleMixin from "@/mixins"
import printJS from 'print-js'

export default {
  name: "User",
  components: { Avatar, UsersHeader },
  props: ["id"],
  mixins: [titleMixin],
  mounted: function() {
    let vm = this
    store.subscribe((mutation) => {
      if (mutation.type === "users/ADD") {
        if (vm.is_new) {
          vm.$router
              .push({
                name: "users-edit",
                params: { id: mutation.payload.user_id }
              })
              .catch()
        }
      } else if (mutation.type === "users/REMOVE") {
        vm.$router.push({ name: "users" }).catch()
      }
      if (mutation.type === "users/ADD" || mutation.type === "users/PATCH") {
        store.dispatch("moderators/patch", mutation.payload)
      }
    })
  },
  beforeRouteEnter: function(to, from, next) {
    if (!isUndefined(to.params.id) && !isNull(to.params.id)) {
      store.dispatch("users/get", to.params.id)
    }
    next()
  },
  watch: {
    user: function(n) {
      this.setForm({ ...n, user_id: n.objectID })
    },
    id: function(n) {
      if (isUndefined(n)) {
        this.setForm(default_user)
      }
    }
  },
  data: function() {
    let vm = this
    return {
      rules: {
        email: [
          {
            required: true,
            message: "Please input the email",
            trigger: "blur"
          },
          {
            min: 6,
            message: "Length should be at least 6 characters",
            trigger: "blur"
          },
          {
            type: "email",
            message: "This does not look like a valid email address",
            trigger: "blur"
          }
        ],
        password: [
          {
            validator: function(rule, value, callback) {
              if (vm.is_new || (!vm.is_new && value.length > 0)) {
                if (value.length < 8) {
                  callback(["Password should be at least 8 characters"])
                }
                let format = /[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/
                if (!format.test(value)) {
                  callback(["Password needs at least one special character: @ # $ & ..."])
                }
                let uppercase = /[A-Z]/
                if (!uppercase.test(value)) {
                  callback(["Password needs at least one UPPERCASE letter"])
                }
                let number = /[0-9]/
                if (!number.test(value)) {
                  callback(["Password needs at least one number"])
                }
                let lowercase = /[a-z]/
                if (!lowercase.test(value)) {
                  callback(["Password needs at least one lowercase letter"])
                }
              }
              callback()
            },
            trigger: "blur"
          }
        ],
        retype: [
          {
            validator: function(rule, value, callback) {
              if (vm.is_new || (!vm.is_new && vm.form.password.length > 0)) {
                if (vm.form.password !== value) {
                  callback(["Passwords don't match"])
                }
                callback()
              }
              callback()
            },
            trigger: "blur"
          }
        ]
      },
      form: JSON.parse(JSON.stringify(default_user)),
      witness: JSON.stringify(default_user),
      print_modal: false
    }
  },
  computed: {
    page_title() {
      return this.is_new ? "Users | Add user" : "Users | Edit user"
    },
    form_has_changed: function() {
      return JSON.stringify(this.form) !== this.witness
    },
    is_new: function() {
      return isUndefined(this.id) || this.id === "new"
    },
    static_form: function() {
      return JSON.parse(this.witness)
    },
    users: function() {
      return store.getters.users
    },
    user: function() {
      return store.getters.single_user
    },
    can_change_password: function() {
      return (!isUndefined(this.user) && !isUndefined(this.user.user_id)) || this.is_new
    },
    link_badge: function(){
      return this.getImgSrc({}, this.$store.getters.settings.ecuson || 'templates/default/ecuson1.jpg')
    }
  },
  methods: {
    confirmDeleteUser: function() {
      let vm = this
      this.$confirm("This will permanently delete the user. Continue?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "danger"
      })
          .then(() => {
            store.dispatch("users/remove", vm.id)
          })
          .catch(() => {
            return
          })
    },
    setForm: function(form) {
      form = new User(form).toObject()
      this.witness = JSON.stringify(form)
      this.form = form
    },
    resetForm: function() {
      this.form = JSON.parse(this.witness)
    },
    saveForm: function() {
      let vm = this
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (vm.is_new) {
            store.dispatch("users/create", vm.form)
          } else {
            if (vm.form.password.length > 0) {
              store.dispatch("users/update_user_password", {
                user_id: vm.id,
                password: vm.form.password
              })
            } else {
              store.dispatch("users/update", { ...vm.form, user_id: vm.id })
            }
          }
        } else {
          return false
        }
      })
    },
    getFullName: function(o) {
      if (isUndefined(o.user_metadata)) {
        return o.email
      }
      if (`${o.user_metadata.first_name} ${o.user_metadata.last_name}`.trim().length === 0) {
        return
      }
      return `${o.user_metadata.first_name} ${o.user_metadata.last_name}`
    },
    printBadge: function(){
      const style = `

        #printframe{
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          max-height: 677px !important;
          overflow: hidden;
          display: block !important;
        }

        .placeholder-field{
          position: absolute;
          width: 260px !important;
          color: black;
          display: inline-block;
          z-index: 1;
          height: 35px !important;
          text-align: center;
          line-height: 35px !important;
          font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
          font-weight: 700;
          font-size: 17px;
        }

        .name{
          top: 16.85%;
          right: 5% !important;
        }
        .company{
          top: 26.35%;
          right: 5% !important;
        }
        .state{
          top: 35.75%;
          right: 5% !important;
        }

        .name-copy{
          bottom: 17.25% !important;
          left: 3.75% !important;
          transform: scale(-1, -1);
        }
        .company-copy{
          bottom: 26%;
          left: 3.75% !important;
          transform: scale(-1, -1);
        }
        .state-copy{
          bottom: 35.5%;
          left: 3.75% !important;
          transform: scale(-1, -1);
        }

        img{
          width: 100% !important;
          height: auto !important;
          z-index: 0;
        }
     `


      printJS({
        printable: 'printframe',
        type: 'html',
        maxWidth: 425, // 638, //1276
        style
      })
    }
  }
}
</script>

<style scoped lang="scss">
#printframe{
  display: none;
}
.static-user {
  margin-bottom: 1rem;
  position: relative;
  padding: 0 0 0 6rem;
  min-height: 5.625rem;
  display: flex;
  align-items: center;
  align-content: center;
  width: 100%;
  flex-wrap: wrap;
  justify-items: center;

  .vue-avatar--wrapper {
    transform: scale(0.65);
    transform-origin: top left;
    position: absolute;
    left: 0;
    top: 0;
  }

  h1 {
    flex-basis: 100%;
    margin-top: -1.25rem;
  }

  h1 + p {
    font-size: 0.75rem;
    line-height: 1.2;
    margin-bottom: 0;
  }

  @include breakpoint(phablet) {
  }

  @include breakpoint(tablet) {
    min-height: 7.5rem;
    padding-left: 9rem;
    margin-bottom: 2rem;

    .vue-avatar--wrapper {
      transform: none;
    }

    h1 + p {
      font-size: inherit;
      line-height: inherit;
    }
  }
  @include breakpoint(laptop) {
    margin-bottom: 4rem;
  }
}
.form-user {
  .el-col + .el-col {
    margin-top: 1.5rem;
  }
  @include breakpoint(laptop) {
    .el-col + .el-col {
      margin-top: 0;
    }
  }
}
</style>
import SchemaObject from "schema-object";
export default new SchemaObject({
    id: String,
    name: {
        type: String,
        default: ''
    },
    excerpt: {
        type: String,
        default: ''
    },
    start: {
        type: String,
        default: ''
    },
    end: {
        type: String,
        default: ''
    },
    live: {
        type: Boolean,
        default: true
    },
    poll: {
        type: Boolean,
        default: false
    },
    chat: {
        type: Boolean,
        default: false
    },
    chat_group: {
        type: Boolean,
        default: false
    },
    location: {
        type: String,
        default: ''
    },
    results: {
        type: Number,
        default: 0
    },
    hosts: [String],
    qa: {
        type: Boolean,
        default: false
    },
    qa_moderator: {
        type: String,
        default: false
    },
    status: {
        type: Number,
        default: 0
    },
    qa_status: {
        type: Number,
        default: 0
    },
    poll_status: {
        type: Number,
        default: 0
    },
    poll_order: [String],
    sync_video: {
        type: String,
        default: ''
    },
    sync_video__ro: {
        type: String,
        default: ''
    },
    sync: {
        type: Boolean,
        default: false
    },
    sync_updated: Number,
    started: Number,
    stopped: Number,
    live_video: {
        type: String,
        default: ''
    },
    live_video__ro: {
        type: String,
        default: ''
    },
    scannable: {
        type: String,
        default: '*'
    },
    hidden:{
        type: Boolean,
        default: false
    },
    sync_video__es: {
        type: String,
        default: ''
    },
    live_video__es: {
        type: String,
        default: ''
    },
    sync_video__it: {
        type: String,
        default: ''
    },
    live_video__it: {
        type: String,
        default: ''
    },
    sync_video__de: {
        type: String,
        default: ''
    },
    live_video__de: {
        type: String,
        default: ''
    },
    sync_video__fr: {
        type: String,
        default: ''
    },
    live_video__fr: {
        type: String,
        default: ''
    },
    hosts_excerpt: {
        type: String,
        default: ''
    },
    replay:{
        type: Boolean,
        default: false
    },
    zoom:{
        type: Boolean,
        default: false
    },
    zoom_meeting:{
        type: String,
        default: ''
    },
    zoom_pass:{
        type: String,
        default: ''
    },
    wowza:{
        type: Boolean,
        default: false
    },
    wowza_live_video:{
        type: String,
        default: ''
    },
    youtube:{
        type: Boolean,
        default: false
    },
    youtube_live_video:{
        type: String,
        default: ''
    },
    twitch:{
        type: Boolean,
        default: false
    },
    twitch_live_video:{
        type: String,
        default: ''
    },
    daily:{
        type: Boolean,
        default: false
    },
    daily_live_video:{
        type: String,
        default: ''
    },
    phone: {
        type: String,
        default: ""
    }
})
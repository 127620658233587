import gql from 'graphql-tag'
export default gql `
    query listInbox($limit: Int, $nextToken: String){
        listInbox(
            limit: $limit,
            nextToken: $nextToken
        ){
            items{
                id
                body
                from
                from_email
                subject
                read
                created
                updated
                ts
            },
            nextToken
         }
    }
`
import gql from 'graphql-tag';
export default gql `
    query listImages($prefix: String){
        listImages(
            prefix: $prefix
        ){
            key
            updated
         }
    }
`
<template>
    <div>
        <h1 class="page-title">Q&A moderation <span v-on:click.prevent="$router.push({name: 'qas-display'})" class="float--right fad margin-top--12px fa-tv-retro color-primary"></span></h1>
        <el-button-group>
          <el-button v-on:click.prevent="selected = null" round size="mini">All</el-button>
          <el-button v-for="(events, date) in events_grouped" :key="`days-filter-${date}`" v-on:click.prevent="selected = date" round size="mini">{{date | moment('ddd DD')}}</el-button>
        </el-button-group>
        <div class="boxes boxes--two margin-top--2" v-if="events.length > 0" v-loading="loading_global">

            <div class="box" v-for="(ev, evi) in events_list" :key="evi">
                <el-row type="flex" align="middle">
                    <el-col :span="18">
                        <el-tag size="mini" :type="getTimerTagType(ev.start)" round>
                            <span class="status-type">
                                <template v-if="['primary','warning', 'danger'].indexOf( getTimerTagType(ev.start) ) >= 0">
                                    Starting
                                </template>
                                <template v-else>Started</template>
                            </span>
                            {{ ev.start | timestamp | timeago }}
                        </el-tag>
                        <el-tag v-if="ev.status === 1" type="danger" size="mini" round class="margin-left--5px"><span class="fas fa-circle"></span> LIVE</el-tag>
                    </el-col>
                    <el-col :span="16" align="right" class="color-gray opacity--50 text-size--smaller">
                        {{ ev.start | moment('MMM DD') }}
                    </el-col>
                </el-row>
                <h1 class="margin-top--1 margin-bottom--5 title">{{ev.name}}</h1>
                <el-row>
                    <el-col :xs="16" :sm="16" :lg="12">
                        <div class="moderator-name">
                            <avatar v-if="getModerator(ev)" :username="getModerator(ev).name" :size="45"></avatar>
                            <div class="name" v-if="getModerator(ev)">{{ getModerator(ev).name }}</div>
                        </div>
                    </el-col>
                    <el-col :xs="8" :sm="8" :lg="12" align="right">
                        <el-button type="primary" round v-on:click.prevent="$router.push({ name: 'moderation-moderate', params: { id: ev.id } })">Join</el-button>
                    </el-col>
                </el-row>
            </div>
        </div>
        <div v-else class="content-box text-align--center margin-top--2" v-loading="loading_global">
            <img src="@/assets/empty.svg" class="empty-svg">
            <p class="margin-top--2">No events available for moderation.</p>
        </div>
    </div>
</template>

<script>
    import Avatar from "vue-avatar/src/Avatar";
    import _ from 'lodash'
    import store from '@/store'
    import moment from "moment"
    import titleMixin from '@/mixins'

    export default {
        name: "ModerationList",
        components: {Avatar},
        data(){
          return {
            selected: null
          }
        },
        mixins: [titleMixin],
        computed: {
            page_title () {
                return `Q&A Moderation`
            },
            events: function(){
                return _.filter( _.orderBy( store.getters.events, ['start'] ), function(ev){
                    let allow = ev.qa
                    if( allow && ['360','moderator', 'client'].indexOf( store.getters.logged_user['https://360live.events/role'] ) === -1 ){
                        allow = false
                    }
                    if( allow && store.getters.logged_user['https://360live.events/role'] === 'moderator' ){
                        allow = ev.qa_moderator === store.getters.logged_user.sub
                    }
                    return allow
                })
            },
            moderators: function(){
                return store.getters.moderators
            },
          events_grouped(){
              return _.groupBy( this.events, (ev) => ev.start.split(' ')[0] )
          },
          events_list(){
              return _.orderBy(this.selected ? this.events_grouped[this.selected] : this.events, ['status'], ['desc'])
          }
        },
        mounted: function(){
            this.checkForSingle(this.events)
        },
        methods: {
            checkForSingle: function(n){
                if(n.length === 1 && this.eventStarted(n[0])){
                    this.$router.push({ name: 'moderation-moderate', params: { id: n[0].id } })
                }
            },
            getModerator: function(ev){
                let moderator = _.find( this.moderators, { objectID: ev.qa_moderator } )
              return _.isUndefined( moderator ) ? false : {
                    name: `${moderator.user_metadata.first_name} ${moderator.user_metadata.last_name}`,
                    email: moderator.email
              }
            },
            eventStarted: function(ev){
                return moment().unix() >= moment(ev.start).unix()
            }
        },
        watch: {
            events: function(n){
                this.checkForSingle(n)
            }
        },
    }
</script>

<style scoped lang="scss">
    .boxes--two{
        .box{
            padding: 1rem;
            display: flex;
            flex-direction: column;

            .title{
                flex-grow: 1;
            }
        }
        h1{
            line-height: 1.35;
            font-size: 1rem;
        }

        .moderator-name{
            position: relative;
            padding-left: 3.5rem;

            min-height: 2.8125rem;

            display: flex;
            align-items: center;
            align-content: center;
            width: 100%;
            flex-wrap: wrap;
            justify-items: center;

            .vue-avatar--wrapper{
                position: absolute;
                left: 0
            }

            .name{
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                margin-right: 10px;
            }
        }

        .status-type{
            display: none;

            @include breakpoint(laptop){
                display: inline;
            }
        }

        @include breakpoint(tablet){
            .box{
                padding: 2rem;

                h1{
                    font-size: 1.25rem;
                }
            }
        }
        @include breakpoint(desktop){
            .box{
                padding: 3rem;
            }
        }
    }
</style>
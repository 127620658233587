import gql from 'graphql-tag'

export default gql`
    mutation deleteChatMessage($id: ID!){
        deleteChatMessage(
            id: $id
        ){
            id
        }
    }
`
<template>
    <span class="counter" :class="{ 'counter--passed': is_past }">
        <span><template v-if="counter.past">-</template>{{counter.hours | format_counter }}</span>
        <span class="separator">:</span>
        <span>{{counter.minutes | format_counter}}</span>
        <span class="separator">:</span>
        <span>{{counter.seconds | format_counter}}</span>
    </span>
</template>

<script>
    import moment from 'moment'
    import _ from 'lodash'
    export default {
        name: "counter",
        props: [ 'event' ],
        data: function(){
          return {
              now: moment().unix()
          }
        },
        mounted: function(){
            this.setTimer()
        },
        computed: {
            counter: function () {
                let start = moment(this.event.start).unix()
                if( this.event.status === 1 && ! _.isUndefined( this.event.started ) && this.event.started > 0 ){
                    start  = this.event.started
                } else if( this.event.status === 0 && ! _.isUndefined( this.event.stopped ) && this.event.stopped > 0 ){
                    start = this.event.stopped
                }
                let ts =  this.now - start;
                let days = parseInt(ts / this.DAY_IN_SECONDS)
                let hours = parseInt((ts - days * this.DAY_IN_SECONDS) / this.HOUR_IN_SECONDS)
                let minutes = parseInt((ts - days * this.DAY_IN_SECONDS - hours * this.HOUR_IN_SECONDS) / this.MINUTE_IN_SECONDS)
                let seconds = parseInt((ts - days * this.DAY_IN_SECONDS - hours * this.HOUR_IN_SECONDS - minutes * this.MINUTE_IN_SECONDS))
                hours += days * 24
                return {
                    hours: hours,
                    minutes: minutes,
                    seconds: seconds,
                    past: seconds < 0
                }
            },
            is_past: function(){
                return this.event.status === 0 && this.event.stopped > 0
            }
        },
        filters: {
            format_counter: function(v){
                if( v < 10 && v >= 0 ){
                    return `0${v}`
                } else  if( v < 10 && v < 0 && v * -1 <=9 ){
                    return `0${v * -1}`
                } else if( v < 10 && v < 0 ){
                    return v * -1
                }
                return v
            }
        },
        methods: {
            setTimer: function(){
                let vm = this
                setTimeout( function(){
                    vm.now = moment().unix()
                    vm.setTimer()
                }, 1000)
            }
        }
    }
</script>

<style scoped lang="scss">
    .counter{
        font-size: 2rem;
        color: $color-black;
        line-height: 1;
        display: inline-flex;
        flex-wrap: nowrap;
        margin-top: 0.15rem;
        font-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace;

        > span:not(.separator){
            display: inline-block;
        }

        @include breakpoint(tablet){
            font-size: 2.5rem;
        }

        &--passed{
            opacity: 0.5;
            color: $color-gray;
        }
    }
</style>
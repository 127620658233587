import gql from 'graphql-tag'

export default gql `
            mutation updateUserPassword($input: UserPasswordInput!){
                updateUserPassword(
                    input: $input
                ){
                    user_id
                    email
                    user_metadata{
                        first_name
                        last_name
                        custom_1
                        custom_2
                    }
                    app_metadata{
                        role
                        permissions
                    }
                }
            }
        `
import SchemaObject from "schema-object";
const LocationButtons = new SchemaObject({
	label: {
		type: String,
		default: ''
	},
	url: {
		type: String,
		default: ''
	},
	type: {
		type: String,
		default: 'back'
	},
	path: {
		type: String,
		default: ''
	},
	d: {
		type: String,
		default: ''
	}
})
export default new SchemaObject({
	id: String,
	name: {
		type: String,
		default: ''
	},
	chat: {
		type: Boolean,
		default: false
	},
	chat_group: {
		type: Boolean,
		default: false
	},
	template: {
		type: String,
		default: 'lobby'
	},
	bg: {
		type: String,
		default: ''
	},
	svg: {
		type: String,
		default: ''
	},
	hosts: {
		type: [String],
		default: []
	},
	slug: {
		type: String,
		default: ''
	},
	buttons: {
		type: [LocationButtons],
		default: []
	},
	heat_maps: {
		type: [LocationButtons],
		default: []
	},
	parent: {
		type: String,
		default: ''
	},
	vod: {
		type: String,
		default: ''
	},
	menu_order: {
		type: Number,
		default: 0
	},
	is_home: {
		type: Boolean,
		default: false
	},
	video_wall: {
		type: String,
		default: ''
	},
	info: {
		type: String,
		default: ''
	},
	agenda: {
		type: Boolean,
		default: false
	},
	agenda_date_format: {
		type: String,
		default: ''
	},
	thumb: {
		type: String,
		default: ''
	},
	not_protected: {
		type: Boolean,
		default: false
	},
	permissions: {
		type: String,
		default: ""
	},
	password: {
		type: String,
		default: ""
	},
	phone: {
		type: String,
		default: ""
	}
})
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import AWSAppSyncClient from "aws-appsync"
import VueApollo from 'vue-apollo'
import locale from 'element-ui/lib/locale/lang/en'
import VueMoment from 'vue-moment'
import * as moment from 'moment-timezone'
import Vuex from 'vuex'
import 'element-ui/lib/theme-chalk/index.css'
import auth0 from '@/auth'
import _ from 'lodash'
import { format } from 'timeago.js';
import '@/assets/scss/main.scss'
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
import store from '@/store'
import { ApolloLink } from 'apollo-link';
import { createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import VEmojiPicker from 'v-emoji-picker';
Vue.use(VEmojiPicker);
//import { InMemoryCache } from 'apollo-cache-inmemory';

// Setup AWS GraphQL client connection
const auth = {
	type: 'OPENID_CONNECT',
	jwtToken: () => window.localStorage.getItem('id_token')
}
const appsync_config = {
	url: process.env.VUE_APP_AWS_GRAPHQL_ENDPOINT,
	region: process.env.VUE_APP_AWS_GRAPHQL_REGION || 'eu-central-1',
	auth: auth,
	disableOffline: true,
	reconnect: true

}
let url = process.env.VUE_APP_AWS_GRAPHQL_ENDPOINT
let region = process.env.VUE_APP_AWS_GRAPHQL_REGION || 'eu-central-1'

const link = ApolloLink.from([
	createAuthLink({ url, region, auth }),
	createSubscriptionHandshakeLink({ url, region, auth })
]);
export const ApolloClient = new AWSAppSyncClient(appsync_config, {
	link,
	//cache: new InMemoryCache()
	defaultOptions: {
		query: {
			fetchPolicy: 'network-only',
			errorPolicy: 'all ',
		},
		mutate: {
			fetchPolicy: 'no-cache',
			errorPolicy: 'all ',
		}
	}
})

const appsyncProvider = new VueApollo({
	defaultClient: ApolloClient
})

/*import Amplify, * as AmplifyModules from 'aws-amplify'
import  { AmplifyPlugin } from "aws-amplify-vue"
Amplify.configure({
	aws_appsync_graphqlEndpoint: appsync_config.url,
	aws_appsync_region: appsync_config.region,
	aws_appsync_authenticationType: appsync_config.auth.type,
	aws_appsync_apiKey: null
})

Vue.use( AmplifyPlugin, AmplifyModules )*/


// Set Vue Auth
Vue.use(auth0)

// Set Element UI
Vue.use(ElementUI, { locale })
Vue.use(ElementUI)

// Set Vue Apollo
Vue.use(VueApollo)

// Set Vuex
Vue.use(Vuex)

// Set VueMoment
Vue.use(VueMoment, {
	moment,
})

// Set timeago
Vue.filter('timeago', function (minutes) {
	return format(parseInt(minutes));
})

// Set perfect scrollbar
Vue.use(PerfectScrollbar)


import Vue2Filters from 'vue2-filters'

Vue.use(Vue2Filters)

/*import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';

Sentry.init({
	dsn: 'https://c5f4a8916d9b46bbb2680df498fd0c5a@o414860.ingest.sentry.io/5305084',
	integrations: [new VueIntegration({Vue, attachProps: true})],
});*/


// Setup Imgix
import ImgixClient from "@imgix/js-core";
const client = new ImgixClient({ domain: '360-public-dev.imgix.net' });
Vue.use({
	install (Vue) {
		Vue.mixin({
			methods: {
				getRandomColor: function (str) {
					let hash = 0;
					for (let i = 0; i < str.length; i++) {
						hash = str.charCodeAt(i) + ((hash << 5) - hash);
					}
					return `#${this.intToRGB(hash)}`;
				},
				intToRGB: function (i) {
					let c = (i & 0x00FFFFFF)
						.toString(16)
						.toUpperCase();
					return "00000".substring(0, 6 - c.length) + c;
				},
				hexToRgbA: function (hex, opacity) {
					var c;
					if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
						c = hex.substring(1).split('');
						if (c.length == 3) {
							c = [c[0], c[0], c[1], c[1], c[2], c[2]];
						}
						c = '0x' + c.join('');
						return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + `,${opacity || 1})`;
					}
					throw new Error('Bad Hex');
				},
				setup: function () {
					return store.getters.setup
				},
				getTimeDiff: function (start, end) {
					return parseInt(moment(start).diff(moment(end)) / 1000)
				},
				getTimerTagType: function (start) {
					let diff = this.getTimeDiff(start, moment())
					if (diff < 0 && diff < this.HOUR_IN_SECONDS * -12) {
						return 'info'
					}
					if (diff < 0) {
						return 'success'
					}
					if (diff < this.DAY_IN_SECONDS - this.HOUR_IN_SECONDS * 8) {
						return 'danger'
					}
					if (diff < this.DAY_IN_SECONDS) {
						return 'warning'
					}
					return 'primary'
				},
				getBroadcastTagType: function (v) {
					if (v === -1) {
						return 'danger'
					}
					if (v === 3) {
						return 'success'
					}
					if (v === 2) {
						return 'warning'
					}
					if (v === 1) {
						return 'scheduled'
					}
					return 'info'
				},
				getImgSrc: function (params, image) {
					return client.buildURL(image, params);
				}
			},
			data: function () {
				return {
					hydrated: false
				}
			},
			computed: {
				site_url: function () {
					return process.env.VUE_APP_AUTH0_REDIRECT_URI
				},
				user_message_types: function () {
					return ['initial-request', 'comment']
				},
				loading_global: function () {
					return store.getters.loading
				},
				MINUTE_IN_SECONDS: function () {
					return 60
				},
				HOUR_IN_SECONDS: function () {
					return this.MINUTE_IN_SECONDS * 60
				},
				DAY_IN_SECONDS: function () {
					return this.HOUR_IN_SECONDS * 24
				},
				WEEK_IN_SECONDS: function () {
					return this.DAY_IN_SECONDS * 7
				},
				logged_user: function () {
					return store.getters.logged_user
				},
				logged_user_role: function () {
					return store.getters.logged_user_role
				}
				/*locations: function(){
					return store.getters.locations
				},*/
			},
			filters: {
				timestamp: function (v) {
					return moment(v).unix() * 1000
				},
				user_role_name: function (v) {
					if (v === 'moderator') {
						return 'Moderator'
					}

					if (v === 'host') {
						return 'Host'
					}

					if (v === '360') {
						return '360 Staff'
					}
					return 'Visitor'
				},
				location_name: function (v) {
					let location = _.find(store.getters.locations, { id: v })
					if (!_.isUndefined(location)) {
						return location.name
					}
					return v
				},
				host_name: function (v) {
					let host = _.find(store.getters.hosts, { objectID: v })
					if (!_.isUndefined(host)) {
						let name = host.user_metadata.first_name
						return !_.isUndefined(host.user_metadata.last_name) ? `${name} ${host.user_metadata.last_name}` : name
					}
					return v
				},
				moderator_name: function (v) {
					let host = _.find(store.getters.moderators, { objectID: v })
					if (!_.isUndefined(host)) {
						let name = host.user_metadata.first_name
						return !_.isUndefined(host.user_metadata.last_name) ? `${name} ${host.user_metadata.last_name}` : name
					}
					return v
				},
				broadcast_type: function (v) {
					if (v === -1) {
						return 'not delivered'
					}
					if (v === 3) {
						return 'delivered'
					}
					if (v === 2) {
						return 'sending'
					}
					if (v === 1) {
						return 'scheduled'
					}
					return 'draft'
				}
			},

		});
	}
})

Vue.config.productionTip = false
new Vue({
	router,
	store,
	//appsyncProvider,
	provide: appsyncProvider.provide(),
	render: h => h(App)
}).$mount('#app')
